import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img ds">
        <div className="left-img left-img2">
          <div className="short-line"></div>
          <h2 className="heading-1">Hybrid App Development Services</h2>
          <p>
            We build hybrid apps that are exceptionally fast, code less, and can
            help in increased brand awareness and ROI. At BTG Technologies, we
            use a tailor-made framework built in Objective-C, Swift, or
            JavaScript with the latest Cordova and PhoneGap tools to develop
            native apps.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
