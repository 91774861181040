import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img virtual">
        <div className="left-img virtual">
          <div className="short-line"></div>
          <h2 className="heading-1">
            Virtual Reality Game Development Services
          </h2>
          <p>
            Get highly interactive and platform-agnostic next-generation VR
            games that provide an immersive and realistic experience and feature
            360-degree panoramic visuals. At BTG, we create highly engaging
            games that have high monetization potential. Call us now!
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
