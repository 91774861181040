import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/game/augmentsreality/Augment-reality_design05.png";
import Android2 from "../../../../images/indeximg/game/augmentsreality/Augment-reality_design01.png";

import Android3 from "../../../../images/Services/Androidapp-Design03.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec augmen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Get Fascinating & Realistic Games using AR Technology</h3>
                  <p>
                    Create AR-based realistic and fascinating games that can
                    overlay digital information over the real world. By
                    utilizing the power of advanced game engines like Unity 3d
                    and Unreal Engine 4, you can create AR games with realistic
                    graphics and immersive animations that can attract a massive
                    audience. This platform also features a variety of
                    customizations and options to ensure that everyone can enjoy
                    our games.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>We bring Innovative Novelty to your AR Games</h3>
                  <p>
                    At BTG Technologies, we strive hard to blur the line between
                    fantasy and reality. We aimed to provide our customers with
                    innovative games in the real world with virtual objects and
                    characters imposed over them. Our digital content
                    interaction with the real world can redefine how users
                    interact with your games. Contact us now to make your next
                    project a big hit!
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    Platform-agnostic Games with Highly Optimized Services
                  </h3>
                  <p>
                    We turn imagination into reality by creating realistic games
                    that allow users to interact and explore 3D objects and
                    environments. Our team ensures the smooth and seamless
                    functionality of games by optimizing their performance
                    according to different platforms. We also eliminate the need
                    for excessive coding and multi-game development by providing
                    games compatible with multi-devices such as mobile phones,
                    tablets, and headsets. Call us now to start your AR project
                    today!
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
