import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>What can we do for you?</h3>
                  <p>
                    Blockchain is an ideal and cost-effective platform that can
                    provide businesses with the highest level of security for
                    transactions and development. By eliminating the need for
                    third parties and vendors, Blockchain empowers enterprises
                    to cut down on costs.
                  </p>
                  <ul>
                    <li>Private Blockchain Development</li>
                    <li>Private Blockchain Consulting</li>
                    <li>Private Blockchain Integration</li>
                  </ul>
                  <ul>
                    <li>Blockchain-Based Wallets & Marketplaces</li>
                    <li>Private Blockchain Security</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
