import React from "react";
// import Navbares from "./Component/Nev";
import MainBanner from "../../images/aboutimg/whyworkwithus.png";
import First from "../../images/aboutimg/3.png";
import Second from "../../images/aboutimg/4.png";

import "./about.css";
function Whywork() {
  return (
    <>
      <section className="WhyWork-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12">
              <div className="top-bar-border"></div>
              <div className="Whymain-head">
                <h3>Why Work With Us?</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <div className="whywork-para">
                <div className="whyworkpara">
                  <p>
                    BTG Technologies was created with the vision to harness the
                    human potential in the unending world of digital domain.
                    Obsessed with the innovation, integrity and agility the
                    company was launched to disrupt the existing trends of
                    business and provide heights of digital excellence through
                    software development and SaaS applications.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2 col-sm-2 col-md-2  col-lg-2 col-xl-2">
                  <div className="whyworkfirstimg">
                    <img src={First} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-10 col-sm-10 col-md-10  col-lg-10 col-xl-10">
                  <div className="whywork-para">
                    <div className="whyworkpara">
                      <p>
                        BTG Technologies believes to deliver excellence to the
                        client through the network of expert developers and
                        customized solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-2 col-sm-2 col-md-2  col-lg-2 col-xl-2">
                  <div className="whyworksecimg">
                    <img src={Second} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-10 col-sm-10 col-md-10  col-lg-10 col-xl-10">
                  <div className="whywork-para">
                    <div className="whyworkpara">
                      <p>
                        We hope that our client will enjoy the product
                        development journey with BTG Technologies and will keep
                        on reverting back for every product development,
                        services and business solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              <div className="mainbanner-img d-flex justify-content-center d-xl-block">
                <img src={MainBanner} alt="MainBanner" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Whywork;
