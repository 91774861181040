import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/digital-market/ppc/ppc-Design5.png";
import Android2 from "../../../../images/indeximg/digital-market/ppc/ppc-Design2.png";

import Android3 from "../../../../images/indeximg/digital-market/ppc/ppc-Design1.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec ppc">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">Techniques Used for PPC</h3>
                  <p className="big">
                    Google Pay per Click (PPC) is the advertising industry's
                    go-to solution for onsite and offsite campaigns aiming to
                    generate business leads or sales. PPC is a great way to get
                    your website noticed by potential customers. Several types
                    of Google Pay per Click (PPC) advertising are available,
                    including text ads, display ads, and mobile ads.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">Convert Clicks into Sales with us</h3>
                  <p className="big">
                    Leading brands globally adapt Google PPC to generate more
                    leads and conversions. We at BTG Technologies work
                    untiringly to choose high-volume and low-competition
                    keywords, create relevant ads and campaigns, and track
                    results to turn each click into a potential customer. Call
                    us now!
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">Leverage the Features of PPC</h3>
                  <p className="big">
                    Google Pay Per Click (PPC) allows you to spend your ad
                    budget on only the ads that are most likely to produce
                    results. It also helps to target users based on their
                    interests and views, which can help you reach more people
                    with your ads. In addition, PPC allows you to manage
                    expenses and optimize campaigns for maximum ROI.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
