import React from 'react'
import Navbares from "../Component/Nev";
import "./Thankyou.css";


const Thankyou = () => {
    return (
        <>
            <Navbares />
            <main>
                <div className='wrapper'>
                    <img src={require('../images/Thankyou.png')} alt='thankyou' />
                    <div>
                        <h1 className='para m-0 thanks-text '> Thank You </h1>
                        <h1 className='thanks-text'> For Contacting Us</h1>
                        <h4>We have received your email. Our team will get in touch with you shortly.</h4>
                        <p></p>
                    </div>
                </div>
            </main>
        </>

    )
}

export default Thankyou
