import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img laravel">
        <div className="left-img laravel">
          <div className="short-line"></div>
          <h2 className="heading-1">Laravel Development Services</h2>
          <p>
            Get robust web apps with powerful PHP Laravel technology. We empower
            startups and growing enterprises with clean and well-organized
            code-based apps to stand out in the competition.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
