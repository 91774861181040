import React from "react";
import main from "../../../../images/indeximg/E-commerce/Shopify/Android-app-development-png.png";
// import blockchain from "../images/indeximg/blockchain-header.png";
// import digital from "../images/indeximg/digital-marketing.png";
// import game from "../images/indeximg/game-development.png";
// import metaverse from '../images/indeximg/metaverse-header.png';
// import mobile from "../images/indeximg/mobile-app.png";
// import uiux from "../images/indeximg/uiux-header01.png";
// import webd from "../images/indeximg/webd-evelopment.png";
function MainBanner() {
  return (
    <>
      <section className="main-banner">
        <div className="main-img">
          <img
            src={main}
            className="img-fluid"
            alt="E-Commerce Development"
            // style={{ paddingTop: 100, paddingBottom: 100 }}
          />
          <div className="main-heabs">
            <h3>Shopify Development Services </h3>
          </div>
        </div>
        {/* <div className="container"></div> */}
      </section>
    </>
  );
}
export default MainBanner;
