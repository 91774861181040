import React from "react";
// import Navbares from "./Component/Nev";
import First from "../../../images/solutions/demand/innovative-approach.png";
import First1 from "../../../images/solutions/demand/customizeapp-appearance.png";
import First2 from "../../../images/solutions/demand/free-Consultation.png";

function Expand() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Why Choose us</h3>
                  {/* <p>
                    With Android being one of the most popular Software, you can
                    easily diversify your revenues by using it in your marketing
                    and finances to be more competitive. Our diverse team of
                    engineers and developers can scale your business to more
                    global audiences. Contact us now!
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Innovative Approach</h3>
                  <p>
                    We can help to streamline your workflows with a flexible and
                    innovative approach.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First1}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Swift App Development</h3>
                  <p>
                    Our developers work with clients to create apps in a
                    fraction of the usual time.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First2}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Free Consultation</h3>
                  <p>
                    With our technical approach, we can transform your business
                    ideas into reality.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Expand;
