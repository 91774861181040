import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../images/solutions/Maintenance/supportmaintenace_design03.png";
import Android2 from "../../../images/solutions/Maintenance/supportmaintenace_design02.png";

import Android3 from "../../../images/solutions/Maintenance/supportmaintenace_design01.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec maintain">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">Why is Maintenance & Support needed?</h3>
                  <p className="big">
                    Proper maintenance and support are pillars to keep your
                    business running smoothly and effortlessly. Regardless of
                    the type of software or its usage, proper and adequate
                    software maintenance is required to provide users with
                    high-quality products with reliable user interfaces while
                    still providing sufficient support for resolving errors and
                    preventing them from occurring in the first place. Properly
                    managed software can save the company millions of dollars in
                    both time and money while minimizing data loss or system
                    downtime.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">Brewing Solutions for IT Glitches</h3>
                  <p className="big">
                    Whether it’s software crashing or a website going down,
                    there’s nothing worse than an IT glitch that not only
                    disturb your workflow but also results in frustrating and
                    angry customers. But to save you from these pesky glitches
                    and customer loss, we are here. Our life-savior maintenance
                    programs can help elevate your software standards while
                    keeping your clients happy and satisfied. We can help you
                    troubleshoot your problems and errors that may hinder your
                    business growth.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">
                    Reduce system failures, Downtime, & Frustration with BTG!
                  </h3>
                  <p className="big">
                    Our experienced team can take on any app maintenance or
                    support task quickly and efficiently, guaranteeing that your
                    projects are always up-to-date and meeting the needs of your
                    users. Our software support services can help you save time
                    and helps in enhanced customer satisfaction and
                    productivity. Contact us today to learn more about our
                    maintenance and support services!
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
