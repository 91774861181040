import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img magen">
        <div className="left-img magen">
          <div className="short-line"></div>
          <h2 className="heading-1">Magento Development Services</h2>
          <p>
            Boots your business growth and revenues with a powerful Magento
            eCommerce store. We have a certified team of developers who works
            tirelessly to deliver you the best and high-end eCommerce sites that
            can help you accelerate sales.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
