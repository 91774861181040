import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
// import Android1 from "../../../../images/Services/hybridapp/hybrid-Deign-02.png";
import Prompt1 from "../../../../images/Services/Prompt-Engineering/Prompt-engineering-pic01.png";
import Prompt2 from "../../../../images/Services/Prompt-Engineering/Prompt-engineering-pic02.png";
import Prompt3 from "../../../../images/Services/Prompt-Engineering/Prompt-engineering-pic03.png";

// import Android2 from "../../../../images/Services/hybridapp/hybrid-Deign-03.png";
import First from "../../../../images/Services/hybridapp/fast-deployment.png";
import First1 from "../../../../images/Services/hybridapp/customer-loyalty.png";
import First2 from "../../../../images/Services/hybridapp/highly-scaleable.png";
import First3 from "../../../../images/Services/hybridapp/cost-effective.png";

// import Android3 from "../../../../images/Services/hybridapp/hybrid-Deign-01.png";
import First4 from "../../../../images/Services/hybridapp/higher-productivity.png";
import First5 from "../../../../images/Services/hybridapp/global-audience.png";
import First6 from "../../../../images/Services/hybridapp/enhance-security.png";
import First7 from "../../../../images/Services/hybridapp/great-efficiecny.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec ms">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Why Invest in Prompt Engineering</h3>
                  <p>
                    Our expertly crafted prompts fuel AI models, ensuring they provide accurate, context-aware responses. Whether it's improving customer interactions, content generation, or data analysis, investing in prompt engineering unlocks the full potential of your AI investments.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Fast Deployment</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First1}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Customer Loyalty</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First2}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Highly Scalable</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First3}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Cost-Effective</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Prompt1}
                  className="img-fluid "
                  alt="Prompt1"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Prompt2}
                  className="img-fluid "
                  alt="Prompt2"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    Strengthening your Marketing Infrastructure with Prompt Engineering
                  </h3>
                  <p>
                    Our specialized prompt creation optimizes AI for marketing, generating tailored content, audience insights, and personalized recommendations. Enhance customer engagement, drive conversions, and gain a competitive edge in the dynamic marketing landscape through strategic AI prompt engineering.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First4}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Higher Productivity</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First5}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Global Audience</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First6}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Enhanced Security</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First7}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Great Efficiency</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    We are the Best AI Prompt Engineering Service in Town!
                  </h3>
                  <p>
                    We're the best AI Prompt Engineering service in town! Our expertise in crafting precise and effective prompts fuels AI models to deliver exceptional results. Elevate your projects with tailored prompts that maximize accuracy and relevance. Trust us for top-notch AI prompt engineering that outshines the competition.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Prompt3}
                  className="img-fluid"
                  alt="Prompt3"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
