import React from "react";
// import Navbares from "./Component/Nev";
import MainBanner from "../../images/aboutimg/aboutus.png";

import "./about.css";
function Lettalk() {
  return (
    <>
      <section className="mainbanner-sec">
        <div className="container-fluid mt-5 mt-md-0 mt-lg-5">
          <div className="row mt-md-5">
            <div className="col-12 col-lg-6">
              <div className="mainbanner-img d-flex justify-content-center d-lg-block">
                <img src={MainBanner} alt="MainBanner" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-auto mt-auto">
              <div className="mainbanner-head">
                <div className="main-heading">
                  <h3>About Us</h3>
                  <p>
                    BTG is multi service, multi solution software company that
                    is fuelled by highly competent and multi talented
                    developers. We provide services to SME’s and Big Business
                  </p>
                  <p>
                    Enterprises. The start ups are close to our hearts and we
                    support them through out their journey. We breathe life to
                    their nascent ideas through work of co creation and digital
                    excellence and convert these nascent ideas into towering
                    success
                  </p>
                  <p className="about-para">
                    BTG not only works on new projects but also understand the
                    bottlenecks and flow faults within existing non performing
                    companies. We reinvent and automate with digital excellence
                    to work on your financial growth and customer retention.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Lettalk;
