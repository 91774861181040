import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img uxres">
        <div className="left-img uxrese">
          <div className="short-line"></div>
          <h2 className="heading-1">UX Research Services</h2>
          <p>
            Get real-world insights on how to improve your user experience with
            our UX research services. We use the latest techniques and tools to
            gather data and insights to help you make informed decisions about
            your product or service.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
