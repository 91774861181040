import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img social-media">
        <div className="left-img social-media">
          <div className="short-line"></div>
          <h2 className="heading-1">Social Media Marketing Services</h2>
          <p>
            Hire us to get the maximum ROI and sales for your business. From
            developing social media campaigns to managing content and ads, we
            can craft an effective way to boost your reach.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
