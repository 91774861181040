import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Expertise in Angular Development</h3>
                  <p>
                    Get real-time, fast, and responsive web apps by hiring our
                    professional Angular developers. We use JavaScript to build
                    apps for businesses of all sizes, big or small. From
                    e-commerce to healthcare and logistics, we have expertise
                    working with multiple industries. We utilize different
                    project management tools to ensure the productivity and
                    functionality of the product.
                  </p>
                  <ul>
                    <li>Custom App Development</li>
                    <li>SPA Development</li>
                    <li>Migration to Angular</li>
                  </ul>
                  <ul>
                    <li>Web App Modernization & Upgradation</li>
                    {/* <li>Enterprise Apps Development</li>
                    <li>Android App Integration & Modernization</li> */}
                  </ul>
                  <ul>
                    <li>API Development & Integration</li>
                    <li>Maintenance & Support</li>
                    <li>UI/UX Designing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
