import React, { Component } from "react";
// team svg

import Our from "react-slick";
// import f1 from "../images/indeximg/icon/data-backup-01.svg";
import f1 from "../images/indeximg/ourkey/agile-developmnet-01.png";
import f2 from "../images/indeximg/ourkey/data-backup-01.png";
import f3 from "../images/indeximg/ourkey/centric-development-01.png";
import f4 from "../images/indeximg/ourkey/data-protection-01.png";
import f5 from "../images/indeximg/ourkey/dedicated-team-01.png";
import f6 from "../images/indeximg/ourkey/quality-delivery-01.png";
import f7 from "../images/indeximg/ourkey/support-01.png";

// import f2 from "../images/indeximg/icon/agile-developmnet-01.svg";

// import Navbares from "./Component/Nev";

export default class MultipleItems extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      // autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            // autoplaySpeed: 2000,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
            autoplay: true,
          },
        },
        {
          breakpoint: 599,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
          },
        },
      ],
    };
    return (
      <div>
        <section className="ourkey-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="main-head"></div>
                <h2 className="dev-headings white-section ">
                  Our Key Feature
                </h2>
                <p className="dev-landing-para">
                  Here, we make almost every genre of applications. You name it
                  and we build it.
                </p>
                <Our {...settings}>
                  <div class="slide">
                    <div class="serviceBox">
                      <div class="service-icon">
                        <img
                          src={f1}
                          //   onload="Wpfcll.r(this,true);"
                          //   src={dedicated}
                          width="90"
                          height="90"
                          //   //   data-wpfc-original-src={dedicated}
                          //   class="vc_single_image-img attachment-full"
                          //   alt="reponsive design icon"
                          //   loading="lazy"
                          alt="f1"
                        />
                      </div>
                      <h3 class="title">Agile Development</h3>

                      <p class="description">
                        We follow the Agile Development process that helps us to
                        deliver the project with utmost quality and solid
                        product for reliable and scalable business.
                      </p>
                      <br />
                    </div>
                  </div>
                  <div class="slide">
                    <div class="serviceBox">
                      <div class="service-icon">
                        <img
                          src={f5}
                          width="90"
                          height="90"
                          alt="reponsive design icon"
                          loading="lazy"
                        />
                      </div>
                      <h3 class="title">Dedicated Development Team</h3>

                      <p class="description">
                        Our skilled team leverage projects to provide best
                        results.The team dedicates their every effort and does
                        not look back until it's accomplished.
                      </p>
                      <br />
                    </div>
                  </div>
                  <div class="slide">
                    <div class="serviceBox">
                      <div class="service-icon">
                        <img
                          src={f3}
                          width="90"
                          height="90"
                          alt="reponsive design icon"
                          loading="lazy"
                        />
                      </div>
                      <h3 class="title">Client Centric Development</h3>

                      <p class="description">
                        We craft the client's needs on web, mobile, and
                        blockchain to tailor the solutions and enhance growth of
                        the businesses.
                      </p>
                      <br />
                    </div>
                  </div>
                  <div class="slide">
                    <div class="serviceBox">
                      <div class="service-icon">
                        <img
                          src={f7}
                          width="90"
                          height="90"
                          class="vc_single_image-img attachment-full"
                          alt="reponsive design icon"
                          loading="lazy"
                        />
                      </div>
                      <h3 class="title">Excellent Support</h3>

                      <p class="description">
                        We are always there to assist our clients in every
                        possible way to meet client's expectations and end
                        needs.
                      </p>
                      <br />
                    </div>
                  </div>
                  <div class="slide">
                    <div class="serviceBox">
                      <div class="service-icon">
                        <img
                          src={f2}
                          width="90"
                          height="90"
                          class="vc_single_image-img attachment-full"
                          alt="reponsive design icon"
                          loading="lazy"
                        />
                      </div>
                      <h3 class="title">Data Backups</h3>

                      <p class="description">
                        The backups are the best options and we carefully
                        maintain our clients project backups to deal with
                        misfortunes in the best manner.
                      </p>
                      <br />
                    </div>
                  </div>
                  <div class="slide">
                    <div class="serviceBox">
                      <div class="service-icon">
                        <img
                          src={f4}
                          width="90"
                          height="90"
                          class="vc_single_image-img attachment-full"
                          alt="reponsive design icon"
                          loading="lazy"
                        />
                      </div>
                      <h3 class="title">Data Protection</h3>

                      <p class="description">
                        By signing an NDA, our company ensures the utmost safety
                        of all your private data. We deliver best-rated products
                        to maintain confidentiality.
                      </p>
                      <br />
                    </div>
                  </div>
                  <div class="slide">
                    <div class="serviceBox">
                      <div class="service-icon">
                        <img
                          src={f6}
                          width="90"
                          height="90"
                          class="vc_single_image-img attachment-full"
                          alt="reponsive design icon"
                          loading="lazy"
                        />
                      </div>
                      <h3 class="title">Quality Deliverance</h3>

                      <p class="description">
                        We believe in delivering quality products to our clients
                        by assuring all their project specifications at the best
                        industry competitive price.
                      </p>
                      <br />
                    </div>
                  </div>
                  <div class="slide">
                    <div class="serviceBox">
                      <div class="service-icon">
                        <img
                          src={f1}
                          width="80"
                          height="80"
                          class="vc_single_image-img attachment-full"
                          alt="reponsive design icon"
                          loading="lazy"
                        />
                      </div>
                      <h3 class="title">Enhancement</h3>

                      <p class="description">
                        During Discovery, Designing and Development phases, our
                        technical team is always there to provide suggestions
                        and edits to improvise their product in the best
                        possible manner.
                      </p>
                      <br />
                    </div>
                  </div>
                </Our>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
// export default Ourkeyfeature;
