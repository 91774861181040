import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img bigcom">
        <div className="left-img bigcome">
          <div className="short-line" style={{ backgroundColor: "#000" }}></div>
          <h2 className="heading-1 big">BigCommerce Development Services</h2>
          <p className="big">
            Hire the best developers at BTG Technologies. We can help you build,
            customize and optimize your BigCommerce store exactly how you want
            it. Spend more time focusing on your business instead of scrambling
            to fix a problem with your BigCommerce store.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
