import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
import Mainlap from "../../images/aboutimg/expanding_images_laptop.png";
import Mainlog from "../../images/aboutimg/BTG-LOGO.png";
// import ab1 from "../../images/about-images/1.png";
// import ab2 from "../../images/about-images/2.png";
// import ab3 from "../../images/about-images/3.png";
// import ab4 from "../../images/about-images/4.png";
// import ab5 from "../../images/about-images/5.png";
// import ab6 from "../../images/about-images/6.png";
// import ab7 from "../../images/about-images/7.png";
// import ab8 from "../../images/about-images/8.png";

const Pricing = () => {
  return (
    useEffect(() => {
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 50) {
          $(".flower").addClass("active");
        } else {
          $(".flower").removeClass("active");
        }
      });
      // $(window).scroll(function() {

      //   var scroll = $(window).scrollDown();

      //   if (scroll <= 50) {
      //       $(".flower").addClass("active");
      //   } else {
      //       $(".flower").removeClass("active");
      //   }
      // });
      Aos.init({ duration: 2000 });
    }, []),
    (
      <section className="bockets">
        <div className="container-fluid">
          {/* <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="bockets">
                  <div className="Bucketimg">
                  <div className="qode-expanding-images-inner">
                    <img
                      src={Mainlap}
                      className="qode-frame-image"
                      alt="laptop frame"
                    />
                    <div className="qode-hero-image">
                      <img
                        src={Mainlog}
                        className="qode-frame-image qode-lazy-image"
                        alt="hero image"
                        style={{ height: "auto" }}
                      />
                    </div>
                    <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="one-img " data-aos="slide-left">
                      <div className="das">
                  <img
                        src={ab1}
                        className="qode-frame-image qode-lazy-image"
                        alt="hero image"
                        style={{ height: "100px" }}
                      />
                      </div>
                  </div>
                  <div className="two-img" data-aos="slide-left">
                  <div className="das">
                  <img
                        src={ab2}
                        className="qode-frame-image qode-lazy-image"
                        alt="hero image"
                        style={{ height: "100px" }}
                      />
                  </div>
                  </div>
                  <div className="three-img" data-aos="slide-left">
                  <div className="das">
                  <img
                        src={ab3}
                        className="qode-frame-image qode-lazy-image"
                        alt="hero image"
                        style={{ height: "100px" }}
                      />
                  </div>
                  </div>
                  <div className="four-img" data-aos="slide-left">
                  <div className="das">
                  <img
                        src={ab4}
                        className="qode-frame-image qode-lazy-image"
                        alt="hero image"
                        style={{ height: "100px" }}
                      />
                  </div>
                  </div>
</div>
<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
</div>
                    </div>
                   
                  </div>
                  

                  </div>
                </div>
            </div>
            </div> */}

          <div className="main-container">
            <div className="heading-wrapper">
              {/* <h1>Flowers heading</h1> */}
              <img
                src={Mainlap}
                className="qode-frame-image"
                alt="laptop frame"
              />
              <div className="qode-hero-image">
                <img
                  src={Mainlog}
                  className="qode-frame-image qode-lazy-image"
                  alt="hero image"
                />
              </div>
            </div>
            <div className="btgbouquet">
              <h1>BTG's bouquet of consultation, services and solutions.</h1>
            </div>
            <div className="flower tl">
              <div className="flower1"></div>
            </div>
            {/* <div className="flower1 ts"></div> */}
            <div className="flower bl">
              <div className="flower2"></div>
            </div>
            <div className="flower tr">
              <div className="flower3"></div>
            </div>
            <div className="flower br">
              <div className="flower4"></div>
            </div>

            <div className="flower tls">
              <div className="flower5"></div>
            </div>

            <div className="flower bs">
              <div className="flower6"></div>
            </div>
            <div className="flower ts">
              <div className="flower7"></div>
            </div>
            <div className="flower bss">
              <div className="flower8"></div>
            </div>
          </div>

          {/* <div className="content"></div><div className="main-container">
  <div className="flower tl"></div>
  <div className="flower bl"></div>
  <div className="flower tr"></div>
  <div className="flower br"></div>
  <div className="heading-wrapper">
    <h1>Flowers heading</h1>
  </div>
</div>

<div className="content"></div> */}
        </div>
      </section>
    )
  );
};

export default Pricing;
