import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img demand">
        <div className="left-img demand">
          <div className="short-line"></div>
          <h2 className="heading-1">On-demand App Development</h2>
          <p>
            Get highly convenient, efficient, and functional on-demand apps that
            can deliver your products or services to your customers in a matter
            of minutes. We also enable our clients to track their customer
            analytics by providing real-time insights.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
