import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img type">
        <div className="left-img type">
          <div className="short-line"></div>
          <h2 className="heading-1">TypeScript Development Services</h2>
          <p>
            We are a renowned and leading software company empowering businesses
            with scalable, high-performance web pages and apps built with
            TypeScript language.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
