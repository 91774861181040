import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../images/solutions/demand/ondemandapp_design01.png";
import Android2 from "../../../images/solutions/demand/ondemandapp_design02.png";

import Android3 from "../../../images/solutions/demand/ondemandapp_design04.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec demand">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Now is the Time for On-demand Apps!</h3>
                  <p>
                    Mobile technology has intelligently turned into tools that
                    can help users access different services in no time. Whether
                    it's a pick-and-drop app, taxi booking app, or virtual
                    consultation app, you can get any service you want with
                    prominent and functional features.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Join hands with BTG to Manage your Customer Requests</h3>
                  <p>
                    We at BTG have expertise in providing customized and
                    flexible on-demand apps compatible with your business. We
                    can provide you with mobile-based apps enabling your users
                    to request and receive services with just a few taps, like
                    Uber, Airbnb, and Deliveroo.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Get your App ready in a Fraction of the Time</h3>
                  <p>
                    On-demand apps are becoming increasingly popular as they
                    offer a convenient and user-friendly way to access a wide
                    range of services. These apps provide users with services or
                    products in a matter of minutes. The upside to this kind of
                    quick turnaround is that you can get your app up and running
                    much faster than if you were to develop it traditionally.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
