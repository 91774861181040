import React from "react";
import Navbares from "../../../Component/Nev";
import "../../../Blog.css";
import "../../../App.css";

import Footer from "../../../Component/Footer";
import MainBanner from "../../../Component/Services/Blockchain-Development/Exchange/Main-banner";
import QuickSolution from "../../../Component/Services/Blockchain-Development/Exchange/Quicksolution";
import Fintechapp from "../../../Component/Services/Blockchain-Development/Exchange/Fintech-App";

// import Getintech from "../../../Component/Services/Blockchain-Development/Exchange/GetIntech";
import Getintech from "../../../Component/LetsBusiness";
import Document from "../../../Document";

function Exchange() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <MainBanner />
        <QuickSolution />
        <Fintechapp />
        {/* <Choose /> */}
        <Getintech info={'Exchange'} />

        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={'Exchange'} />
    </>
  );
}

export default Exchange;
