import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img sasasa">
        <div className="left-img asas">
          <div className="short-line"></div>
          <h2 className="heading-1">React Native App Development</h2>
          <p>
            Get your cross-platform and native-feel app ready with us with React
            Native technology. Our team has years of experience in the React
            Native market, and we can help you create high-quality Android and
            iOS apps quickly and at a fraction of the cost.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
