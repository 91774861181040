import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Let us Handle your Project</h3>
                  <p>
                    Our team specializes in crafting tailored prompts to optimize your AI systems. From fine-tuning chatbots to enhancing virtual assistants, we ensure your AI projects deliver intelligent and context-aware responses, making your applications smarter and more effective.
                  </p>
                  <ul>
                    <li>Language Generation</li>
                    <li>Question Generation</li>
                    <li>Code Generation</li>
                    <li>Translation</li>
                  </ul>
                  <ul>
                    <li>Summarization</li>
                    <li>Text Completion</li>
                    <li>Recommendation Systems</li>
                    <li>Machine Translation</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
