import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Choose our Exceptional Unity 3d Development Services</h3>
                  <p>
                    BTG specializes in creating and developing engaging,
                    realistic, and exciting games for all platforms, including
                    mobiles, web, NFTs, consoles, and tablets. From
                    conceptualizing to resource designing and deployment, we
                    have everything you need to get your project off the ground
                  </p>
                  <ul>
                    <li>Unity Game Development</li>
                    <li>Unity Game UI/UX Designing</li>
                    <li>Game Migration to Unity 3d</li>
                  </ul>
                  <ul>
                    <li>Unity 2d/3d Animation Development</li>
                    <li>Unity Game Optimization</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
