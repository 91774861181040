import React from "react";
import Automation from "../../../../images/indeximg/game/metaverse/crypto-wallet.png";
import Secure from "../../../../images/indeximg/game/metaverse/P2E-Mechanics.png";
import cost from "../../../../images/indeximg/game/metaverse/Multi-currency.png";
import greate from "../../../../images/indeximg/game/metaverse/Decentralized-standard.png";
import icon from "../../../../images/solutions/Icon1.png";

function QuickSolution() {
  return (
    <>
      <section className="QuickSolution-sec">
        <div className="Quick-maas">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="Quick-head">
                  <h3>
                    Build Real-time <strong> Multiplayer</strong> Games
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quick-fir">
          <div className="container">
            <div className="row quick-sec">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={Automation}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Advanced Features like Crypto Wallet & Asset-Trade</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={Secure}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Advanced UI & P2E Mechanics</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={cost}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Multi-currency & Langauge Support</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={greate}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Decentralized with Interoperable Standards</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-5 pb-5">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>
                      2d/3d Metaverse Game<br></br> Development
                    </h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      We leverage Metaverse's latest technologies, such as
                      gaming engines, middleware, and programming solutions, to
                      develop interactive virtual worlds.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main sec1">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>AR/VR Game Development</h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      From creating compelling stories to developing engaging
                      interfaces and avatars, we are specialized in developing
                      AR/VR Metaverse games.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main sec2">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>P2E NFT Game Development</h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      Now trade your digital assets in a secure and enjoyable
                      environment by collaborating with one of the best gaming
                      agencies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default QuickSolution;
