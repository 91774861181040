import React from "react";
// import Navbares from "./Component/Nev";
// import Android1 from "../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images//Services/Ionic/Ionic-Deign-01.png";
import First from "../../../../images/Services/Ionic/1.png";
import First1 from "../../../../images/Services/Ionic/customize-app.png";

import fintech_design05 from "../../../../images/Services/Ionic/Ionic-Deign-05.png";
import First4 from "../../../../images/Services/Ionic/experienced-developers.png";
import First5 from "../../../../images/Services/Ionic/design to deployment.png";
import fintech_design04 from "../../../../images/Services/Ionic/Ionic-Deign-03.png";
import First7 from "../../../../images/Services/Ionic/cyber-attack.png";
import First8 from "../../../../images/Services/Ionic/6.png";

function Fintechapp() {
  return (
    <>
      <section className="Fintech-sec ionic">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>What makes Ionic worth Investment?</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Ionic is a popular, fast-growing platform for building
                    mobile apps which helps businesses to grow and expand faster
                    and wider. It offers a drag-and-drop interface, supports
                    both Android and iOS, and has a large community of
                    developers.
                  </p>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          We develop ionic apps that are designed to be fast and
                          easy to use design and look great on all devices.
                          Additionally, Ionic features such as modularity and
                          pre-made templates help speed up the app development
                          process.
                        </p>
                        {/* <div
                          className="separator small left"
                          style={{
                            marginTop: 0,
                            marginBottom: 15,
                            backgroundColor: "#ffffff",
                            height: 2,
                            width: 30,
                          }}
                        /> */}
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First1}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Ionic apps offer a high degree of flexibility and
                          customizability that allows you to create apps
                          compatible with Android, iOS, and PWA. So if you're
                          ready to take your business up a notch, consider
                          adapting the Ionic platform for building mobile apps.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Helping Prospective Clients Find the Best App</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Get your Ionic app ready with BTG Technologies. We are among
                    the most reliable and professional software developers in
                    town who use an open-source ionic framework and online
                    resources to build ionic apps.
                  </p>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First4}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Our experienced developers have a deep understanding
                          of this front-end mobile app development platform and
                          use ionic framework, HTML, CSS, and JavaScript to
                          create the app's user interface and backend
                          functionality.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First5}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          From design to deployment, we will help you to get the
                          most functional and well-designed apps catered to your
                          needs. If you're interested in learning more about
                          Ionic app development or want to develop mobile apps
                          using this popular framework, visit our website or
                          call us today!
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design05}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Itinerate your Apps with Ease!</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design04}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Migrate your apps from native to hybrid by use of our
                    high-end services. Migration to the Ionic platform can
                    enhance the stability and performance of the app while
                    eliminating the chances of bugs, crashes, and errors.
                  </p>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First7}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          This advanced platform is also secure from
                          cyber-attacks and malfunctions and can save you from
                          potential losses. By taking these factors into account
                          during development, we encourage our clients to
                          migrate to Ionic to uplift their businesses.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First8}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          App migration will allow you to take advantage of all
                          the features of the newly updated version. Our
                          experienced team has the knowledge and proficiency to
                          make the transition flawless while ensuring that your
                          apps remain functional and operational while
                          undergoing the migration process.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Fintechapp;
