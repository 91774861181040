import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Take Leverage of our Expertise</h3>
                  <p>
                    We at BTG Technologies provide state-of-the-art services to
                    make your brand superior and reputable. Our goal is to help
                    you create a differentiated and compelling brand that will
                    resonate with your target audience and drive business
                    success.
                  </p>
                  <ul>
                    <li>Brand Vision & Strategic Planning</li>
                    <li>Brand Positioning</li>
                    <li>Brand Architecture</li>
                  </ul>
                  <ul>
                    <li>UX Strategy</li>
                    <li>Positioning & Messaging</li>
                    {/* <li>Android App Integration & Modernization</li> */}
                  </ul>
                  <ul>
                    {/* <li>On-demand Apps Development</li>
                    <li>Android Apps Optimization & QA</li> */}
                    {/* <li>Cross-platform Android App Development</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
