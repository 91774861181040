import React from "react";
// import Navbares from "./Component/Nev";
import AIChatbot1 from "../../../../images/Services/AI-Chatbot/Aichatbot-pic01.png";
import AIChatbot2 from "../../../../images/Services/AI-Chatbot/Aichatbot-pic02.png";
import AIChatbot3 from "../../../../images/Services/AI-Chatbot/Aichatbot-pic03.png";
import "./Service.css";
// import Android1 from "../../images/Services/Androidapp-Design01.png";
// import Android2 from "../../../../images/Services/Androidapp-Design02.png";
import First from "../../../../images/Services/increase-efficiency.png";
import First1 from "../../../../images/Services/customer-relation.png";
import First2 from "../../../../images/Services/Elevated-sales.png";
import First3 from "../../../../images/Services/customer-engagment.png";

// import Android3 from "../../../../images/Services/Androidapp-Design03.png";
import First4 from "../../../../images/Services/userfriendly-app.png";
import First5 from "../../../../images/Services/visually-appealing.png";
import First6 from "../../../../images/Services/esay-navigation.png";
import First7 from "../../../../images/Services/customize-app.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Chatbot Solution for Everyone</h3>
                  <p>
                    Discover Chatbot Solutions for Everyone at BTG Technologies. Whether you're a small business seeking cost-effective customer support or an enterprise looking to enhance efficiency, our diverse range of chatbot offerings caters to all. Our customizable, AI-powered chatbots streamline interactions, improve customer engagement, and drive results across industries.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={AIChatbot1}
                  className="img-fluid "
                  alt="Android"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={AIChatbot2}
                  className="img-fluid "
                  alt="Android"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Breathe Life into Your Marketing with AI Chatbot</h3>
                  <p>
                    Our innovative chatbots inject vitality into customer interactions, offering personalized recommendations, gathering valuable insights, and automating lead generation. Elevate your brand's engagement, enhance user experiences, and stay ahead of the competition with our cutting-edge AI chatbot technology.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Increased Efficiency</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First1}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Improved Customer Relations</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First2}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Elevated Sales & ROI</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First3}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Higher Customer Engagement</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Our AI Chatbot Key features for businesses</h3>
                  <p>
                    Seamlessly integrate chatbots into your operations to provide 24/7 customer support, handle frequently asked questions, and deliver personalized recommendations. Harness the power of natural language processing, data analytics, and multi-platform compatibility to optimize engagement and enhance your business's efficiency.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First4}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>User-Friendly</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First5}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Easy-To-Navigate</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First6}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Easy-To-Navigate</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First7}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Customizable</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={AIChatbot3}
                  className="img-fluid"
                  alt="Android"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
