import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/blockchain/ethereum/ethereum-design05.png";
import Android2 from "../../../../images/indeximg/blockchain/ethereum/ethereum-design03.png";

import Android3 from "../../../../images/indeximg/blockchain/ethereum/ethereum-design01.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec ethere">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Ethereum – A Secure Smart Contract Platform</h3>
                  <p>
                    Ethereum is a platform that is used widely to run smart
                    contracts. It is a decentralized platform that is programmed
                    in a way to resist the possibility of fraud or third-party
                    interference. With its powerful infrastructure and
                    enthusiastic development community, Ethereum is
                    well-positioned to become the backbone of a new
                    decentralized web.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Why Businesses Should Invest In Ethereum</h3>
                  <p>
                    Ethereum is a flexible, scalable, and secure Blockchain
                    platform businesses use for various purposes. Companies use
                    this blockchain to develop decentralized autonomous
                    organizations (DAOs) and applications (DApps). With
                    Ethereum, you can create tokens representing virtual shares,
                    assets, proof of membership, and more. Businesses can use
                    Ethereum to create Smart contracts, which can help them save
                    time and money.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Choose us for your Next Project!</h3>
                  <p>
                    Look no further other than BTG Technologies if you are
                    looking for professional Ethereum development services. We
                    have a team of experienced developers who can help you with
                    all aspects of Ethereum development, from smart contract
                    development to dApp development and more. We can also
                    provide support and maintenance services for your existing
                    Ethereum projects. Contact us today to learn more about our
                    Ethereum Development Services.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
