import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Web App Consulting Expertise</h3>
                  <p>
                    We are one of the leading web development companies
                    specializing in developing dynamic, user-friendly, and
                    compelling websites that match your brand's image and meet
                    your online marketing needs.
                  </p>
                  <ul>
                    <li>Strategic consultation</li>
                    <li>Website designing & development</li>
                    <li>Marketing & optimization</li>
                  </ul>
                  <ul>
                    <li>Analytics Integration</li>
                    <li>Database migration</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
