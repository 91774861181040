import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/blockchain/privat/privateblockchain-design04.png";

// import Android1 from "../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/indeximg/blockchain/privat/privateblockchain-design05.png";

import Android3 from "../../../../images/indeximg/blockchain/privat/privateblockchain-design02.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec private">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Private Blockchain for Strong Security Protocols</h3>
                  <p>
                    A private blockchain is a centralized and closed database
                    distributed ledger that is immutable and crypto-secure. It
                    can be operated only by authorized persons that can restrict
                    the accessibility of other users to their network. Private
                    chains use PoET and Raft algorithms for working.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Why use Private Blockchain?</h3>
                  <p>
                    A private blockchain has low transaction fees and no hidden
                    charges compared to a public blockchain. In addition, it
                    needs a lesser amount of time and money for maintenance.
                    With its authentication process, you can restrict intruders
                    and keep your network secure from cyber-attacks and hacking.
                    It is the best technology that can be used for process
                    privacy, and many organizations are overwhelmed by this
                    feature.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Get leverage of Private Blockchain Features</h3>
                  <p>
                    Private Blockchain is a highly efficient, automated, and
                    secure platform for enterprises with elaborate verification
                    processes. It can also lower transaction risks due to KYC
                    authentication. Contact us if you want to migrate or update
                    your business modules and infrastructure on this highly
                    scalable technology..
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
