import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img emailma">
        <div className="left-img emailma">
          <div className="short-line"></div>
          <h2 className="heading-1">Email Marketing Services</h2>
          <p>
            Email marketing is the quickest and most effective way to turn
            one-time buyers into regular and loyal customers. We are enabling
            businesses to send newsletters, promotional materials, and other
            types of information to their customers and prospects to increase
            their sales.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
