import React from "react";
import Navbares from "../../Component/Nev";
import "../../Blog.css";
import "../../App.css";

// import Lettalk from "../../Component/Let’stalk";
import MainBanner from "../../Component/Consulting/MobileAppConsulting/Main-banner";
import QuickSolution from "../../Component/Consulting/MobileAppConsulting/Quicksolution";
import Fintechapp from "../../Component/Consulting/MobileAppConsulting/Fintech-App";
// import Choose from "../../Component/Consulting/MobileAppConsulting/Choose";
// import Getintech from "../../Component/Consulting/MobileAppConsulting/GetIntech";
import Getintech from "../../Component/LetsBusiness";
import Footer from "../../Component/Footer";
import Document from "../../Document";
function MobileAppConsulting() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <MainBanner />
        <QuickSolution />
        <Fintechapp />
        {/* <Choose /> */}
        <Getintech info={"Mobile App Consulting"} />

        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Mobile App Consulting"} />
    </>
  );
}

export default MobileAppConsulting;
