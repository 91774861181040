import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/digital-market/market-analytics/marketinganalytics-Design3.png";
import Android2 from "../../../../images/indeximg/digital-market/market-analytics/marketinganalytics-Design4.png";

import Android3 from "../../../../images/indeximg/digital-market/market-analytics/marketinganalytics-Design2.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec marketan">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Churning a Premium Solution for your Analytics Needs</h3>
                  <p>
                    Marketing analytics is a statistical analysis tool used to
                    make marketing campaigns successful. We at BTG Technologies
                    use this technique to study consumer behavior to improve
                    marketing campaigns, product design, and customer service.
                    We also enable companies to track customer engagement and
                    determine what changes need to be made to keep customers
                    happy.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Why does Marketing Analytics be important?</h3>
                  <p>
                    Since marketers cannot see everything happening in their
                    marketplaces, they need to use analytics tools to analyze
                    data to make informed decisions. Marketing analytics can
                    predict consumer future demand by observing customer change
                    in behavior. Companies can create more effective marketing
                    campaigns by understanding which products have more selling
                    rates.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    Maximize your Productivity with Analytics Tactics that Make
                    Sense
                  </h3>
                  <p>
                    Our bespoke solutions can provide unparalleled customer
                    insights to help you forecast, realize, and interpret your
                    business’s future. With marketing analytics services, you
                    can track your marketing KPIs and ROI, identify areas of
                    improvement, and adjust your strategy accordingly.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
