import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Expertise</h3>
                  <p>
                    With our experience and expertise, we at BTG Technologies
                    can help you maximize the impact of your email marketing
                    efforts and ensure your campaigns are successful. So if
                    you're looking for help with your next email marketing
                    campaign, contact us today.
                  </p>
                  <ul>
                    <li>Email Marketing Management</li>
                    <li>Custom Template Design & Development</li>
                    <li>Email Marketing Performance Audit</li>
                  </ul>
                  <ul>
                    <li>VR Game Support & Maintenance</li>
                    <li>Email Marketing Automation</li>
                    <li>Enterprise Email Marketing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
