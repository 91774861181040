import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/Services/Reas=ct/React-Deign-02.png";
import First from "../../../../images/Services/Reas=ct/crossplatform-Compatibility.png";
import First1 from "../../../../images/Services/Reas=ct/seo-friendly.png";
import First2 from "../../../../images/Services/Reas=ct/easy-maintenance.png";
import First3 from "../../../../images/Services/customer-engagment.png";

import Android3 from "../../../../images/Services/Reas=ct/React-Deign-03.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec sssds">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>What is React Native?</h3>
                  <p>
                    React Native is a mobile development framework created by
                    Facebook. It allows you to create intuitive user interfaces
                    and applications using JavaScript, rather than Objective-C
                    or Java. React Native is platform-agnostic and can be used
                    to build applications for both iOS and Android platforms.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Get Ahead of the Competition with Optimal Technology</h3>
                  <p>
                    Let us help you have the most functional, interactive, and
                    profitable apps. We at BTG Technologies aim to revamp your
                    infrastructure with our expertise and knowledge to add value
                    to your business. We ensure effective and quick porting of
                    your web business apps to React Native platform.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Cross Platform Compatibility</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First1}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> SEO-Friendly</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First2}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Easy Maintenance</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First3}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Community Support</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Join the Luxurious React Native Ecosystem with us!</h3>
                  <p>
                    We are one of the most reliable and technically-expert
                    software development companies having expertise in building
                    apps based on React Native technology. Our competent and
                    qualified developers create user interfaces using JavaScript
                    and React. Our team will work with you to determine your
                    specific needs and develop a plan to fulfill them
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
