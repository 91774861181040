import React from "react";
// import Navbares from "./Component/Nev";
import First from "../../../../images/Services/skill.png";
import First1 from "../../../../images/Services/customer-satisfication.png";
import First2 from "../../../../images/Services/company-growth.png";
import First3 from "../../../../images/Services/competitive-price.png";
import First4 from "../../../../images/Services/timeframe.png";
import First5 from "../../../../images/Services/privacy-policy.png";
import "./Service.css";
function Expand() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Expand your Market Reach</h3>
                  <p>
                    Our AI-powered chatbots break language barriers, offer real-time support, and guide users through seamless transactions. Whether you're a local business or a global enterprise, our chatbots help you engage with diverse audiences, driving growth and extending your market influence.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First}
                    className="img-fluid "
                    alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Skilled Developers</h3>
                  <p>
                    Our team of knowledgeable developers has years of experience
                    creating fruitful applications for companies of all sizes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First1}
                    className="img-fluid "
                    alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>100% Customer Satisfaction</h3>
                  <p>
                    We assure customer satisfaction to our valuable clients, and
                    we will do everything we can to make sure you are happy with
                    your final product.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First2}
                    className="img-fluid "
                    alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Proven Track Record</h3>
                  <p>
                    We have a proven record of elevating business growth through
                    our functional apps. Give us a call today to book your
                    consultation appointment.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First3}
                    className="img-fluid "
                    alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Transparent Pricing</h3>
                  <p>
                    Our team of experienced developers is dedicated to providing
                    quality services at competitive pricing without compromising
                    on quality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First4}
                    className="img-fluid "
                    alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Adhere Timeframe</h3>
                  <p>
                    With our agile development approach and tight deadlines, we
                    strive to be the top choice for android application
                    developers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First5}
                    className="img-fluid "
                    alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Confidentiality</h3>
                  <p>
                    We understand the sensitivity and vulnerability of your data
                    thus we have a implemented strict privacy policy for our
                    clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Expand;
