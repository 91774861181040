import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../images/consulting/cloud/cloudconsulting-Deign-04.png";
import Android2 from "../../../images/consulting/cloud/cloudconsulting-Deign-01.png";

import Android3 from "../../../images/consulting/cloud/cloudconsulting-Deign-02.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec cloud">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Unleash your Business Potential by Moving to Cloud</h3>
                  <p>
                    Incorporating Cloud potential and features into your
                    business infrastructure can lead to many benefits, including
                    but not limited to scalability, flexibility, and
                    cost-efficiency. From migrating data and applications to the
                    Cloud, configuring networks, and setting up security
                    protocols, we can help you unlock your business potential by
                    reaping the benefits of the Cloud.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Initiate your Cloud Journey with us!</h3>
                  <p>
                    Trust the expert team at BTG Technologies with your cloud
                    project. Our cloud design consultation services can provide
                    you with expert recommendations on the right cloud storage
                    solution for your business. We will work with you to
                    identify your needs, assess potential risks, and recommend
                    the most cost-effective and secure options.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    Get Ready for a Cloud-based Future with an Expert Team
                  </h3>
                  <p>
                    When it comes to delivering Cloud development,
                    modernization, and migration services, you can count on the
                    reliable and knowledgeable team of BTG Technologies. We are
                    helping businesses to transform their framework into
                    cloud-based infrastructures. We can deliver exceptional
                    cloud consultancy services, from online support to guidance
                    and feedback.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
