import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../images/consulting/WebApp/webconsulting-Deign-04.png";
import Android2 from "../../../images/consulting/WebApp/webconsulting-Deign-05.png";

import Android3 from "../../../images/consulting/WebApp/webconsulting-Deign-02.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec webapp">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">Get Expert Web App Consulting</h3>
                  <p className="big">
                    Being trusted and experienced software experts, we at BTG
                    Technologies aim to modernize and optimize your business by
                    developing functional web apps. Whether you need to
                    transform your existing technological infrastructure or want
                    a new startup from scratch, we are here to help. You can
                    rely on our multi-disciplinary expert team for consultation
                    and discussion.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">
                    Quickly Amplify Your Business with a Web App
                  </h3>
                  <p className="big">
                    In today's business world, a web app can be a powerful tool
                    for quickly amplifying your business. By creating a web app
                    specifically for your business, you can reach a wider
                    audience, improve conversions and revenue, increase sales
                    and promote your products or services more effectively.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">
                    Custom and Effective Solutions with Wisdom and Insight
                  </h3>
                  <p className="big">
                    We have decades of experience creating successful web apps
                    for our esteemed clients and providing robust solutions to
                    their business needs. From developing .NET platforms for
                    management firms to WordPress, Angular, and other platforms,
                    we are ready to help you with any project or idea that may
                    come your way.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
