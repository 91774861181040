import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/game/unity3d/unity3d-design01.png";

// import Android1 from "../../images/indeximg/game/unity3d/unity3d-design01.png";
import Android2 from "../../../../images/indeximg/game/unity3d/unity3d-design04.png";

import Android3 from "../../../../images/indeximg/game/unity3d/unity3d-design05.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec unity">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    Captivate your Audience with Mesmerizing Unity 3d Games
                  </h3>
                  <p>
                    Unity 3d offers a variety of tools and features that make it
                    ideal for developing games with stunning and immersive
                    visuals. You can create games with its powerful game play,
                    graphics engine, physical rendering, cinematic effects, and
                    real-time lighting system. So if you're ready to take your
                    game development to the next level, be sure to check out
                    Unity 3D.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Create A One of a Kind Game with BTG Experts</h3>
                  <p>
                    Looking for innovative and unique Unity 3d gameplay that can
                    stand you out in the market? Look no further. At BTG
                    Technologies, we have a team of professional Unity
                    developers that can create a highly addictive and quality
                    game that your users will love to play. Whether you have an
                    idea for a game you want to bring to life or need help
                    brainstorming ideas, our team is here to help.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Reduce Development Time And Costs</h3>
                  <p>
                    Save your time and money by collaborating with one of the
                    most reliable game development companies. We can help you
                    cut development costs by streamlining the process from
                    consultation to asset creation and level designing. With our
                    Unity 3D game engine, you can easily bring your vision to
                    life without breaking the bank.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
