import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img hyper">
        <div className="left-img hyper">
          <div className="short-line"></div>
          <h2 className="heading-1">Hyperledger Development Services</h2>
          <p>
            Hyperledger is a decentralized distributed ledger that is helping
            companies to take leverage of blockchain power to streamline
            business operations. With our expertise, we can help you create a
            permissioned blockchain network that is secure, scalable, and
            flexible.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
