import React from "react";
// import Navbares from "./Component/Nev";
// import Android1 from "../../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../images/consulting/DevOps/devopsconsulting-Deign-02.png";
import First from "../../../images/consulting/DevOps/1.png";
import First1 from "../../../images/consulting/DevOps/2.png";

import fintech_design05 from "../../../images/consulting/DevOps/devopsconsulting-Deign-04.png";
import First4 from "../../../images/consulting/DevOps/3.png";
import First5 from "../../../images/consulting/DevOps/4.png";
import fintech_design04 from "../../../images/consulting/DevOps/devopsconsulting-Deign-01.png";
import First7 from "../../../images/consulting/DevOps/5.png";
import First8 from "../../../images/consulting/DevOps/6.png";

function Fintechapp() {
  return (
    <>
      <section className="Fintech-sec1 devops">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Improve Business Efficiency with DevOps</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    DevOps is a methodology that can help you streamline your
                    development and operational processes, making them more
                    efficient and effective.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Ensure your organization's DevOps transformation
                          success by working with our experienced team, who can
                          help optimize your DevOps practices and reach your
                          desired outcome.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First1}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Our experienced consultants can help organizations to
                          adopt automated practices that ensure smooth and
                          uninterrupted workflows.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>10X More Productivity with us</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    We can help your business to take advantage of the newest
                    and most efficient techniques for managing your development
                    process.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First4}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Using a combination of automation, monitoring, and
                          collaboration tools, our team can help you streamline
                          your process and achieve a tenfold increase in
                          productivity.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First5}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Whether you seek to improve your internal operations
                          or release new products faster, we can provide the
                          solutions you need.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design05}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>
                  Get the best DevOps Consulting Service that you deserve!
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design04}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Our consultants at BTG Technologies are experienced in
                    working with various DevOps tools and strategies, so they
                    can provide you with the assistance you need to get the most
                    out of your technology initiatives.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First7}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          You can reduce costs and time spent managing
                          technology implementations by consulting with us while
                          ensuring that your applications run smoothly.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First8}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Our approach is tailored to meet the specific needs of
                          each organization, so we can ensure that our services
                          are helpful and effective.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Fintechapp;
