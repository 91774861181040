import React from "react";
import Navbares from "../../../Component/Nev";
import "../../../Blog.css";
import "../../../App.css";

import Leverage from "../../../Component/Services/Artificial-Intelligence-Development/Chatbot/Loverage";
import Digitalsec from "../../../Component/Services/Artificial-Intelligence-Development/Chatbot/Digitalsec";
import Expand from "../../../Component/Services/Artificial-Intelligence-Development/Chatbot/Expand";
import Getintech from "../../../Component/LetsBusiness";
import Footer from "../../../Component/Footer";
import Document from "../../../Document";
import Header01 from "../../../Component/Services/Artificial-Intelligence-Development/Chatbot/Header01";
// import Leverage from "../../../Component/Services/Android/Loverage"
function Chatbot() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <Header01 />
        <Leverage />
        <Digitalsec />
        <Expand />
        <Getintech info={"Chatbot"} />
        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Chatbot"} />

    </>
  );
}

export default Chatbot;
