import React from "react";
import Automation from "../../../../images/indeximg/web-dev/asp/Improvesecurity-feautres.png";
import Secure from "../../../../images/indeximg/web-dev/asp/thirdparty-componentslibraries.png";
import cost from "../../../../images/indeximg/web-dev/asp/securityand-performance.png";
import greate from "../../../../images/indeximg/web-dev/asp/userfriendly-webapplication.png";
import icon from "../../../../images/solutions/Icon1.png";

function QuickSolution() {
  return (
    <>
      <section className="QuickSolution-sec">
        <div className="Quick-maas">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="Quick-head">
                  <h3>
                    Majestic Web Solutions Beyond your{" "}
                    <strong> Expectations</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quick-fir">
          <div className="container">
            <div className="row quick-sec">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={Automation}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>
                      Improved security features over traditional web
                      development frameworks
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={Secure}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>
                      Access to a wide range of high-quality third-party
                      components and libraries
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={cost}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>
                      Better security and performance than competing frameworks
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={greate}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>
                      Dynamic and user-friendly web applications for multiple
                      industries
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-5 pb-5">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>
                      ASP.NET App & CMS <br></br> Development
                    </h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      We provide SDLC support for enterprises and small
                      businesses, from product development to integration,
                      customization, and deployment.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main sec1">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>ASP.NET Core Development</h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      If you are looking to develop robust web apps using the
                      latest technologies, then our ASP.NET Core-centric
                      services should be on your radar!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main sec2">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>
                      ASP.NET App Migration to <br></br> Cloud
                    </h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      We can help you migrate your applications to any cloud
                      platform, including AWS, Microsoft Azure, Google Cloud
                      Platform, and IBM Bluemix.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default QuickSolution;
