import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
import Android1 from "../../../../images/Services/hybridapp/hybrid-Deign-02.png";
import Android2 from "../../../../images/Services/hybridapp/hybrid-Deign-03.png";
import First from "../../../../images/Services/hybridapp/fast-deployment.png";
import First1 from "../../../../images/Services/hybridapp/customer-loyalty.png";
import First2 from "../../../../images/Services/hybridapp/highly-scaleable.png";
import First3 from "../../../../images/Services/hybridapp/cost-effective.png";

import Android3 from "../../../../images/Services/hybridapp/hybrid-Deign-01.png";
import First4 from "../../../../images/Services/hybridapp/higher-productivity.png";
import First5 from "../../../../images/Services/hybridapp/global-audience.png";
import First6 from "../../../../images/Services/hybridapp/enhance-security.png";
import First7 from "../../../../images/Services/hybridapp/great-efficiecny.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec ms">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Why Invest in Hybrid Apps</h3>
                  <p>
                    Hybrid apps offer a unique opportunity for businesses to
                    develop more engaging and customer-centric experiences. A
                    blend of both powerful mediums – mobile and web - helps
                    companies create high-quality hybrid apps that are
                    interactive and can easily target a wider audience.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Fast Deployment</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First1}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Customer Loyalty</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First2}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Highly Scalable</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First3}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Cost-Effective</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    Strengthening your Marketing Infrastructure with Hybrid Apps
                  </h3>
                  <p>
                    Create a seamless experience for your customers across all
                    channels, including website, social media, and
                    brick-and-mortar stores by leveraging Hybrid apps. You can
                    also use them to collect valuable customer data and insights
                    that can be used to inform future marketing efforts.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First4}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Higher Productivity</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First5}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Global Audience</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First6}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Enhanced Security</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First7}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Great Efficiency</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    We are the Best Cross-Platform App Developers in Town!
                  </h3>
                  <p>
                    BTG Technologies is a renowned and reliable IT agency that
                    creates high-end platform-agnostic apps for start-ups and
                    growing enterprises. Our dependable team knows the ins and
                    outs of app development and can customize the apps according
                    to your needs. Our track record speaks for itself – we have
                    developed some of the most popular and successful apps on
                    both Android and iOS.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
