import React from "react";
// import Navbares from "./Component/Nev";
// import Android1 from "../../../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/indeximg/web-dev/wordpress/wordpress-design01.png";
import First from "../../../../images/indeximg/web-dev/wordpress/1.png";
import First1 from "../../../../images/indeximg/web-dev/wordpress/2.png";

import fintech_design05 from "../../../../images/indeximg/web-dev/wordpress/wordpress-design02.png";
import First4 from "../../../../images/indeximg/web-dev/wordpress/3.png";
import First5 from "../../../../images/indeximg/web-dev/wordpress/4.png";
import fintech_design04 from "../../../../images/indeximg/web-dev/wordpress/wordpress-design03.png";
import First7 from "../../../../images/indeximg/web-dev/wordpress/5.png";
import First8 from "../../../../images/indeximg/web-dev/wordpress/6.png";

function Fintechapp() {
  return (
    <>
      <section className="Fintech-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>
                  Why should you consider WordPress Website for your Business?
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    WordPress provides many features, including content
                    management, blog publishing, e-commerce, and social media
                    integration.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          This CMS platform has prominent features which many
                          other platforms lack, such as custom modules, plugins,
                          and themes that make your website intuitive and
                          appealing.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First1}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          It offers numerous benefits for businesses, including
                          ease of use, enhanced security, high flexibility, and
                          cost-effectiveness.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Globally Adaptive & Dynamic Website Platform</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    WordPress is a popular open-source content management system
                    (CMS) used by millions of website owners, developers, and
                    bloggers globally.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First4}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          WordPress provides its users with a vast and highly
                          scalable platform that ensures the smooth growth of
                          business and eliminates the need for new website
                          development.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First5}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          WordPress is also known for its incredible security
                          and safety policies. It is well-versed with regular
                          updates and security controls to ensure your website
                          is always protected against malicious attacks.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design05}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>
                  We Serve Start-ups & Growing enterprises in Multiple
                  Industries!
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design04}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    At BTG Technologies, we have experience serving start-ups
                    and growing enterprises in multiple industries. Our
                    experienced team of developers can provide you with the
                    resources and support you need to grow your business.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First7}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          We offer various services to help your company
                          succeed, including marketing and branding, web
                          development and design, data management, and more.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First8}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Our team is experienced in creating amazing websites
                          that are user-friendly and look great on any device.
                          You can be confident that your project is in good
                          hands by choosing us.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Fintechapp;
