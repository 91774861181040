import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/blockchain/nft/NFTMarketplace-design01.png";
import Android2 from "../../../../images/indeximg/blockchain/nft/NFTMarketplace-design02.png";

import Android3 from "../../../../images/indeximg/blockchain/nft/NFTMarketplace-design03.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec nft">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big" style={{ Color: "#000 !important" }}>
                    Encrypted and Secure Platform for Digital Assets
                  </h3>
                  <p className="big" style={{ Color: "#000 !important" }}>
                    Non-fungible tokens, commonly known as NFTs, are unique
                    digital assets. The NFT marketplace provides a high-end,
                    secure platform for selling and purchasing these NFTs. With
                    the NFT marketplace, each transaction is verified and
                    recorded on a public ledger, ensuring that all parties can
                    trust the integrity of the marketplace.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">
                    Bringing Value to Your Businesses through NFT Metaverse
                    Marketplace
                  </h3>
                  <p className="big">
                    We enable businesses to sell digital assets encoded with
                    unique identities that cannot be altered or manipulated. NFT
                    marketplaces are hosted on decentralized networks, offering
                    increased security and resilience against attacks. It is
                    especially important for businesses dealing with sensitive
                    data or high-value assets.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big" style={{ Color: "#000 !important" }}>
                    Choose us for your Next Project!
                  </h3>
                  <p className="big" style={{ Color: "#000 !important" }}>
                    AT BTG Technologies, we are empowering businesses by
                    developing a secure NFT marketplace where they can easily
                    and fearlessly trade digital assets. We provide end-to-end
                    development services, from initial planning and design to
                    launching and managing your marketplace. Our team has
                    extensive experience making NFT marketplaces a secure and
                    successful tool for business growth. Contact us today!
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
