import React from "react";
import Automation from "../../../../images/indeximg/Uiux/uianimation/userfriendly-webapplication.png";
import Secure from "../../../../images/indeximg/Uiux/uianimation/stunning-animation.png";
import cost from "../../../../images/indeximg/Uiux/uianimation/online-presence.png";
import greate from "../../../../images/indeximg/Uiux/uianimation/captivate-design.png";
import icon from "../../../../images/solutions/Icon1.png";

function QuickSolution() {
  return (
    <>
      <section className="QuickSolution-sec">
        <div className="Quick-maas">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="Quick-head">
                  <h3>
                    <strong> Improving</strong> your site’s usability and online
                    presence with visually appealing websites & apps!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quick-fir">
          <div className="container">
            <div className="row quick-sec">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={Automation}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>User-friendly Interfaces</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={Secure}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Stunning Animations</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={cost}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Strong Online Presence</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={greate}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Captivating Designs</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-5 pb-5">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>
                      Custom Animation & <br></br> Graphics
                    </h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      Our animation and graphic design services can help add
                      life to your website or app and make it more
                      user-friendly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main sec1">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>Logo Design</h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      We have a team of expert designers who can craft a
                      beautiful customized logo that reflects your business.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main sec2">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>2D Animation</h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      With our visually attractive 2D animations, we are
                      enabling businesses to interact with their audience
                      uniquely.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default QuickSolution;
