import React from "react";
import { Helmet } from "react-helmet";

const Document = ({ title }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {` ${title} | BRIDGE THE GAP (BTG) TECHNOLOGIES `}</title>
      </Helmet>
    </>
  );
};

export default Document;
