import React from "react";
import First from "../../images/aboutimg/mobile app development.svg";
import First1 from "../../images/aboutimg/website development.svg";
import First2 from "../../images/aboutimg/UIUX-Development.svg";
import First3 from "../../images/aboutimg/Ecommerce Development.svg";
import First4 from "../../images/aboutimg/Game Development.svg";
import First5 from "../..//images/menu-icon/ai/ai.svg"
import First6 from "../../images/aboutimg/Blockchain-development.svg";

// import Navbares from "./Component/Nev";
function Wearereally() {
  return (
    <>
      <section className="industry_sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 pr-50  mt-auto mb-auto ">
              <div className="industryhigh"></div>
              <div className="industry-head text-center">
                <h2>Services We Offer</h2>
                <p>
                  Here, we make almost every genre of applications. You name it
                  and we build it.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={First} class="card-img-top " alt="..." />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">Mobile App Development</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={First1} class="card-img-top " alt="..." />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">Web Development</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={First2} class="card-img-top " alt="..." />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">UI/UX Designing</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={First3} class="card-img-top " alt="..." />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">E-Commerce Development </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={First4} class="card-img-top " alt="..." />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">Game Development</h5>
                    {/* <p class="card-text mb-5 ms-1">
                      See more bootstrap code snippets on my website
                    </p>

                    <a
                      href="https://freesnippetcode.blogspot.com/"
                      target="_blank"
                      class="btn btn-primary mb-1 mt-1 "
                    >
                      Go now
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={First5} class="card-img-top " alt="..." />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">AI Development</h5>
                    {/* <p class="card-text mb-5 ms-1">
                      See more bootstrap code snippets on my website
                    </p>

                    <a
                      href="https://freesnippetcode.blogspot.com/"
                      target="_blank"
                      class="btn btn-primary mb-1 mt-1 "
                    >
                      Go now
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pr-50  mt-auto mb-auto mx-auto">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={First6} class="card-img-top " alt="..." />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">Blockchain Development</h5>
                    {/* <p class="card-text mb-5 ms-1">
                      See more bootstrap code snippets on my website
                    </p>

                    <a
                      href="https://freesnippetcode.blogspot.com/"
                      target="_blank"
                      class="btn btn-primary mb-1 mt-1 "
                    >
                      Go now
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Wearereally;
