import React from "react";
import Navbares from "../../../Component/Nev";
import "../../../Blog.css";
import "../../../App.css";

import Footer from "../../../Component/Footer";
import MainBanner from "../../../Component/Services/Digital-Marketing/Search-Engine-Optimization/Main-banner";
import QuickSolution from "../../../Component/Services/Digital-Marketing/Search-Engine-Optimization/Quicksolution";
import Fintechapp from "../../../Component/Services/Digital-Marketing/Search-Engine-Optimization/Fintech-App";
// import Choose from "../../../Component/Services/Digital-Marketing/Search-Engine-Optimization/Choose";
// import Getintech from "../../../Component/Services/Digital-Marketing/Search-Engine-Optimization/GetIntech";
import Getintech from "../../../Component/LetsBusiness";
import Document from "../../../Document";

function SearchEngineOptimization() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <MainBanner />
        <QuickSolution />
        <Fintechapp />
        {/* <Choose /> */}
        <Getintech info={"Search Engine Optimization"} />

        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Search Engine Optimization"} />

    </>
  );
}

export default SearchEngineOptimization;
