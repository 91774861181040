import React from "react";
import Navbares from "../../../Component/Nev";
import "../../../Blog.css";
import "../../../App.css";

import Leverage from "../../../Component/Services/Mobile-App-Development/Swift/Loverage";
import Digitalsec from "../../../Component/Services/Mobile-App-Development/Swift/Digitalsec";
import Expand from "../../../Component/Services/Mobile-App-Development/Swift/Expand";
import Header01 from "../../../Component/Services/Mobile-App-Development/Swift/Header01";
import Getintech from "../../../Component/LetsBusiness";
import Footer from "../../../Component/Footer";
import Document from "../../../Document";
function Swift() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <Header01 />
        <Leverage />
        <Digitalsec />
        <Expand />
        <Getintech info={"Swift"} />
        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Swift"} />
    </>
  );
}

export default Swift;
