import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Let us Handle your Project</h3>
                  <p>
                    BTG Technologies is one of the top-class and leading
                    software companies serving around the globe. We use
                    top-notch technologies and tools to build cross-platform for
                    businesses that use the power of native code to deliver the
                    best performance.
                  </p>
                  <ul>
                    <li>Hybrid App Design</li>
                    <li>Hybrid App UI/UX Design</li>
                    <li>App Prototype Development</li>
                    <li>Hybrid App Migration</li>
                  </ul>
                  <ul>
                    <li>Flutter App Development</li>
                    <li>HTML5 App Development</li>
                    <li>Hybrid App Integration</li>
                  </ul>
                  <ul>
                    <li>React Native App Development</li>
                    <li>Progressive App Development</li>
                    <li>Titanium App Development</li>
                    <li>Maintenance & Support</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
