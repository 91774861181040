import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>What we can do for you?</h3>
                  <p>
                    From smart contracts to dApp development and integration, we
                    are well-versed with the latest technologies to provide a
                    complete suite of Ethereum services. Contact us today to
                    learn more about how we can help you to get the best out of
                    your investment.
                  </p>
                  <ul>
                    <li>Ethereum Smart Contract Development</li>
                    <li>Decentralized App Development Services</li>
                    <li>Web3 Wallet Development</li>
                  </ul>
                  <ul>
                    <li>dApp Development and Maintenance</li>
                    <li>Tailored Ethereum Wallet App</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
