import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img ethere">
        <div className="left-img ethere">
          <div className="short-line"></div>
          <h2 className="heading-1">Ethereum Development Services</h2>
          <p>
            Secure your transactions, create smart contracts, streamline your
            business operations, and develop decentralized applications with
            Ethereum, a blockchain-based platform. No matter what your project
            entails, we're confident we can provide you with the quality
            services needed to get it done right.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
