import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/Services/Androidapp-Design01.png";
import "./Service.css";
// import Android1 from "../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/Services/Androidapp-Design02.png";
import First from "../../../../images/Services/increase-efficiency.png";
import First1 from "../../../../images/Services/customer-relation.png";
import First2 from "../../../../images/Services/Elevated-sales.png";
import First3 from "../../../../images/Services/customer-engagment.png";

import Android3 from "../../../../images/Services/Androidapp-Design03.png";
import First4 from "../../../../images/Services/userfriendly-app.png";
import First5 from "../../../../images/Services/visually-appealing.png";
import First6 from "../../../../images/Services/esay-navigation.png";
import First7 from "../../../../images/Services/customize-app.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Digital Solution for Everyone</h3>
                  <p>
                    From small businesses that need a simple way to manage their
                    finances, to large enterprises that need an inclusive ERP
                    solution, there is an app for everyone. From developing
                    custom apps to optimizing existing apps, we have a solution
                    that will fit your needs. Our cyber-secure and crash-free
                    android apps will enable your brand to expand sales, reach
                    potential customers and reduce acquisition costs.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Breathe Life into Your Marketing Efforts</h3>
                  <p>
                    Android apps can be extremely helpful for businesses looking
                    to boost their online presence and reach more customers. Our
                    well-designed and easy-to-navigate apps can keep your
                    customers engaged and connected, as well as provide new
                    opportunities for marketing and sales.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Increased Efficiency</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First1}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Improved Customer Relations</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First2}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Elevated Sales & ROI</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First3}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Higher Customer Engagement</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Our Android App Key features for businesses</h3>
                  <p>
                    Our technologically crafted apps can aid companies and
                    individuals to track and keep a record of their expenses,
                    inventory, and sales. With the right team behind you,
                    creating an amazing Android app is possible! Our Android app
                    has some great features that can help you be more productive
                    and organized.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First4}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>User-Friendly</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First5}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Easy-To-Navigate</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First6}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Easy-To-Navigate</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First7}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Customizable</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
