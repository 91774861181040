import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Ai-chatbot">
        <div className="left-img">
          <div className="short-line"></div>
          <h2 className="heading-1">Chatbot Development</h2>
          <p>
            Bridge The Gap BTG Technologies specializes in cutting-edge Chatbot Development services. We create intelligent and personalized chatbots that bridge the gap between your business and customers, enhancing engagement and efficiency. Our solutions are designed to streamline customer interactions, boost productivity, and provide seamless, 24/7 support.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
