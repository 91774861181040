import React from "react";
import Navbares from "../../Component/Nev";
import "../../Blog.css";
import "../../App.css";

import MainBanner from "../../Component/Solutions/HealthCareApp/Main-banner";
import QuickSolution from "../../Component/Solutions/HealthCareApp/Quicksolution";
import Fintechapp from "../../Component/Solutions/HealthCareApp/Fintech-App";
// import Choose from "../../Component/Solutions/HealthCareApp/Choose";
import Getintech from "../../Component/LetsBusiness";
import Footer from "../../Component/Footer";
import Document from "../../Document";

function HealthCareApp() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <MainBanner />
        <QuickSolution />
        <Fintechapp />
        {/* <Choose /> */}
        <Getintech info={"Health Care App"} />

        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Health Care App"} />

    </>
  );
}

export default HealthCareApp;
