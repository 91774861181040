import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our NFT Marketplace Expertise</h3>
                  <p>
                    From platform development to deployment and user
                    acquisition, we can provide various NFT Marketplace
                    services. We have a team of experienced developers who can
                    create a custom solution for your needs.
                  </p>
                  <ul>
                    <li>
                      NFT Marketplace Design and<br></br> Development
                    </li>
                    <li>
                      NFT Smart Contract Development <br></br> and Audit
                    </li>
                    <li>
                      NFT Marketplace Support and <br></br> Maintenance
                    </li>
                    <li>
                      NFT <br></br> Development
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
