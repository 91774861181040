import React from "react";
// import Navbares from "./Component/Nev";
// import Android1 from "../../../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/Services/Xamarin/xamarine-Deign-01.png";
import First from "../../../../images/Services/Xamarin/tailored-mobileapp.png";
import First1 from "../../../../images/Services/Xamarin/SDK-framework.png";

import fintech_design05 from "../../../../images/Services/Xamarin/xamarine-Deign-02.png";
import First4 from "../../../../images/Services/Xamarin/crossplatform-framework.png";
import First5 from "../../../../images/Services/Xamarin/API-integration.png";
import fintech_design04 from "../../../../images/Services/Xamarin/xamarine-Deign-03.png";
import First7 from "../../../../images/Services/Xamarin/flexible-tools.png";
import First8 from "../../../../images/Services/Xamarin/technical-support.png";

function Fintechapp() {
  return (
    <>
      <section className="Fintech-sec ssdsa">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Get Your Digitalize your business today!</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Xamarin can reuse the .Net libraries' code to increase
                    productivity and profitability by developing hybrid apps. We
                    aimed to empower businesses by delivering our high-end
                    services to create robust, power-packed cross-platform apps.
                  </p>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Our team follows best practices when it comes to
                          Xamarin mobile app development services, ensuring that
                          the final product is both secure and reliable. We
                          understand that no two businesses are the same. That's
                          why we work with our clients to create a mobile app
                          that is tailored to their exact specifications.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First1}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          By using SDKs, Xamarin libraries, frameworks, and IDEs
                          we build apps that meet top-notch quality standards.
                          You can count on us if you need to develop an app from
                          scratch or just want an Upgradation of your existing
                          app.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Build Feature-rich & Functional Apps with Us</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Xamarin gives businesses the leverage to target potential
                    audiences by utilizing the same APIs, data, and language for
                    both iOS and Android platforms. At BTG Technologies, we
                    understand that businesses need to stay ahead of the
                    competition that’s why we offer Xamarin mobile app
                    development services for them.
                  </p>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First4}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Xamarin is a cross-platform framework used to create
                          apps that run on multiple operating systems by using
                          C# language. It is an ideal choice for businesses
                          looking to create a native-feel app while also cutting
                          down on development costs.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First5}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          It also offers a wide range of features and tools,
                          such as real-time debugging, integration with Visual
                          Studio, and support for multiple languages. Our
                          methodologies include the latest implementation
                          practices including .Net6, Xamarin Forums, and UI.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design05}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Accelerate your Business with Xamarin App Technology</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design04}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Xamarin’s set of tools, libraries, third-party integration,
                    and shared coding makes it easier to create cross-platform
                    apps, making them more cost-effective for businesses. This
                    quick and efficient development framework can slash the
                    amount they spend on software development, which can give
                    them more money to invest in other areas of their business.
                  </p>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First7}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          With flexible tools and community tools, businesses
                          can develop quality apps for mobile devices using
                          Xamarin. The community toolkit comprises IDE, a C#
                          compiler, and several plugins for animations and
                          effects. This community toolkit is free to use and
                          download from the Xamarin website.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First8}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Xamarin also provides support for native extensions
                          which helps businesses to add features that give an
                          intuitive and native user experience.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Fintechapp;
