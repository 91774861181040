import React from "react";
import Navbares from "../../../Component/Nev";
import "../../../Blog.css";
import "../../../App.css";
import Footer from "../../../Component/Footer";
import MainBanner from "../../../Component/Services/Web-Development/Php/Main-banner";
import QuickSolution from "../../../Component/Services/Web-Development/Php/Quicksolution";
import Fintechapp from "../../../Component/Services/Web-Development/Php/Fintech-App";
// import Choose from "../../../Component/Services/Web-Development/Php/Choose";
// import Getintech from "../../../Component/Services/Web-Development/Php/GetIntech";
import Getintech from "../../../Component/LetsBusiness";
import Document from "../../../Document";
function Php() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <MainBanner />
        <QuickSolution />
        <Fintechapp />
        {/* <Choose /> */}
        <Getintech info={"Php"} />

        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Php"} />

    </>
  );
}

export default Php;
