import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
import Android1 from "../../../../images/Services/iphone/iphone-Deign-04.png";
import First from "../../../../images/Services/iphone/strategy-formulation.png";
import First1 from "../../../../images/Services/iphone/prototype.png";
import First2 from "../../../../images/Services/iphone/app-development.png";
import First3 from "../../../../images/Services/iphone/app-testing.png";
import Android2 from "../../../../images/Services/iphone/iphone-Deign-03.png";
import First4 from "../../../../images/Services/iphone/iphone.png";
import First5 from "../../../../images/Services/iphone/ipad.png";
import First6 from "../../../../images/Services/iphone/iwatch.png";
import First7 from "../../../../images/Services/iphone/apple-tv.png";

import Android3 from "../../../../images/Services/iphone/iphone-Deign-02.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>We provide Smooth Sailing!</h3>
                  <p>
                    We initiate the developing process by gathering data
                    relevant to business so you can target potential audiences
                    more effectively to generate more revenue. Our
                    intellectually crafted apps can streamline workflow by
                    integrating into business infrastructure.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Strategy Formulation</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First1}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Prototype</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First2}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> App Development</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First3}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> App Testing</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>iOS Apps for Any Device</h3>
                  <p>
                    Undoubtedly iOS operating system has become the top choice
                    of businesses and individual users around the world. Whether
                    you want an app compatible with iPhones or all iOS operating
                    devices, you can rely on us. We at BTG Technologies, have a
                    team of expert developers that can build any iOS app for
                    different iOS devices, no matter how complex it is.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First4}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> IPhone</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First5}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> IPad</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First6}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> IWatch</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First7}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Apple TV</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Melt Away Phone Competition with iOS Apps</h3>
                  <p>
                    With BTG Technologies, you can create anything you can
                    imagine - from simple utilities to complex games. Our team
                    of qualified iOS developers can help you create apps that
                    not only look great but are also embedded with the latest
                    technologies and features that will make you stand out in
                    the marketplace.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
