import React from "react";
import "./Service.css";
// import Navbares from "./Component/Nev";
import First from "../../../../images/Services/iphone/ios-app.png";
import First1 from "../../../../images/Services/iphone/userfriendly-app.png";
import First2 from "../../../../images/Services/iphone/fast-performance.png";
import First3 from "../../../../images/Services/iphone/better-aesthetics.png";
import First4 from "../../../../images/Services/iphone/seo-friendly.png";
import First5 from "../../../../images/Services/iphone/responsive-design.png";
import First6 from "../../../../images/Services/iphone/customize-app-solutions.png";
import First7 from "../../../../images/Services/iphone/Secure-Ecosystem.png";
import First8 from "../../../../images/Services/iphone/technical-support.png";

function Expand() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Custom iOS App Development Feature List</h3>
                  <p>
                    Ensuring a perfect scene for reliably rising business
                    improvement with our top-rated iOS app development service.
                    Our quality speaks for itself;
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>iOS Apps from Scratch</h3>
                  <p>
                    Starting from an industrial and competitive research to
                    having a rough idea for the iPhone app development, and
                    carrying it to crafting the final product, our team ensures
                    the smoothness of the process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First1}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>User-Friendly Experience</h3>
                  <p>
                    User experience is something we never compromise on. With
                    your project apart from attaining your satisfaction, our
                    objective is bringing the absolute best to your consumer.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First2}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Fast Performance</h3>
                  <p>
                    iOS mobile applications are faster in performance as
                    compared to other software. Its fast performance can aid
                    your users with good usability and hence better UX.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First3}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Better Aesthetics</h3>
                  <p>
                    We are known for our creative design and styling in our iOS
                    applications for mobile. What you get in return is your
                    ideas gathered into a visible online platform with appealing
                    aesthetics.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First4}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>SEO Friendly</h3>
                  <p>
                    Your iOS app is your product but it won’t be of any help
                    unless it gets recognition. Our team ensures your iPhone or
                    iPad apps are search engine friendly so they get the right
                    exposure.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First5}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Responsive Design</h3>
                  <p>
                    Cover all devices your user could potentially run your
                    application on by having a responsive designed iOS
                    application. Having a responsive design would grow your
                    application’s
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First6}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Customized App Solutions</h3>
                  <p>
                    Understanding the specific and special requirements of your
                    business with respect to your industry we offer tailored
                    customized iOS application solutions to help your business
                    stand out.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First7}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Securing the Ecosystem</h3>
                  <p>
                    Providing a safe and secure iPhone & iPad application is our
                    responsibility. We work towards shaping your application in
                    a way that it’s kept safe from any threats.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First8}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Technical Support</h3>
                  <p>
                    Applications created are tested through various means and
                    variables by our QA testing team to ensure the application
                    is technically and functionally good.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Expand;
