import React from "react";
import Navbares from "../Component/Nev";

import "../Blog.css";
import "../App.css";

import Projectlink from "../Component/Projectlink";

import MainBanner from "../Component/About/Mainbanner";

import Howwework from "../Component/About/HowWeWork";
import Pricing from "../Component/About/boket";
import Whywork from "../Component/About/WhyWork";

import Ourwork from "../Component/About/Ourwork";
import Wearereally from "../Component/About/Wearereally";
import Wework from "../Component/About/Wework";
import Getintech from "../Component/LetsBusiness";

import Footer from "../Component/Footer";
import Document from "../Document";

// import MainNav from "../Component/Header";
// import HelpForDev from "../Component/Mainheader";
function App() {
  return (
    <>
      <Navbares />
      {/* <HelpForDev /> */}
      {/* <LogicalNot /> */}
      <div className="pagesss">
        {/* <Service /> */}
        {/* <Header01 /> */}
        {/* <ResponsiveExample /> */}
        <MainBanner />
        <Pricing />
        {/* <Btgbouquet /> */}
        <Howwework />
        <Whywork />
        <Ourwork />
        <Wearereally />
        <Wework />
        {/* <Process /> */}
        <Projectlink />
        <Getintech info={"About Us"} />
        {/* <Lettalk /> */}
      </div>
      <Footer />
      {/* <Swiper /> */}
      <Document title={"About Us"} />
    </>
  );
}

export default App;
