import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
import First from "../../../../images/Services/hybridapp/competitive-price.png";
import First1 from "../../../../images/Services/hybridapp/on-timeframe.png";
import First2 from "../../../../images/Services/hybridapp/customer-satisfication.png";
import First3 from "../../../../images/Services/hybridapp/data-collection.png";
import First4 from "../../../../images/Services/hybridapp/prototype-proposal.png";
import First5 from "../../../../images/Services/hybridapp/app-designdevelopment.png";

function Expand() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Why Choose BTG Technologies</h3>
                  <p>
                    Our unmatched proficiency in crafting prompts supercharges AI models, ensuring top-notch performance. With a track record of excellence, we empower your AI projects with precision, context-awareness, and reliability. Elevate your AI initiatives with our expertise, and experience the BTG Technologies advantage today.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First}
                    className="img-fluid "
                    alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Competitive Pricing</h3>
                  <p>
                    With our expertise and experience, we develop high-quality
                    hybrid apps tailored to your budget.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First1}
                    className="img-fluid "
                    alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>On-time Delivery</h3>
                  <p>
                    We'll work with you closely to ensure that your app meets
                    all your requirements and is delivered on time.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First2}
                    className="img-fluid "
                    alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Customer Satisfaction</h3>
                  <p>
                    We're confident that we can exceed your expectations and
                    deliver an exceptional product that you'll be happy with.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First3}
                    className="img-fluid "
                    alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Data Collection & Analysis</h3>
                  <p>
                    We undergo thorough data collection and evaluation based on
                    feasibility and turnaround rates to create the right
                    solution for you.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First4}
                    className="img-fluid "
                    alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Prototype Proposal</h3>
                  <p>
                    We prepare a prototype to test and ensure that the requested
                    features and ideas validate the client’s expectations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First5}
                    className="img-fluid "
                    alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>App Designing & Development</h3>
                  <p>
                    We understand the sensitivity and vulnerability of your data
                    thus we have a implemented strict privacy policy for our
                    clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Expand;
