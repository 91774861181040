import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>What We Can Do for You!</h3>
                  <p>
                    Looking to develop an iOS app but don't know where to start?
                    Look no further. Beginning with ideation, a sleek interface,
                    and backend coding, to testing and marketing your app, our
                    team of developers is diverse enough to create to fit your
                    needs..
                  </p>
                  <ul>
                    <li>iOS App Development Consultation & Planning</li>
                    <li>Custom iPhone App Development</li>
                    <li>iOS Game App Development</li>
                  </ul>
                  <ul>
                    <li>iOS UI/UX Design</li>
                    <li>E-commerce & Enterprise App Development</li>
                  </ul>
                  <ul>
                    <li>iOS App Optimization & Modernization</li>
                    <li>Social Media App Development</li>
                    <li>iOS App Maintenance & Support</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
