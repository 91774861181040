import React from "react";
// import Navbares from "./Component/Nev";
// import Android1 from "../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/indeximg/digital-market/content/Contentmarketing-Design3.png";
import First from "../../../../images/indeximg/digital-market/content/1.png";
import First1 from "../../../../images/indeximg/digital-market/content/2.png";

import fintech_design05 from "../../../../images/indeximg/digital-market/content/Contentmarketing-Design4.png";
import First4 from "../../../../images/indeximg/digital-market/content/3.png";
import First5 from "../../../../images/indeximg/digital-market/content/4.png";
import fintech_design04 from "../../../../images/indeximg/digital-market/content/Contentmarketing-Design1.png";
import First7 from "../../../../images/indeximg/digital-market/content/5.png";
import First8 from "../../../../images/indeximg/digital-market/content/6.png";

function Fintechapp() {
  return (
    <>
      <section className="Fintech-sec content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Grow Your Business with Content Marketing</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Content marketing creates valuable, engaging, and persuasive
                    content to connect with customers. This strategic technique
                    can help in generating more impressions, leads, and revenue.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Well-organized quality content educates your target
                          audience, builds your brand identity, and can even
                          help you win business.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First1}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Creating high-quality, valuable content relevant to
                          your audience is key to successful content marketing.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Need Content Marketing? Let Us Handle It</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    If you need content marketing but don't have the time or
                    resources to do it yourself, let us handle it. We are
                    experienced in creating content that drives sales and
                    results.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First4}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          From developing targeted buyer personas to creating
                          high-quality blog posts and infographics, we can help
                          you achieve your content marketing goals.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First5}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Our content specialists can create bespoke solutions
                          for businesses across various platforms, including
                          blogs, articles, websites, whitepapers, and more.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design05}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Generate Sales with Analytics-Driven Content Marketing</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design04}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Generate more sales with us by leveraging the power of
                    content to improve your marketing strategies and create
                    persuasive content that customers will love to read.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First7}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          We use data and analytics to understand your target
                          audience and generate content encouraging them to
                          purchase from your store.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First8}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          With our tracking key metric techniques, we deeply
                          analyze traffic, conversion rates, and customer
                          analytics to keep your sales boost.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Fintechapp;
