import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../images/solutions/saas/saasdevelopment_design03.png";
import Android2 from "../../../images/solutions/saas/saasdevelopment_design05.png";

import Android3 from "../../../images/solutions/saas/saasdevelopment_design02.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec saas">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Deploy your Ideas - Transfer Your Hassle</h3>
                  <p>
                    We can develop and optimize numerous projects using Saas
                    development solutions, including mobile/web apps, APIs, data
                    storage, and hosting. We have decades of experience working
                    with Saas and have the proficiency to complete your projects
                    accurately. Whether you're looking for a one-time
                    consultation or ongoing support throughout the SaaS
                    development process, our qualified developers can help you
                    reach your goals quickly and efficiently.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>BTG - Your Ultimate Tech Partner</h3>
                  <p>
                    We are a leading professional software company specializing
                    in creating high-quality Saas apps for your business. We
                    offer expert Saas-based web design and development services
                    for any project, including full-scale responsive websites,
                    eCommerce websites, and blogs. Our developers offer Sass
                    development services, from helping you create new style
                    sheets from scratch to providing feedback and tips on
                    improving your code. Contact us today to get started!
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Software Designed for Endless Possibilities</h3>
                  <p>
                    The software we build is dynamic, and you will not have to
                    start over from scratch to add new services or features.
                    Introduction of IAAS, PAAS, and now SAAS (everything as a
                    service) is just a click away from you, making your business
                    excel with adaptable services and software.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
