import React from "react";
// import Navbares from "./Component/Nev";
// import Android1 from "../../../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/indeximg/blockchain/smart/smartcontract-design01.png";
import First from "../../../../images/indeximg/blockchain/smart/1.png";
import First1 from "../../../../images/indeximg/blockchain/smart/2.png";

import fintech_design05 from "../../../../images/indeximg/blockchain/smart/smartcontract-design02.png";
import First4 from "../../../../images/indeximg/blockchain/smart/3.png";
import First5 from "../../../../images/indeximg/blockchain/smart/4.png";
import fintech_design04 from "../../../../images/indeximg/blockchain/smart/smartcontract-design03.png";
import First7 from "../../../../images/indeximg/blockchain/smart/5.png";
import First8 from "../../../../images/indeximg/blockchain/smart/6.png";

function Fintechapp() {
  return (
    <>
      <section className="Fintech-sec smart">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Wondering what that Smart Contract thing is?</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    A smart contract is a self-executed computer program stored
                    inside a blockchain that helps users with an easy and
                    hassle-free contract without any third-party involvement.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          This type of contract can be used for various
                          purposes, from financial transactions to intellectual
                          property agreements.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First1}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          The benefits of using smart contracts include the
                          ability to enforce agreements, the removal of
                          third-party involvement, and the creation of
                          tamper-proof contracts.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Streamlining your Business with Smart Contracts</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Businesses can reap countless benefits from blockchain’s
                    smart contracts. It can help companies to save time and
                    money by automating their operations and workflows.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First4}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Smart Contracts enable businesses to improve
                          transparency and credibility by giving access to the
                          concerned parties.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First5}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Utilizing Smart Contracts can help businesses to avoid
                          potential disputes or disagreements, as all terms and
                          conditions will be laid out from the outset.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design05}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Add Efficiency And Transparency To Agreements with us</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design04}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Hire our expert developers at BTG Technologies to create
                    customized Smart Contracts that can serve as a transparent
                    and reliable medium between parties.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First7}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Our designed Smart Contracts are highly secure and
                          immutable, eliminating the risk of privacy breaches
                          and errors.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First8}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Let us do the work for you with our simple-to-use
                          smart contracts, which will spare you all the hassle.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Fintechapp;
