import React from "react";
// import Navbares from "./Component/Nev";
import First from "../../../../images//indeximg/Uiux/Product/customize-solutions.png";
import First1 from "../../../../images//indeximg/Uiux/Product/Agile-technology.png";
import First2 from "../../../../images//indeximg/Uiux/Product/roundtheclock-service.png";

function Expand() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>What Sets us Apart from Others</h3>
                  {/* <p>
                    With Android being one of the most popular Software, you can
                    easily diversify your revenues by using it in your marketing
                    and finances to be more competitive. Our diverse team of
                    engineers and developers can scale your business to more
                    global audiences. Contact us now!
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Customized Solution</h3>
                  <p>
                    We take the time to understand your needs and goals and
                    create a custom plan to bring your vision to life.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First1}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Agile Methodologies</h3>
                  <p>
                    We use the latest tools and technologies to create
                    beautiful, user-friendly, and high-performance software
                    products.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First2}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>24/7 Support</h3>
                  <p>
                    You can find us 24x7x365 a year for any support you require
                    regarding your project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Expand;
