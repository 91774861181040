import React from "react";
import Navbares from "../../../Component/Nev";
import "../../../Blog.css";
import "../../../App.css";

import Leverage from "../../../Component/Services/Blockchain-Development/Ethereum/Loverage";
import Digitalsec from "../../../Component/Services/Blockchain-Development/Ethereum/Digitalsec";
import Expand from "../../../Component/Services/Blockchain-Development/Ethereum/Expand";
import Header01 from "../../../Component/Services/Blockchain-Development/Ethereum/Header01";
import Footer from "../../../Component/Footer";
// import Leverage from "../../../Component/Services/Ethereum/Loverage"
import Getintech from "../../../Component/LetsBusiness";
import Document from "../../../Document";
function Ethereum() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <Header01 />
        <Leverage />
        <Digitalsec />
        <Expand />
        {/* <Lettalk /> */}
        <Getintech info={"Ethereum"} />
      </div>
      <Footer />
      <Document title={"Ethereum"} />
    </>
  );
}

export default Ethereum;
