import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Testing App Consulting Expertise</h3>
                  <p>
                    We can create the entire app from scratch, including the
                    backend logic, user interface design, and marketing
                    materials. If you have any on-demand app ideas to elevate
                    your business, stop waiting and hire our best developers to
                    get the best opportunity.
                  </p>
                  <ul>
                    <li>Custom On-Demand App Development Solutions</li>
                    <li>On-Demand Payment Solutions</li>
                    <li>On-Demand Services Web App Development</li>
                  </ul>
                  <ul>
                    <li>On-Demand Services Solutions</li>
                    <li>On-Demand Delivery App Development</li>
                    <li>On-Demand Appointment App</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
