import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Code Your Way to Success with TypeScript Web Apps</h3>
                  <p>
                    Based on JavaScript, TypeScript is an incredible and
                    power-packed programming language helping businesses to
                    succeed n competition. It offers classes, modules, and
                    interfaces to help you build robust components perfect for
                    large-scale applications. With TypeScript, your business can
                    stay competitive by remaining up-to-date with the latest
                    language features.
                  </p>
                  {/* <ul>
                    <li>Android UI/UX designing</li>
                    <li>Custom Android Apps</li>
                    <li>Cross-platform Android App Development</li>
                  </ul>
                  <ul>
                    <li>Android Game Development</li>
                    <li>Enterprise Apps Development</li>
                    <li>Android App Integration & Modernization</li>
                  </ul>
                  <ul>
                    <li>On-demand Apps Development</li>
                    <li>Android Apps Optimization & QA</li>
                    
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
