import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/E-commerce/Magento/mangento-Design2.png";
import Android2 from "../../../../images/indeximg/E-commerce/Magento/mangento-Design3.png";

import Android3 from "../../../../images/indeximg/E-commerce/Magento/mangento-Design1.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec magen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Why Choose Magento for your Business?</h3>
                  <p>
                    Magento is an open-source platform that is highly scalable
                    and can be used by businesses of all sizes, from small
                    businesses to large enterprises. Magento offers
                    customization and flexibility, allowing businesses to cater
                    to the site according to their goals. Magento is also used
                    by some of the world's leading brands, including Coca-Cola,
                    Ford, and Samsung, showing the platform's reliability.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    Our Scalable Technologies Are Tailor-Made For Today's
                    Digital Market
                  </h3>
                  <p>
                    Our team of Magento developers is an expert in creating
                    tailor-made solutions specifically designed to meet the
                    unique needs of our clients. We know the importance of
                    scalable and flexible platforms to increase sales,
                    conversions, and revenues. Whether you're looking to expand
                    your product range, launch a new marketing campaign, or
                    simply improve your customer experience, we can help.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Engage Customers With A Dynamic Digital Experience</h3>
                  <p>
                    You can elevate your brand awareness and customer loyalty
                    with highly personalized, interactive, and sophisticated
                    storefronts. We enable businesses to drive their conversions
                    and sales with numerous features and options for managing
                    customer relationships, product catalogs, inventories, and
                    orders. By leveraging the power of Magento, businesses can
                    stay ahead of the competition and deliver an exceptional
                    online experience that will keep customers coming back for
                    more.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
