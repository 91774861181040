import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Top-Notch Laravel Development Agency</h3>
                  <p>
                    BTG takes pride in providing top-notch and professional
                    Laravel development services, from developing custom
                    applications to providing support and maintenance for
                    existing ones. Our team of experienced developers can handle
                    any project, no matter how complex it may be. We are
                    equipped with dedicated resources and expertise for
                    developing high-quality web applications.
                  </p>
                  <ul>
                    <li>Laravel Custom App Development</li>
                    <li>Laravel Third-party Integration</li>
                    <li>Migration to Laravel</li>
                  </ul>
                  <ul>
                    <li>Laravel Maintenance & Support</li>
                    <li>Laravel APIs and Extension Development</li>
                  </ul>
                  {/* <ul>
                    <li>On-demand Apps Development</li>
                    <li>Android Apps Optimization & QA</li>
                   
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
