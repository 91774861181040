import React from "react";
// import Navbares from "./Component/Nev";
import facebook from "../images/indeximg/facebook-icon.png";
import twitter from "../images/indeximg/twitter.png";
import youtube from "../images/indeximg/youtube.png";
import linkedin from "../images/indeximg/linkedin.png";
import rededit from "../images/indeximg/rededit.png";
import instagram from "../images/indeximg/instagram.png";

// import facebook from "../images/indeximg/linkedin.png";

function Footer() {
  return (
    <>
      {/* <section className="main-footer">
        <footer className="uncover">
          <div className="footer_inner clearfix">
            <div className="footer_top_holder">
              <div className="footer_top">
                <div className="container">
                  <div className="container_inner">
                    <div className="two_columns_50_50 clearfix">`
                      <div className="column1 footer_col1">
                        <div className="column_inner">
                          <div
                            id="text-5 text-f"
                            className="widget widget_text"
                          >
                            {" "}
                            <div className="textwidget">
                              {" "}
                              <h3>
                                <span
                                  style={{
                                    color: "#fff",
                                    fontWeight: 800,
                                    lineHeight: "1.304347826086957em",
                                  }}
                                >
                                  We Make{" "}
                                  <span
                                    style={{
                                      fontWeight: 800,
                                      color: "#f24a4a",
                                    }}
                                  >
                                    Good
                                  </span>{" "}
                                  Companies{" "}
                                  <span
                                    style={{
                                      fontWeight: 800,
                                      color: "#f24a4a",
                                    }}
                                  >
                                    Great.
                                  </span>
                                </span>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column2 footer_col2">
                        <div className="column_inner">
                          <div
                            id="custom_html-3"
                            className="widget_text widget widget_custom_html"
                          >
                            <div className="textwidget custom-html-widget">
                              <div className="q_icon_with_title medium normal_icon right">
                                <div className="icon_holder">
                                  <span
                                    data-icon-type="normal"
                                    style={{ fontSize: 38 }}
                                    className="qode_iwt_icon_holder q_font_awsome_icon fa-3x"
                                  >
                                    <span
                                      aria-hidden="true"
                                      className="qode_icon_font_elegant icon_pin qode_iwt_icon_element"
                                      style={{ color: "#ffffff" }}
                                    />
                                  </span>
                                </div>
                                <div
                                  className="icon_text_holder"
                                  style={{ paddingRight: 58 }}
                                >
                                  <div className="icon_text_inner">
                                    <h6
                                      className="icon_title"
                                      style={{ color: "#fff", fontWeight: 700 }}
                                    >
                                      Karachi
                                    </h6>
                                    <p style={{ color: "#ccc" }}>
                                      Office # 303, CTC Shopping Mall,
                                      Khayaban-e-Iqbal,
                                      <br />
                                      Clifton Block 8 . Karachi. Pakistan.
                                      <br /> +92-333-1466556 |
                                      info@btgtechnologies.com
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="block-3" className="widget widget_block">
                            <div
                              style={{ height: 20 }}
                              aria-hidden="true"
                              className="wp-block-spacer"
                            />
                          </div>
             
                          <span
                            className="q_social_icon_holder circle_social"
                            data-color="#383838"
                            data-hover-color="#fff"
                          >
                            <a itemProp="url" href="#" target="_blank">
                              <span
                                className="fa-stack"
                                style={{ fontSize: 20 }}
                              >
                                <img
                                  onload="Wpfcll.r(this,true);"
                                  src="../wp-content/plugins/wp-fastest-cache-premium/pro/images/blank.gif"
                                  itemProp="image"
                                  data-wpfc-original-src="../Pictures/facebook-icon.png"
                                  alt="blank"
                                />
                              </span>
                            </a>
                          </span>
                          <span
                            className="q_social_icon_holder circle_social"
                            data-color="#383838"
                            data-hover-color="#fff"
                          >
                            <a itemProp="url" href="#" target="_blank">
                              <span
                                className="fa-stack"
                                style={{
                                  margin: "0px 4px 0px 4px",
                                  fontSize: 20,
                                }}
                              >
                                <img
                                  onload="Wpfcll.r(this,true);"
                                  src="../wp-content/plugins/wp-fastest-cache-premium/pro/images/blank.gif"
                                  itemProp="image"
                                  data-wpfc-original-src="../Pictures/twitter.png"
                                  alt="blank"
                                />
                              </span>
                            </a>
                          </span>
                          <span
                            className="q_social_icon_holder circle_social"
                            data-color="#383838"
                            data-hover-color="#fff"
                          >
                            <a itemProp="url" href="#" target="_blank">
                              <span
                                className="fa-stack"
                                style={{
                                  margin: "0px 4px 0px 4px",
                                  fontSize: 20,
                                }}
                              >
                                <img
                                  onload="Wpfcll.r(this,true);"
                                  src="../wp-content/plugins/wp-fastest-cache-premium/pro/images/blank.gif"
                                  itemProp="image"
                                  data-wpfc-original-src="../Pictures/youtube.png"
                                  alt="blank"
                                />
                              </span>
                            </a>
                          </span>
                          <span
                            className="q_social_icon_holder circle_social"
                            data-color="#383838"
                            data-hover-color="#fff"
                          >
                            <a itemProp="url" href="#" target="_blank">
                              <span
                                className="fa-stack"
                                style={{
                                  margin: "0px 4px 0px 4px",
                                  fontSize: 20,
                                }}
                              >
                                <img
                                  onload="Wpfcll.r(this,true);"
                                  src="../wp-content/plugins/wp-fastest-cache-premium/pro/images/blank.gif"
                                  itemProp="image"
                                  data-wpfc-original-src="../Pictures/linkedin.png"
                                  alt="blank"
                                />
                              </span>
                            </a>
                          </span>
                          <span
                            className="q_social_icon_holder circle_social"
                            data-color="#383838"
                            data-hover-color="#fff"
                          >
                            <a itemProp="url" href="#" target="_blank">
                              <span
                                className="fa-stack"
                                style={{
                                  margin: "0px 4px 0px 4px",
                                  fontSize: 20,
                                }}
                              >
                                <img
                                  onload="Wpfcll.r(this,true);"
                                  src="../wp-content/plugins/wp-fastest-cache-premium/pro/images/blank.gif"
                                  itemProp="image"
                                  data-wpfc-original-src="../Pictures/rededit.png"
                                  alt="blank"
                                />
                              </span>
                            </a>
                          </span>
                          <span
                            className="q_social_icon_holder circle_social"
                            data-color="#383838"
                            data-hover-color="#fff"
                          >
                            <a itemProp="url" href="#" target="_blank">
                              <span
                                className="fa-stack"
                                style={{
                                  margin: "0px 4px 0px 4px",
                                  fontSize: 20,
                                }}
                              >
                                <img
                                  onload="Wpfcll.r(this,true);"
                                  src="../wp-content/plugins/wp-fastest-cache-premium/pro/images/blank.gif"
                                  itemProp="image"
                                  data-wpfc-original-src="../Pictures/instagram.png"
                                  alt="blank"
                                />
                              </span>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer_bottom_holder">
              <div className="container">
                <div className="container_inner">
                  <div className="three_columns footer_bottom_columns clearfix">
                    <div className="column1 footer_bottom_column">
                      <div className="column_inner">
                        <div className="footer_bottom">
                          <div className="textwidget">
                            <p style={{ paddingLeft: 0 }}>
                              <span
                                style={{
                                  color: "#fff",
                                  fontSize: 22,
                                  fontWeight: 700,
                                  letterSpacing: 2,
                                  textTransform: "uppercase",
                                  lineHeight: "1.304347826086957em",
                                }}
                              >
                                BOLD BRANDS BET ON{" "}
                                <span
                                  style={{
                                    fontSize: 22,
                                    fontWeight: 700,
                                    color: "#f24a4a",
                                  }}
                                >
                                  {" "}
                                  BTG!
                                </span>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column2 footer_bottom_column">
                      <div className="column_inner">
                        <div className="footer_bottom">
                          <div className="textwidget custom-html-widget"></div>
                        </div>
                      </div>
                    </div>
                    <div className="column3 footer_bottom_column">
                      <div className="column_inner">
                        <div className="footer_bottom">
                          <div className="textwidget custom-html-widget">
                            <p style={{ fontSize: "20px !important" }}>
                              {" "}
                              2023 © BTG Technologies.{" "}
                              <span className="privacy">
                                <a href="#">Contact Us</a>
                              </span>{" "}
                              <span className="pipe"> | </span>
                              <span className="privacy">
                                <a href="#">About Us</a>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section> */}

      <section className="main-footer">
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div id="text-5 text-f" className="widget widget_text">
                  {" "}
                  <div className="textwidget main-si">
                    {" "}
                    <h3 style={{ marginTop: '8rem' }}>
                      <span
                        style={{
                          color: "#fff",
                          fontWeight: 800,
                          lineHeight: "1.304347826086957em",
                        }}
                      >
                        We Make{" "}
                        <span style={{ fontWeight: 800, color: "#f24a4a" }}>
                          Good
                        </span>{" "}
                        Companies{" "}
                        <span style={{ fontWeight: 800, color: "#f24a4a" }}>
                          Great.
                        </span>
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="column2 footer_col2">
                  <div className="column_inner">
                    <div
                      id="custom_html-3"
                      className="widget_text widget widget_custom_html"
                    >
                      <div className="textwidget custom-html-widget">
                        <div className="q_icon_with_title medium normal_icon right">
                          <div className="icon_holder">
                            <span
                              data-icon-type="normal"
                              style={{ fontSize: 38 }}
                              className="qode_iwt_icon_holder q_font_awsome_icon fa-3x"
                            >
                              <span
                                aria-hidden="true"
                                className="qode_icon_font_elegant icon_pin qode_iwt_icon_element"
                                style={{ color: "#ffffff" }}
                              />
                            </span>
                          </div>
                          <div
                            className="icon_text_holder"
                            style={{ paddingRight: 58 }}
                          >
                            <div className="icon_text_inner">
                              <h6
                                className="icon_title"
                                style={{ color: "#fff", fontWeight: 700 }}
                              >
                                FLORIDA
                              </h6>
                              <p style={{ color: "#ccc" }}>
                                {/* Office # 303, CTC Shopping Mall,
                                Khayaban-e-Iqbal, */}
                                7901 4TH Street North
                                <br />
                                St. Petersburg FL, United States
                                {/* Clifton Block 8 . Karachi. Pakistan. */}
                                <br />
                                33702
                                <br />
                                {/* info@btgtechnologies.com
                                <br />
                                <span><a href="tel:+1 727 308 0419" style={{ textDecoration: 'none', color: '#CCCCCC' }}> +1 727 308 0419 </a></span> */}
                              </p>
                            </div>
                            <div className="icon_text_inner" style={{ marginTop: '-3rem' }}>
                              <h6
                                className="icon_title"
                                style={{ color: "#fff", fontWeight: 700 }}
                              >
                                KARACHI
                              </h6>
                              <p style={{ color: "#ccc" }}>
                                Office # 303, CTC Shopping Mall,
                                Khayaban-e-Iqbal,
                                {/* 7901 4TH Street North */}
                                <br />
                                {/* St. Petersburg FL, United States */}
                                Clifton Block 8 . Karachi. Pakistan.
                                {/* 33702 */}
                                <br />
                                <br />
                                info@btgtechnologies.com
                                <br />
                                <span><a href="tel:+1 727 308 0419" style={{ textDecoration: 'none', color: '#CCCCCC' }}> +1 727 308 0419 </a></span>
                              </p>
                            </div>
                            {/* <div
                              className="icon_text_holder p-0"
                              style={{ paddingRight: 58 }}
                            >
                              <div className="icon_text_inner-1 p-0">
                                <p className="m-0">+1 727 308 0419</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="block-3" className="widget widget_block">
                      <div
                        style={{ height: 20 }}
                        aria-hidden="true"
                        className="wp-block-spacer"
                      />
                    </div>
                    {/*<div id="custom_html-7" class="widget_text widget widget_custom_html"><div className="textwidget custom-html-widget"><div className='q_icon_with_title medium normal_icon right'><div className="icon_holder"><span data-icon-type="normal" style="font-size: 38px;" class="qode_iwt_icon_holder q_font_awsome_icon fa-3x"><span aria-hidden="true" class="qode_icon_font_elegant icon_pin qode_iwt_icon_element" style="color: #ffffff;"></span></span></div><div className="icon_text_holder" style="padding-right:58px;"><div className="icon_text_inner"><h6 class="icon_title" style="color: #fff;font-weight: 700;">Kuala Lumpur</h6><p style='color: #ccc'>Level 1, Menara Amplewest<br />6 Jalan P Ramlee, Malaysia.<br /> +60-112-1487441 | my@buzzinteractive.co</p></div></div></div></div></div><div id="block-4" class="widget widget_block">
                                      <div style="height:20px" aria-hidden="true" class="wp-block-spacer"></div>
                                  </div>
                                  <div id="custom_html-8" class="widget_text widget widget_custom_html"><div className="textwidget custom-html-widget"><div className='q_icon_with_title medium normal_icon right'><div className="icon_holder"><span data-icon-type="normal" style="font-size: 38px;" class="qode_iwt_icon_holder q_font_awsome_icon fa-3x"><span aria-hidden="true" class="qode_icon_font_elegant icon_pin qode_iwt_icon_element" style="color: #ffffff;"></span></span></div><div className="icon_text_holder" style="padding-right:58px;"><div className="icon_text_inner"><h6 class="icon_title" style="color: #fff;font-weight: 700;">USA</h6><p style='color: #ccc'>704 Charles Street<br />Chesapeake VA 23320<br /> &#x202D;+1 (213) 260-4125 | us@buzzinteractive.co</p></div></div></div></div></div><div id="block-2" class="widget widget_block">
                                      <div style="height:20px" aria-hidden="true" class="wp-block-spacer"></div>
                                  </div>*/}
                    <span
                      className="q_social_icon_holder circle_social"
                      data-color="#383838"
                      data-hover-color="#fff"
                    >
                      <a itemProp="url" href="https://www.facebook.com/bridgethegapbtgtechnologies/" target="_blank">
                        <span className="fa-stack" style={{ fontSize: 20 }}>
                          <img src={facebook} itemProp="image" alt="facebook" />
                        </span>
                      </a>
                    </span>
                    <span
                      className="q_social_icon_holder circle_social"
                      data-color="#383838"
                      data-hover-color="#fff"
                    >
                      <a itemProp="url" href="https://x.com/btgtechnologies/" target="_blank">
                        <span
                          className="fa-stack"
                          style={{ margin: "0px 4px 0px 4px", fontSize: 20 }}
                        >
                          <img src={twitter} itemProp="image" alt="twitter" />
                        </span>
                      </a>
                    </span>
                    {/* <span
                      className="q_social_icon_holder circle_social"
                      data-color="#383838"
                      data-hover-color="#fff"
                    >
                      <a itemProp="url" href="#" target="_blank">
                        <span
                          className="fa-stack"
                          style={{ margin: "0px 4px 0px 4px", fontSize: 20 }}
                        >
                          <img src={youtube} itemProp="image" alt="youtube" />
                        </span>
                      </a>
                    </span> */}
                    {/* <span
                      className="q_social_icon_holder circle_social"
                      data-color="#383838"
                      data-hover-color="#fff"
                    >
                      <a itemProp="url" href="#" target="_blank">
                        <span
                          className="fa-stack"
                          style={{ margin: "0px 4px 0px 4px", fontSize: 20 }}
                        >
                          <img src={linkedin} itemProp="image" alt="linkedin" />
                        </span>
                      </a>
                    </span> */}
                    {/* <span
                      className="q_social_icon_holder circle_social"
                      data-color="#383838"
                      data-hover-color="#fff"
                    >
                      <a itemProp="url" href="#" target="_blank">
                        <span
                          className="fa-stack"
                          style={{ margin: "0px 4px 0px 4px", fontSize: 20 }}
                        >
                          <img src={rededit} itemProp="image" alt="rededit" />
                        </span>
                      </a>
                    </span> */}
                    <span
                      className="q_social_icon_holder circle_social"
                      data-color="#383838"
                      data-hover-color="#fff"
                    >
                      <a itemProp="url" href="https://www.instagram.com/bridgethegapbtgtechnologies/" target="_blank">
                        <span
                          className="fa-stack"
                          style={{ margin: "0px 4px 0px 4px", fontSize: 20 }}
                        >
                          <img src={instagram} itemProp="image" alt="instagram" />
                        </span>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="bottom-footer">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="bottom-footer-head">
                  <div className="footer_bottom">
                    <div className="textwidget">
                      <p style={{ paddingLeft: 0 }}>
                        <span className="footerspan" style={{}}>
                          BOLD BRANDS BET ON{" "}
                          <span
                            style={{
                              fontSize: 22,
                              fontWeight: 700,
                              color: "#f24a4a",
                            }}
                          >
                            {" "}
                            BTG!
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="botomright-text">
                  <div className="textwidget custom-html-widget">
                    <p style={{ fontSize: "22px !important", fontWeight: 700 }}>
                      {" "}
                      2023 © BTG Technologies.{" "}
                      {/* <span className="privacy">
                        <a href="#">Terms & Conditions </a>
                      </span>{" "}
                      <span className="pipe"> | </span>
                      <span className="privacy">
                        <a href="#">Privacy & Policy</a>
                      </span> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Footer;
