import React from "react";
import Navbares from "../../../Component/Nev";
import "../../../Blog.css";
import "../../../App.css";

import Footer from "../../../Component/Footer";
import MainBanner from "../../../Component/Services/Digital-Marketing/AffiliateMarketing/Main-banner";
import QuickSolution from "../../../Component/Services/Digital-Marketing/AffiliateMarketing/Quicksolution";
import Fintechapp from "../../../Component/Services/Digital-Marketing/AffiliateMarketing/Fintech-App";
// import Choose from "../../../Component/Services/Digital-Marketing/AffiliateMarketing/Choose";
// import Getintech from "../../../Component/Services/Digital-Marketing/AffiliateMarketing/GetIntech";
import Getintech from "../../../Component/LetsBusiness";
import Document from "../../../Document";
function AffiliateMarketing() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <MainBanner />
        <QuickSolution />
        <Fintechapp />
        {/* <Chose /> */}
        <Getintech info={'Affiliate Marketing'} />

        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={'Affiliate Marketing'} />
    </>
  );
}

export default AffiliateMarketing;
