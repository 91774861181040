import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img swift">
        <div className="left-img swift">
          <div className="short-line"></div>
          <h2 className="heading-1">Swift App Development Servicest</h2>
          <p>
            Create innovative and competitive mobile and desktop apps with us by
            using Swift technology. We at BTG Technologies are proficient in
            developing apps having blazing speed and incredible performance.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
