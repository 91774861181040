import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img Brand">
        <div className="left-img Brands">
          <div className="short-line"></div>
          <h2 className="heading-1">Brand Strategy Services</h2>
          <p>
            Retain and attract customers through our effective brand strategy
            services. Whether starting from scratch or looking to refresh your
            existing brand, we can help you create a powerful and sustainable
            brand that will grow with your business.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
