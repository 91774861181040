import React from "react";
// import Navbares from "./Component/Nev";
import First from "../../../../images/indeximg/web-dev/typescript/committed-team.png";
import First1 from "../../../../images/indeximg/web-dev/typescript/Customization cost-effective.png";
import First2 from "../../../../images/indeximg/web-dev/typescript/technical-support.png";
// import First3 from "../../../../images/Services/competitive-price.png";
// import First4 from "../../../../images/Services/timeframe.png";
// import First5 from "../../../../images/Services/privacy-policy.png";

function Expand() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Building Trust with Our Customers</h3>
                  <p>
                    We at BTG Technologies are reliable TypeScript web
                    developers with years of experience in the field. We have
                    worked on various big and small projects and have the skills
                    and knowledge to get the job done right. We are always
                    up-to-date on the latest trends and technologies, so you can
                    be confident that your project is in good hands. Contact us
                    today!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <div className="why-choce">
                <h3>Why choose us?</h3>
              </div>
            </div> */}
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Experienced Team</h3>
                  <p>
                    We are an experienced team having the expertise to handle
                    projects across multiple
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First1}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Customization</h3>
                  <p>
                    BTG is proficient in tailoring cost-efficient projects that
                    meet and exceed your expectations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First2}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Technical Support</h3>
                  <p>
                    We at BTG Technologies work tirelessly to make your business
                    grow and succeed by providing 24/7 technical support.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First3}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Transparent Pricing</h3>
                  <p>
                    Our team of experienced developers is dedicated to providing
                    quality services at competitive pricing without compromising
                    on quality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First4}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Adhere Timeframe</h3>
                  <p>
                    With our agile development approach and tight deadlines, we
                    strive to be the top choice for android application
                    developers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First5}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Confidentiality</h3>
                  <p>
                    We understand the sensitivity and vulnerability of your data
                    thus we have a implemented strict privacy policy for our
                    clients.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}
export default Expand;
