import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/digital-market/social-maedia/socialmedia-Design3.png";

// import Android1 from "../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/indeximg/digital-market/social-maedia/socialmedia-Design2.png";

import Android3 from "../../../../images/indeximg/digital-market/social-maedia/socialmedia-Design1.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec socialm">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Pristine Marketing Gear for Businesses</h3>
                  <p>
                    Social media marketing updates can benefit businesses in
                    many ways, like high traffic and user engagement, improved
                    rankings, brand recognization, and higher conversion rates.
                    Above all, it is the most cost-effective method that can
                    easily be adopted for brand development.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Globally Adapted Marketing Tool</h3>
                  <p>
                    Social media marketing is undoubtedly one of the most
                    powerful tools many renowned and leading brands use to reach
                    out to potential customers. Companies adopting social media
                    marketing tactics will likely see positive customer
                    retention and growth results. Using social media wisely,
                    companies can build connections with potential customers,
                    resulting in future business opportunities.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    Need to Increase Your Reach? | Get Professional Support
                  </h3>
                  <p>
                    Reach out to us to get digital media marketing assistance
                    from the world’s leading software agency. With proficiency
                    in social marketing, we at BTG Technologies can help you
                    create and manage your social media presence, saving you
                    time and helping you grow your business. We can develop an
                    effective social media strategy, create engaging content,
                    and manage your day-to-day activities to make your business
                    successful.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
