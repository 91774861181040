import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our VR Expertise</h3>
                  <p>
                    From the initial consultation to VR game concept creation
                    and development, we are proficient and experienced. We are
                    equipped with the latest technologies to transform your
                    ideas into reality.
                  </p>
                  <ul>
                    <li>VR Game Development</li>
                    <li>VR Game Integration & Deployment</li>
                  </ul>
                  <ul>
                    <li>VR Game Graphics & Animation Development</li>
                    <li>VR Game Support & Maintenance</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
