import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/Services/Data-training/Ai-data-training-pic02.png";
import Android2 from "../../../../images/Services/Data-training/Ai-data-training-pic03.png";
import First from "../../../../images/Services/Reas=ct/crossplatform-Compatibility.png";
import First1 from "../../../../images/Services/Reas=ct/seo-friendly.png";
import First2 from "../../../../images/Services/Reas=ct/easy-maintenance.png";
import First3 from "../../../../images/Services/customer-engagment.png";

import Android3 from "../../../../images/Services/Data-training/Ai-data-training-pic01.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec sssds">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>What is AI Data Training</h3>
                  <p>
                    AI Data Training is the process of preparing and refining datasets for machine learning models. It involves data collection, labeling, and preprocessing to enable AI systems to learn and make predictions. This critical phase empowers AI with the knowledge and patterns needed to provide accurate insights and intelligent decision-making.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Get Ahead of the Competition with Optimal Technology</h3>
                  <p>
                    Our expert data preparation and optimization services ensure your machine learning models perform at their best. We equip your AI systems with the knowledge and accuracy they need to outshine competitors and drive superior results in today's data-driven landscape.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Cross Platform Compatibility</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First1}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> SEO-Friendly</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First2}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Easy Maintenance</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First3}
                        className="img-fluid "
                        alt="Android"
                      // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Community Support</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Join the Luxurious AI Data Training Ecosystem with us!</h3>
                  <p>

                    Our specialized data preparation and training services offer the pinnacle of excellence in AI development. Elevate your projects with precision, ensuring your AI systems achieve superior performance and deliver unmatched results. Join us for an exclusive journey to AI success!
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
