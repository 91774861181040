import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img privat">
        <div className="left-img privat">
          <div className="short-line"></div>
          <h2 className="heading-1">Private Blockchain Development Services</h2>
          <p>
            Private Blockchain is a platform that creates a safe place for your
            trusted personnel with a minimal chance of getting down as access is
            restricted. The partial decentralized model helps build the model
            you trust for your business and gives you the privacy to run a
            smooth business flow.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
