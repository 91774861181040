import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img imd">
        <div className="left-img las">
          <div className="short-line"></div>
          <h2 className="heading-1">Kotlin App Development Services</h2>
          <p>
            Develop high-end and robust apps for your business using Kotlin. We
            are a leading software company with a team of expert and dependable
            developers ready to take your business to the next level with help
            of Kotlin-built apps.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
