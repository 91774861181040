import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/blockchain/hyperledger/hyperledger-design05.png";
import Android2 from "../../../../images/indeximg/blockchain/hyperledger/hyperledger-design02.png";

import Android3 from "../../../../images/indeximg/blockchain/hyperledger/hyperledger-design01.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec hyper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Why is Hyperledger popular these days?</h3>
                  <p>
                    Hyperledger is an open-source collaborative effort/
                    community created to design frameworks, libraries, and other
                    blockchain technologies to support blockchain ledgers. It
                    enables organizations to introduce and promote new projects
                    with technical and community support. Companies like Huawei,
                    Walmart, and FedEx use Hyperledger to improve transactions'
                    performance, efficiency, and reliability.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>What does Hyperledger blockchain do?</h3>
                  <p>
                    Hyperledger, with improved scalability, performance, and
                    high scalability, is responsible for nurturing projects by
                    providing an open-source ledger framework. Hyperledger is
                    developing with each passing day and has many projects under
                    the umbrella, like Hyperledger Fabric, Iroha, and Indy.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Hyperledger Development Services for Enterprises</h3>
                  <p>
                    Hyperledger is an extremely versatile, flexible, and
                    adaptable framework for developing applications. It helps
                    enterprises to have apps with a high degree of security and
                    transparency. Moreover, with the powerful Hyperledger
                    modular design, businesses can create and deploy
                    blockchain-based apps quickly and efficiently.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
