import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/web-dev/python/phthons-design05.png";
import Android2 from "../../../../images/indeximg/web-dev/python/phthons-design01.png";
import First from "../../../../images/indeximg/web-dev/python/1.png";
import First1 from "../../../../images/indeximg/web-dev/python/2.png";
import First2 from "../../../../images/indeximg/web-dev/python/3.png";
import First3 from "../../../../images/indeximg/web-dev/python/4.png";

import Android3 from "../../../../images/indeximg/web-dev/python/phthons-design03.png";
import First4 from "../../../../images/indeximg/web-dev/python/5.png";
import First5 from "../../../../images/indeximg/web-dev/python/6.png";
import First6 from "../../../../images/indeximg/web-dev/python/7.png";
import First7 from "../../../../images/indeximg/web-dev/python/8.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec python">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Discover the Powerful Benefits of Using Python</h3>
                  <p>
                    Python is a well-suited platform that can create robust and
                    highly scalable apps. It is power-backed with the latest
                    tools and libraries for swift and affordable web app
                    development, making it suitable for small and large-scale
                    projects.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Scalable & Extensible</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First1}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Extensive Libraries</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First2}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Code Reusability</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First3}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Community Support</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Servicing Small Businesses and Enterprises</h3>
                  <p>
                    Python is a adaptable programming language that is used for
                    a wide variety of applications. It is widely used in
                    finance, scientific computing, web development, and more.
                    Python has many features that make it an ideal choice for
                    specific applications, such as its readability and
                    comprehensibility. The industries we serve include:
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First4}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Banking</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First5}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> ECommerce</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First6}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Manufacturing Automation</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First7}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Supply Chain Logistics</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Tired of Java? Migrate to Python Today!</h3>
                  <p>
                    Python is a globally used high-level programming language
                    that can be used for web development. Python also has
                    built-in modules such as template engines and database
                    access that help to make more responsive and
                    high-performance websites. Its popularity is due to its
                    simple syntax, vast libraries and frameworks, and abundance
                    of tools, making it ideal for businesses seeking to reach
                    new markets with more functional web apps. We can help you
                    make a seamless and smooth transition from Java to Python
                    while ensuring data safety.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
