import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>One-stop Shop for all your Kotlin Needs!</h3>
                  <p>
                    Come to us when planning to develop a feature-rich app with
                    powerful backends. Our team of experienced developers is
                    eagerly keen to build apps customized to your needs.
                  </p>
                  <ul>
                    <li>Kotlin App Designing & Development</li>
                    <li>Kotlin PWA Development</li>
                    <li>Kotlin App Consultation</li>
                  </ul>
                  <ul>
                    <li>Kotlin App Testing</li>
                    <li>Kotlin App Maintenance & Support</li>
                    <li>Kotlin App Upgradation & Migration</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
