import React from "react";
import Navbares from "../../../Component/Nev";
import "../../../Blog.css";
import "../../../App.css";

import Footer from "../../../Component/Footer";
import MainBanner from "../../../Component/Services/Mobile-App-Development/Xamarin/Main-banner";
import QuickSolution from "../../../Component/Services/Mobile-App-Development/Xamarin/Quicksolution";
import Fintechapp from "../../../Component/Services/Mobile-App-Development/Xamarin/Fintech-App";
// import Choose from "../../../Component/Services/Mobile-App-Development/Xamarin/Choose";
// import Getintech from "../../../Component/Services/Mobile-App-Development/Xamarin/GetIntech";
import Getintech from "../../../Component/LetsBusiness";
import Document from "../../../Document";
function Xamarin() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <MainBanner />
        <QuickSolution />
        <Fintechapp />
        {/* <Choose /> */}
        <Getintech info={"Xamarin"} />

        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Xamarin"} />

    </>
  );
}

export default Xamarin;
