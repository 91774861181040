import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// All pages
import Home from "./Pages/Home";
import About from "./Pages/About";
// import Service from "./Pages/Services";
// import Solution from "./Pages/Solution";
// Solution packages import
import HealthCareApp from "./Pages/Solutions/HealthCareApp";
import DemandApp from "./Pages/Solutions/DemandApp";
import SaaSDevelopment from "./Pages/Solutions/SaaSDevelopment";
import RealStateApp from "./Pages/Solutions/RealStateApp";
import IntigrationMigration from "./Pages/Solutions/IntigrationMigration";
import Maintainance from "./Pages/Solutions/Maintainance";

// import Consulting from "./Pages/Consulting";

// Mobile App Development pages
import AndroidApp from "./Pages/Services/Mobile-App-Development/AndroidApp";
import IphoneApp from "./Pages/Services/Mobile-App-Development/IphoneApp";
import HybridApp from "./Pages/Services/Mobile-App-Development/HybridApp";
import Kotlin from "./Pages/Services/Mobile-App-Development/Kotlin";
import Ionic from "./Pages/Services/Mobile-App-Development/Ionic";
import Swift from "./Pages/Services/Mobile-App-Development/Swift";
import Flutter from "./Pages/Services/Mobile-App-Development/Flutter";
import Xamarin from "./Pages/Services/Mobile-App-Development/Xamarin";
import ReactNative from "./Pages/Services/Mobile-App-Development/ReactNative";
// Web Development pages

import Asp from "./Pages/Services/Web-Development-services/Asp";
import Php from "./Pages/Services/Web-Development-services/Php";
import WordPress from "./Pages/Services/Web-Development-services/Wordpress";
import TypeScript from "./Pages/Services/Web-Development-services/TypeScript";
import Laravel from "./Pages/Services/Web-Development-services/Laravel";
import Angular from "./Pages/Services/Web-Development-services/Angular";
import Python from "./Pages/Services/Web-Development-services/Python";

// UI UX Designing pages
import UIAnimation from "./Pages/Services/UIUX-Designing/UIAnimation";
import DesignTesting from "./Pages/Services/UIUX-Designing/DesignTesting";
import ProductDesign from "./Pages/Services/UIUX-Designing/ProductDesign";
import UXResearch from "./Pages/Services/UIUX-Designing/UXResearch";
import BrandStrategy from "./Pages/Services/UIUX-Designing/BrandStrategy";

// E-commerce Development pages import
import Shopify from "./Pages/Services/E-Commerce-Development/Shopify";
import Magento from "./Pages/Services/E-Commerce-Development/Magento";
import BigCommerce from "./Pages/Services/E-Commerce-Development/BigCommerce";
import WooCommerce from "./Pages/Services/E-Commerce-Development/WooCommerce";

// Game Development pages import
import Unity3d from "./Pages/Services/Game-Development/Unity3d";
import CasualGames from "./Pages/Services/Game-Development/CasualGames";
import AugementedReality from "./Pages/Services/Game-Development/AugementedReality";
import Metaverse from "./Pages/Services/Game-Development/Metaverse";
import VirtualReality from "./Pages/Services/Game-Development/VirtualReality";
import UnrealEngine from "./Pages/Services/Game-Development/UnrealEngine";

// /Blockchain-Development pages import
import Wallet from "./Pages/Services/Blockchain-Development/Wallet";
import Exchange from "./Pages/Services/Blockchain-Development/Exchange";
import Ethereum from "./Pages/Services/Blockchain-Development/Ethereum";
import PrivateBlockchain from "./Pages/Services/Blockchain-Development/PrivateBlockchain";
import NFTMarketplace from "./Pages/Services/Blockchain-Development/NFTMarketplace";
import Hyperledger from "./Pages/Services/Blockchain-Development/Hyperledger";
import SmartContract from "./Pages/Services/Blockchain-Development/SmartContract";

// Digital Marketing pages import
import SearchEngineOptimization from "./Pages/Services/Digital-Marketing/SearchEngineOptimization";
import EmailMarketing from "./Pages/Services/Digital-Marketing/EmailMarketing";
import MarketAnalytics from "./Pages/Services/Digital-Marketing/MarketAnalytics";
import Ppc from "./Pages/Services/Digital-Marketing/Ppc";
import MobileMarketing from "./Pages/Services/Digital-Marketing/MobileMarketing";
import AffiliateMarketing from "./Pages/Services/Digital-Marketing/AffiliateMarketing";
import SocialMediaMarketing from "./Pages/Services/Digital-Marketing/SocialMediaMarketing";
import ContentMarketing from "./Pages/Services/Digital-Marketing/ContentMarketing";
// Consulting pages import
import MobileAppConsulting from "./Pages/Consulting/MobileAppConsulting";
import WebAppConsulting from "./Pages/Consulting/WebAppConsulting";
import DevOpsConsulting from "./Pages/Consulting/DevOpsConsulting";
import DesignConsulting from "./Pages/Consulting/DesignConsulting";
import TestingConsulting from "./Pages/Consulting/TestingConsulting";
import CloudConsulting from "./Pages/Consulting/CloudConsulting";
import Contact from "./Pages/ContactUs";
// import Popup from "./Component/Popup/popup";
import Thankyou from "./Pages/Thankyou";
import Chatbot from "./Pages/Services/Artificial-Intelligence-Development/Chatbot";
import PromptEngineering from "./Pages/Services/Artificial-Intelligence-Development/PromptEngineering";
import DataTraining from "./Pages/Services/Artificial-Intelligence-Development/DataTraining";
import ScrollToTop from "./ScrollToTo";
// import {useDocTitle} from './components/CustomHook';
// import ScrollToTop from './components/ScrollToTop';

function App() {
  // const [timedPopup, setTimedPopup] = useState(false)

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTimedPopup(true)
  //   }, 3000);
  // }, [])

  return (
    <>
      <Router>
        <ScrollToTop />
        {/* <Popup trigger={timedPopup} setTrigger={setTimedPopup} /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path={"/Thankyou"} element={<Thankyou />} />
          {/* <Route path="/Service" element={<Service />} /> */}
          {/* Artificial-Intelligence-Development */}
          <Route
            path="/Artificial-Intelligence-Development/Chatbot"
            element={<Chatbot />}
          />
          <Route
            path="/Artificial-Intelligence-Development/PromptEngineering"
            element={<PromptEngineering />}
          />
          <Route
            path="/Artificial-Intelligence-Development/DataTraining"
            element={<DataTraining />}
          />
          {/* Mobile-App-Development */}
          <Route
            path="/Mobile-App-Development/AndroidApp"
            element={<AndroidApp />}
          />
          <Route
            path="/Mobile-App-Development/IphoneApp"
            element={<IphoneApp />}
          />
          <Route
            path="/Mobile-App-Development/HybridApp"
            element={<HybridApp />}
          />
          <Route path="/Mobile-App-Development/Kotlin" element={<Kotlin />} />
          <Route path="/Mobile-App-Development/Ionic" element={<Ionic />} />
          <Route path="/Mobile-App-Development/Swift" element={<Swift />} />
          <Route path="/Mobile-App-Development/Flutter" element={<Flutter />} />

          <Route path="/Mobile-App-Development/Xamarin" element={<Xamarin />} />

          <Route
            path="/Mobile-App-Development/ReactNative"
            element={<ReactNative />}
          />

          {/* Web Development services Pages link */}
          <Route path="/Web-Development-Services/Asp" element={<Asp />} />
          <Route path="/Web-Development-Services/Php" element={<Php />} />
          <Route
            path="/Web-Development-Services/Wordpress"
            element={<WordPress />}
          />
          <Route
            path="/Web-Development-Services/TypeScript"
            element={<TypeScript />}
          />
          <Route
            path="/Web-Development-Services/Laravel"
            element={<Laravel />}
          />
          <Route
            path="/Web-Development-Services/Angular"
            element={<Angular />}
          />
          <Route path="/Web-Development-Services/Python" element={<Python />} />

          {/* UI UX Design Pages link  */}
          <Route
            path="/ui-ux-design-services/UIAnimation"
            element={<UIAnimation />}
          />
          <Route
            path="/ui-ux-design-services/DesignTesting"
            element={<DesignTesting />}
          />
          <Route
            path="/ui-ux-design-services/BrandStrategy"
            element={<BrandStrategy />}
          />
          <Route
            path="/ui-ux-design-services/ProductDesign"
            element={<ProductDesign />}
          />
          <Route
            path="/ui-ux-design-services/UXResearch"
            element={<UXResearch />}
          />
          {/* /E-Commerce-Development pages links */}
          <Route path="/E-Commerce-Development/Shopify" element={<Shopify />} />
          <Route path="/E-Commerce-Development/Magento" element={<Magento />} />
          <Route
            path="/E-Commerce-Development/BigCommerce"
            element={<BigCommerce />}
          />
          <Route
            path="/E-Commerce-Development/WooCommerce"
            element={<WooCommerce />}
          />

          {/* GameDevelopment pages link  */}

          <Route path="/Game-Development/Unity3d" element={<Unity3d />} />
          <Route
            path="/Game-Development/CasualGames"
            element={<CasualGames />}
          />
          <Route
            path="/Game-Development/AugementedReality"
            element={<AugementedReality />}
          />
          <Route path="/Game-Development/Metaverse" element={<Metaverse />} />
          <Route
            path="/Game-Development/VirtualReality"
            element={<VirtualReality />}
          />
          <Route
            path="/Game-Development/UnrealEngine"
            element={<UnrealEngine />}
          />

          {/* Blockchain Development pages link  */}
          <Route path="/Blockchain-Development/Wallet" element={<Wallet />} />
          <Route
            path="/Blockchain-Development/Exchange"
            element={<Exchange />}
          />
          <Route
            path="/Blockchain-Development/Ethereum"
            element={<Ethereum />}
          />
          <Route
            path="/Blockchain-Development/PrivateBlockchain"
            element={<PrivateBlockchain />}
          />
          <Route
            path="/Blockchain-Development/NFTMarketplace"
            element={<NFTMarketplace />}
          />
          <Route
            path="/Blockchain-Development/Hyperledger"
            element={<Hyperledger />}
          />
          <Route
            path="/Blockchain-Development/SmartContract"
            element={<SmartContract />}
          />

          {/* Solution page linking */}

          <Route path="/Solutions/HealthCareApp" element={<HealthCareApp />} />
          <Route path="/Solutions/DemandApp" element={<DemandApp />} />
          <Route
            path="/Solutions/SaaSDevelopment"
            element={<SaaSDevelopment />}
          />
          <Route path="/Solutions/RealStateApp" element={<RealStateApp />} />
          <Route
            path="/Solutions/IntigrationMigration"
            element={<IntigrationMigration />}
          />

          <Route path="/Solutions/Maintainance" element={<Maintainance />} />

          {/* Digital Marketing Page linking  */}

          <Route
            path="/Digital-Marketing/SearchEngineOptimization"
            element={<SearchEngineOptimization />}
          />
          <Route
            path="/Digital-Marketing/EmailMarketing"
            element={<EmailMarketing />}
          />
          <Route
            path="/Digital-Marketing/MarketAnalytics"
            element={<MarketAnalytics />}
          />
          <Route path="/Digital-Marketing/Ppc" element={<Ppc />} />
          <Route
            path="Digital-Marketing/MobileMarketing"
            element={<MobileMarketing />}
          />
          <Route
            path="/Digital-Marketing/AffiliateMarketing"
            element={<AffiliateMarketing />}
          />
          <Route
            path="/Digital-Marketing/SocialMediaMarketing"
            element={<SocialMediaMarketing />}
          />
          <Route
            path="/Digital-Marketing/ContentMarketing"
            element={<ContentMarketing />}
          />

          {/* <Route path="/Consulting" element={<Consulting />} /> */}

          {/* Consulting Pages linking  */}

          <Route
            path="/Consulting/MobileAppConsulting"
            element={<MobileAppConsulting />}
          />
          <Route
            path="/Consulting/WebAppConsulting"
            element={<WebAppConsulting />}
          />
          <Route
            path="/Consulting/DevOpsConsulting"
            element={<DevOpsConsulting />}
          />
          <Route
            path="/Consulting/DesignConsulting"
            element={<DesignConsulting />}
          />

          <Route
            path="/Consulting/TestingConsulting"
            element={<TestingConsulting />}
          />
          <Route
            path="/Consulting/CloudConsulting"
            element={<CloudConsulting />}
          />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
