import React from "react";
// import Navbares from "./Component/Nev";
import First from "../../../../images/indeximg/web-dev/Laravel/cost-effective.png";
import First1 from "../../../../images/indeximg/web-dev/Laravel/garrented-satisfaction.png";
import First2 from "../../../../images/indeximg/web-dev/Laravel/uptodate-development.png";

function Expand() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Building Trust with Our Customers</h3>
                  {/* <p>
                    If you're looking for a robust and scalable PHP framework
                    for your next project, Laravel is the right choice. Laravel
                    is a free, open-source framework that helps you create
                    high-quality web applications quickly and efficiently.
                    Laravel’s flexible PHP frameworks, built-in tools, and
                    support for database management make it ideal for businesses
                    to create functional web apps on this platform.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Cost-effective</h3>
                  <p>
                    Our projects are customer-centric and tailored according to
                    the client's needs and budget.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First1}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Commitment to Quality</h3>
                  <p>
                    BTG is committed to quality, and our developers follow best
                    practices to ensure that your project meets standards.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First2}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Up-to-date Development</h3>
                  <p>
                    We always stay up-to-date on the latest changes to Laravel
                    to provide our clients with the best possible experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Expand;
