import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img sasasas">
        <div className="left-img asas">
          <div className="short-line"></div>
          <h2 className="heading-1">AI Data Training Page</h2>
          <p>
            AI Data Training is the backbone of intelligent systems. Our expert data training services refine algorithms, improve accuracy, and enhance AI models. With meticulous data curation, annotation, and validation, we ensure your AI applications learn, adapt, and perform at their best, driving smarter decisions and meaningful outcomes.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
