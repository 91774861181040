import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img saas">
        <div className="left-img saas">
          <div className="short-line"></div>
          <h2 className="heading-1">SaaS Development</h2>
          <p>
            With our suite of SaaS solutions, we specialize in converting your
            ideas into custom software solutions with detailed planning, quality
            product design, and implementation.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
