import React from "react";
// import Navbares from "./Component/Nev";
// import Android1 from "../../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../images/solutions/integration/integrationmigration_design02.png";
import First from "../../../images/solutions/integration/1.png";
import First1 from "../../../images/solutions/integration/2.png";

import fintech_design05 from "../../../images/solutions/integration/integrationmigration_design05.png";
import First4 from "../../../images/solutions/integration/3.png";
import First5 from "../../../images/solutions/integration/4.png";
import fintech_design04 from "../../../images/solutions/integration/integrationmigration_design04.png";
import First7 from "../../../images/solutions/integration/5.png";
import First8 from "../../../images/solutions/integration/6.png";

function Fintechapp() {
  return (
    <>
      <section className="Fintech-sec1  integration">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Get The Insight You Need to Hype-up your Business</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    By utilizing effective and secure data migration and
                    integration, you can create a more efficient and effective
                    business operation, which can, in turn, help to increase
                    sales and profits.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          In addition, you can improve customer satisfaction and
                          loyalty by properly utilizing data migration and
                          integration.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First1}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Expanding into new markets and integrating with other
                          businesses can unlock new growth opportunities.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Merge your Software with Latest Technologies</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    With the right tools and approach, businesses can harness
                    the power of data to improve their operations in a variety
                    of ways, such as improved performance, increased
                    efficiencies, and even cost savings.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First4}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Take leverage of our state-of-the-art tools and
                          resources to integrate your business with the latest
                          technologies to help you stand out in the competition.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First5}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          We use our decades of experience in the online world
                          to ensure effective integration of your software with
                          third-party resources, plugins, and technologies to
                          elevate your business productivity and efficiency.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design05}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>
                  Migrate Your Data Without Destroying Your Existing
                  Infrastructure
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design04}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    When it comes to safe and secure data migration without
                    effective your existing infrastructure, you can count on the
                    expert developing team at BTG Technologies.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First7}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Our premium data integration services can help your
                          company to migrate its data securely and quickly,
                          preserving the accuracy and integrity of your data
                          while ensuring that it's transferred in an appropriate
                          format.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First8}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          When data is not correctly entered or tracked, it can
                          lead to inefficient and costly operations.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Fintechapp;
