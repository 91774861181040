import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Web App Consulting Expertise</h3>
                  <p>
                    We are your one-stop solution for your Cloud needs. Whether
                    you want to develop your business on Cloud or want to
                    migrate your existing data, we are equipped with tools and
                    resources to deliver the best possible results.
                  </p>
                  <ul>
                    <li>Cloud Development</li>
                    <li>Cloud Migration</li>
                    <li>Cloud Configuration</li>
                  </ul>
                  <ul>
                    <li>Cloud Validation & Testing</li>
                    <li>Cloud modernization & Management</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
