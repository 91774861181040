import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img nft">
        <div className="left-img nft">
          <div className="short-line" style={{ backgroundColor: "#000" }}></div>
          <h2 className="heading-1 big">
            NFT Marketplace Development Services
          </h2>
          <p className="big">
            Get a secure, feature-enrich, and custom NFT marketplace by
            collaborating with BTG Technologies. We offer high-end NFT
            marketplace development with a wide range of features and
            functionalities that can be added to your platform, such as support
            for multiple crypto currencies and much more.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
