import React from "react";
import Automation from "../../../../images/indeximg/web-dev/wordpress/plugins-extensions.png";
import Secure from "../../../../images/indeximg/web-dev/wordpress/technical-support.png";
import cost from "../../../../images/indeximg/web-dev/wordpress/secure-models.png";
import greate from "../../../../images/indeximg/web-dev/wordpress/seo-friendlywebsite.png";
import icon from "../../../../images/solutions/Icon1.png";

function QuickSolution() {
  return (
    <>
      <section className="QuickSolution-sec">
        <div className="Quick-maas">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="Quick-head">
                  <h3>
                    Fabricating Websites with <strong> WordPress</strong>{" "}
                    Technology
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quick-fir">
          <div className="container">
            <div className="row quick-sec">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={Automation}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Enterprise-level plugins and extensions</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={Secure}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Immediate technical support</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={cost}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Highly advanced and secure models</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={greate}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>High-performing and SEO-optimized websites</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-5 pb-5">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>
                      WP Theme Development &<br></br> Customization
                    </h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      We can help you with any customization you need, whether
                      adding new features or modifying the existing theme to
                      match your goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main sec1">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>
                      WP Configuration &<br></br> Installation
                    </h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      By entrusting your website configuration and installation
                      to our professional team, you can rest assured that your
                      site is in good hands.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main sec2">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>
                      PSD to WordPress <br></br> Development
                    </h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      We use advanced tools such as Adobe Dreamweaver to
                      transform and relocate your PSD code into CSS, PHP, and
                      HTML.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default QuickSolution;
