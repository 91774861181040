import React from "react";
import Navbares from "../../Component/Nev";
import "../../Blog.css";
import "../../App.css";

// import Lettalk from "../../Component/Let’stalk";

import Leverage from "../../Component/Consulting/CloudConsulting/Loverage";
import Digitalsec from "../../Component/Consulting/CloudConsulting/Digitalsec";
import Expand from "../../Component/Consulting/CloudConsulting/Expand";
import Header01 from "../../Component/Consulting/CloudConsulting/Header01";
import Getintech from "../../Component/LetsBusiness";
import Footer from "../../Component/Footer";
import Document from "../../Document";
// import Leverage from "../../../Component/Services/Android/Loverage"
function CloudConsulting() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <Header01 />
        <Leverage />
        <Digitalsec />
        <Expand />
        <Getintech info={'Cloud Consulting'} />
        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Cloud Consulting"} />
    </>
  );
}

export default CloudConsulting;
