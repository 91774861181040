// import React from "react";
import React, { useState } from "react";
// import $ from "jquery";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
// import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import Logo from "../images/logo-design01.png";

// Nevbares icon mobile-development

import M1 from "../images/menu-icon/mobile app development.svg";
import M2 from "../images/menu-icon/android-icon.svg";
import M3 from "../images/menu-icon/iphone/iphone.svg";
import AI from "../images/menu-icon/ai/ai.svg";
import AI1 from "../images/menu-icon/ai-01/ai-01.svg";
import AI2 from "../images/menu-icon/ai-02/ai-02.svg";
import AI3 from "../images/menu-icon/ai-03/ai-03.svg";
import M4 from "../images/indeximg/packageicon/hybrid-icon.png";
import M5 from "../images/indeximg/packageicon/kotlin.png";
import M6 from "../images/indeximg/packageicon/ionicframework-icon.png";
import M7 from "../images/menu-icon/swift/swift.svg";
import M8 from "../images/menu-icon/flutter/flutter-icon.svg";
import M9 from "../images/menu-icon/xamarin/xamarin.svg";
import M10 from "../images/menu-icon/react/react.svg";

// Nevbares icon Web-development
import Web from "../images/menu-icon/websitedevelopment/website development.svg";
import W1 from "../images/menu-icon/Asp.net/net.svg";
import W2 from "../images/menu-icon/php/php.svg";
import W3 from "../images/menu-icon/wordpress/wordpress.svg";
import W4 from "../images/menu-icon/typescript/typescript.svg";
import W5 from "../images/menu-icon/laravel/laravel.svg";
import W6 from "../images/menu-icon/Angular/angularjs.svg";
import W7 from "../images/menu-icon/phython/python.svg";

// Nevbares icon UI/UX Designing
import UI from "../images/menu-icon/UIUX-Development/UIUX-Development.svg";
import Ui1 from "../images/menu-icon/UI Animated/UI Animated.svg";
import Ui2 from "../images/menu-icon/Design Testing/Testing.svg";
// import Ui3 from "../images/menu-icon/brand-strategy/Brand-Strategy.svg";
import Ui4 from "../images/menu-icon/Product design/product design.svg";
import Ui5 from "../images/menu-icon/UX Resarch/UX Resarch.svg";

// Nevbares icon E commerce Development
import E from "../images/menu-icon/EcommerceDevelopment/Ecommerce Development.svg";
import E1 from "../images/menu-icon/shopify/shopify.svg";
import E2 from "../images/menu-icon/Big commerce/bigcommerce.svg";
// import Ui3 from "../images/menu-icon/brand-strategy/Brand-Strategy.svg";
import E3 from "../images/menu-icon/woocommerce/woocommerce.svg";
import E4 from "../images/menu-icon/Magento/magento.svg";

// Nevbares icon E commerce Development
import G from "../images/menu-icon/GameDevelopment/Game Development.svg";
import G1 from "../images/menu-icon/unity 3d/unity3d.svg";
import G2 from "../images/menu-icon/AR logo/ar.svg";
import G3 from "../images/menu-icon/Virtul Reallity logo/VR-logo.svg";
import G4 from "../images/menu-icon/Causal Game/casual-game.svg";
import G5 from "../images/menu-icon/metaverse/metaverse.svg";
import G6 from "../images/menu-icon/unrealengine/unreal-engine.svg";

// Nevbares icon Block chain Development
import B from "../images/menu-icon/Blockchain-development/Blockchain-development.svg";
import B1 from "../images/menu-icon/wallet/wallet.svg";
import B2 from "../images/menu-icon/exchange/Exchange.svg";
import B3 from "../images/menu-icon/ethereum/ethereum.svg";
import B4 from "../images/menu-icon/private blockchain.svg";
import B5 from "../images/menu-icon/NFT/nft.svg";
import B6 from "../images/menu-icon/Hyperleadger/hyperledger.svg";
import B7 from "../images/menu-icon/smart contrat/Smart Contract.svg";

// Nevbares icon Digital Marketing
import D from "../images/menu-icon/Digitalmarketing/Digital marketing.svg";
import D1 from "../images/menu-icon/seo/seo.svg";
import D2 from "../images/menu-icon/Emailmarketing/Email marketing.svg";
import D3 from "../images/menu-icon/marketinganalytics/marketing analytics.svg";
import D4 from "../images/menu-icon/ppc/ppc.svg";
import D5 from "../images/menu-icon/mobilemarketing/Mobile marketing.svg";
import D6 from "../images/menu-icon/affilicatemarketing/affiliate marketing.svg";
import D7 from "../images/menu-icon/SocialMediaMarketing/Social Media Marketing.svg";
import D8 from "../images/menu-icon/contentmarketing/Content marketing.svg";

// social icon

import linkedin from "../images/social/linkedin.png";
import twitter from "../images/social/twitter.png";
import facebook from "../images/social/facebook-icon.png";
import instagram from "../images/social/instagram.png";
import youtube from "../images/social/youtube.png";

function Nevbares() {

  const [isShownAi1, setIsShownAi1] = useState(false);
  const [isShownAi2, setIsShownAi2] = useState(false);
  const [isShownAi3, setIsShownAi3] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [isShowne, setIsShow] = useState(false);
  const [isShownes, setIsShowes] = useState(false);
  const [isShowes, setIsShownes] = useState(false);
  const [isShowicon, setIsShowIcon] = useState(false);
  const [isShowswift, setIsShowSwift] = useState(false);
  const [isShowefilter, setIsShowFilter] = useState(false);
  const [isShowxam, setIsShowXam] = useState(false);
  const [isShowreact, setIsShowReact] = useState(false);
  const [isShowasp, setIsShowAsp] = useState(false);
  const [isShowphp, setIsShowPhp] = useState(false);
  const [isShowword, setIsShowWord] = useState(false);
  const [isShowtype, setIsShowType] = useState(false);
  const [isShowlarvel, setIsShowLaravel] = useState(false);
  const [isShowangular, setIsShowAngular] = useState(false);
  const [isShowpython, setIsShowPython] = useState(false);
  const [isShowui, setIsShowUi] = useState(false);
  const [isShowdesign, setIsShowDesign] = useState(false);
  const [isShowbrand, setIsShowBrand] = useState(false);
  const [isShowpro, setIsShowPro] = useState(false);
  const [isShowux, setIsShowUx] = useState(false);
  const [isShowshof, setIsShowShof] = useState(false);
  const [isShowbig, setIsShowBig] = useState(false);
  const [isShowwoo, setIsShowWoo] = useState(false);
  const [isShowmag, setIsShowMag] = useState(false);
  const [isShowunity, setIsShowUnity] = useState(false);
  const [isShowaug, setIsShowAug] = useState(false);
  const [isShowvirt, setIsShowVirt] = useState(false);
  const [isShowcas, setIsShowCas] = useState(false);
  const [isShowmeta, setIsShowMeta] = useState(false);
  const [isShowunreal, setIsShowUnreal] = useState(false);
  const [isShowwall, setIsShowWall] = useState(false);
  const [isShowex, setIsShowEx] = useState(false);
  const [isShoweth, setIsShowEth] = useState(false);
  const [isShowpriv, setIsShowPriv] = useState(false);
  const [isShownft, setIsShowNft] = useState(false);
  const [isShowhyp, setIsShowHyp] = useState(false);
  const [isShowsmart, setIsShowSmart] = useState(false);
  const [isShowseo, setIsShowSeo] = useState(false);
  const [isShowemail, setIsShowEmail] = useState(false);
  const [isShowmark, setIsShowMark] = useState(false);
  const [isShowgoogle, setIsShowgGoogle] = useState(false);
  const [isShowmob, setIsShowMob] = useState(false);
  const [isShowaff, setIsShowAff] = useState(false);
  const [isShowsocial, setIsShowSocial] = useState(false);
  const [isShowcont, setIsShowCont] = useState(false);

  return (
    //   <nav class="navbar navbar-expand-lg bg-body-tertiary">
    //   <div class="container-fluid">
    //     <a class="navbar-brand" href="#">Navbar</a>
    //     <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    //       <span class="navbar-toggler-icon"></span>
    //     </button>
    //     <div class="collapse navbar-collapse" id="navbarSupportedContent">
    //       <ul class="navbar-nav me-auto mb-2 mb-lg-0">
    //         <li class="nav-item">
    //           <a class="nav-link active" aria-current="page" href="#">Home</a>
    //         </li>
    //         <li class="nav-item">
    //           <a class="nav-link" href="#">Link</a>
    //         </li>
    //         <li class="nav-item dropdown">
    //           <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    //             Dropdown
    //           </a>
    //           <ul class="dropdown-menu">
    //             <li><a class="dropdown-item" href="#">Action</a></li>
    //             <li><a class="dropdown-item" href="#">Another action</a></li>
    //             <li><hr class="dropdown-divider" /></li>
    //             <li><a class="dropdown-item" href="#">Something else here</a></li>
    //           </ul>
    //         </li>
    //         <li class="nav-item">
    //           <a class="nav-link disabled">Disabled</a>
    //         </li>
    //       </ul>
    //       <form class="d-flex" role="search">
    //         <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
    //         <button class="btn btn-outline-success" type="submit">Search</button>
    //       </form>
    //     </div>
    //   </div>
    // </nav>
    <>
      {[false].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          // sticky="top"
          fixed="top"
          className="nav-visibility bg-trans"
        >
          <Container fluid>
            <Navbar.Brand className="main-logo">
              <Link to="/">
                {" "}
                <img
                  src={Logo}
                  className="img-fluid"
                  alt="UI/UX Logo"

                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                ></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link>
                    <Link to="/"> Home</Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link to="/About">About Us</Link>
                  </Nav.Link>

                  <Nav.Link className="men">
                    <Link to="">Services</Link>

                    <div className="other-element">
                      <div className="services oneto">
                        <h2>Services</h2>
                        {/* <Link
                          to="/

                        "
                        >
                          Back to Home
                        </Link> */}
                        <Offcanvas.Header closeButton className="ser-btn">
                          <Offcanvas.Title
                            id={`offcanvasNavbarLabel-expand-${expand}`}
                          ></Offcanvas.Title>
                        </Offcanvas.Header>
                      </div>
                      <div className="services">
                        <h3>
                          <img
                            src={AI}
                            className="img-fluid"
                            alt="UI/UX Logo"
                            width={200}
                            height={200}
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                          />
                          Artificial Intelligence (AI) Development
                        </h3>
                      </div>
                      <div className="row king">
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <h3 className="main-hs">
                                <Nav.Link
                                  onMouseEnter={() => setIsShownAi1(true)}
                                  onMouseLeave={() => setIsShownAi1(false)}
                                >
                                  <Link
                                    id="AIDev"
                                    to="/Artificial-Intelligence-Development/Chatbot"
                                  >
                                    <img
                                      src={AI1}
                                      className="img-fluid"
                                      alt="UI/UX Logo"
                                      width={200}
                                      height={200}
                                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                                    />
                                    Chatbot
                                  </Link>
                                  <div className="and-img"></div>
                                </Nav.Link>
                              </h3>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                className="main-hs"
                                onMouseEnter={() => setIsShownAi2(true)}
                                onMouseLeave={() => setIsShownAi2(false)}
                              >
                                <Link to="/Artificial-Intelligence-Development/PromptEngineering">
                                  {" "}
                                  <img
                                    src={AI2}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Prompt Engineering
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                className="main-hs"
                                onMouseEnter={() => setIsShownAi3(true)}
                                onMouseLeave={() => setIsShownAi3(false)}
                              >
                                <Link to="/Artificial-Intelligence-Development/DataTraining">
                                  <img
                                    src={AI3}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Data Training
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="services">
                        <h3>
                          <img
                            src={M1}
                            className="img-fluid"
                            alt="UI/UX Logo"
                            width={200}
                            height={200}
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                          />
                          Mobile App Development
                        </h3>
                      </div>
                      <div className="row king">
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <h3 className="main-hs">
                                <Nav.Link
                                  onMouseEnter={() => setIsShown(true)}
                                  onMouseLeave={() => setIsShown(false)}
                                >
                                  <Link
                                    id="MobDev"
                                    to="/Mobile-App-Development/AndroidApp"
                                  >
                                    <img
                                      src={M2}
                                      className="img-fluid"
                                      alt="UI/UX Logo"
                                      width={200}
                                      height={200}
                                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                                    />
                                    Android App
                                  </Link>
                                  <div className="and-img"></div>
                                </Nav.Link>
                              </h3>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                className="main-hs"
                                onMouseEnter={() => setIsShow(true)}
                                onMouseLeave={() => setIsShow(false)}
                              >
                                <Link to="/Mobile-App-Development/IphoneApp">
                                  {" "}
                                  <img
                                    src={M3}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  iPhone App
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                className="main-hs"
                                onMouseEnter={() => setIsShowes(true)}
                                onMouseLeave={() => setIsShowes(false)}
                              >
                                <Link to="/Mobile-App-Development/HybridApp">
                                  <img
                                    src={M4}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Hybrid App
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShownes(true)}
                                onMouseLeave={() => setIsShownes(false)}
                              >
                                <Link to="/Mobile-App-Development/Kotlin">
                                  {" "}
                                  <img
                                    src={M5}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Kotlin
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowIcon(true)}
                                onMouseLeave={() => setIsShowIcon(false)}
                              >
                                <Link to="/Mobile-App-Development/Ionic">
                                  <img
                                    src={M6}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Ionic
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowSwift(true)}
                                onMouseLeave={() => setIsShowSwift(false)}
                              >
                                <Link to="/Mobile-App-Development/Swift">
                                  {" "}
                                  <img
                                    src={M7}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Swift
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowFilter(true)}
                                onMouseLeave={() => setIsShowFilter(false)}
                              >
                                <Link to="/Mobile-App-Development/Flutter">
                                  {" "}
                                  <img
                                    src={M8}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Flutter
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowXam(true)}
                                onMouseLeave={() => setIsShowXam(false)}
                              >
                                <Link to="/Mobile-App-Development/Xamarin">
                                  <img
                                    src={M9}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Xamarin
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowReact(true)}
                                onMouseLeave={() => setIsShowReact(false)}
                              >
                                <Link to="/Mobile-App-Development/ReactNative">
                                  {" "}
                                  <img
                                    src={M10}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  React Native
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="services">
                        <h3>
                          {" "}
                          <img
                            src={B}
                            className="img-fluid"
                            alt="UI/UX Logo"
                            width={200}
                            height={200}
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                          />
                          Blockchain Development
                        </h3>
                      </div>
                      <div className="row">
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowWall(true)}
                                onMouseLeave={() => setIsShowWall(false)}
                              >
                                <Link to="/Blockchain-Development/Wallet">
                                  {" "}
                                  <img
                                    src={B1}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Wallet
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowEx(true)}
                                onMouseLeave={() => setIsShowEx(false)}
                              >
                                <Link to="/Blockchain-Development/Exchange">
                                  {" "}
                                  <img
                                    src={B2}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Exchange
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowEth(true)}
                                onMouseLeave={() => setIsShowEth(false)}
                              >
                                <Link to="/Blockchain-Development/Ethereum">
                                  {" "}
                                  <img
                                    src={B3}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Ethereum
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowPriv(true)}
                                onMouseLeave={() => setIsShowPriv(false)}
                              >
                                <Link to="/Blockchain-Development/PrivateBlockchain">
                                  {" "}
                                  <img
                                    src={B4}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Private BlockChain
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowNft(true)}
                                onMouseLeave={() => setIsShowNft(false)}
                              >
                                <Link to="/Blockchain-Development/NFTMarketplace">
                                  {" "}
                                  <img
                                    src={B5}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  NFT Marketplace
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowHyp(true)}
                                onMouseLeave={() => setIsShowHyp(false)}
                              >
                                <Link to="/Blockchain-Development/Hyperledger">
                                  {" "}
                                  <img
                                    src={B6}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Hyperleadger
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowSmart(true)}
                                onMouseLeave={() => setIsShowSmart(false)}
                              >
                                <Link to="/Blockchain-Development/SmartContract">
                                  {" "}
                                  <img
                                    src={B7}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Smart Contract
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="services">
                        <h3>
                          {" "}
                          <img
                            src={Web}
                            className="img-fluid"
                            alt="UI/UX Logo"
                            width={200}
                            height={200}
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                          />{" "}
                          Web Development
                        </h3>
                      </div>
                      <div className="row">
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowAsp(true)}
                                onMouseLeave={() => setIsShowAsp(false)}
                              >
                                <Link to="/Web-Development-Services/Asp">
                                  {" "}
                                  <img
                                    src={W1}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  ASP .NET
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowPhp(true)}
                                onMouseLeave={() => setIsShowPhp(false)}
                              >
                                <Link to="/Web-Development-Services/Php">
                                  <img
                                    src={W2}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  PHP
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowWord(true)}
                                onMouseLeave={() => setIsShowWord(false)}
                              >
                                <Link to="/Web-Development-Services/WordPress">
                                  {" "}
                                  <img
                                    src={W3}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Wordpress
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowType(true)}
                                onMouseLeave={() => setIsShowType(false)}
                              >
                                <Link to="/Web-Development-Services/TypeScript">
                                  {" "}
                                  <img
                                    src={W4}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Typescript
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowLaravel(true)}
                                onMouseLeave={() => setIsShowLaravel(false)}
                              >
                                <Link to="/Web-Development-Services/Laravel">
                                  <img
                                    src={W5}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Laravel
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowAngular(true)}
                                onMouseLeave={() => setIsShowAngular(false)}
                              >
                                <Link to="/Web-Development-Services/Angular">
                                  {" "}
                                  <img
                                    src={W6}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Angular
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowPython(true)}
                                onMouseLeave={() => setIsShowPython(false)}
                              >
                                <Link to="/Web-Development-Services/Python">
                                  {" "}
                                  <img
                                    src={W7}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Python
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="services">
                        <h3>
                          <img
                            src={E}
                            className="img-fluid"
                            alt="UI/UX Logo"
                            width={200}
                            height={200}
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                          />
                          E-Commerce Developement
                        </h3>
                      </div>
                      <div className="row">
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowShof(true)}
                                onMouseLeave={() => setIsShowShof(false)}
                              >
                                <Link to="/E-Commerce-Development/Shopify">
                                  <img
                                    src={E1}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Shopify
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowBig(true)}
                                onMouseLeave={() => setIsShowBig(false)}
                              >
                                <Link to="/E-Commerce-Development/BigCommerce">
                                  <img
                                    src={E2}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Big Commerce
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowWoo(true)}
                                onMouseLeave={() => setIsShowWoo(false)}
                              >
                                <Link to="/E-Commerce-Development/WooCommerce">
                                  <img
                                    src={E3}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Woo Commerce
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowMag(true)}
                                onMouseLeave={() => setIsShowMag(false)}
                              >
                                <Link to="/E-Commerce-Development/Magento">
                                  <img
                                    src={E4}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />{" "}
                                  Magento
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="services">
                        <h3>
                          <img
                            src={G}
                            className="img-fluid"
                            alt="UI/UX Logo"
                            width={200}
                            height={200}
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                          />{" "}
                          Game Development
                        </h3>
                      </div>
                      <div className="row">
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowUnity(true)}
                                onMouseLeave={() => setIsShowUnity(false)}
                              >
                                <Link to="/Game-Development/Unity3d">
                                  {" "}
                                  <img
                                    src={G1}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Unity 3D
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowAug(true)}
                                onMouseLeave={() => setIsShowAug(false)}
                              >
                                <Link to="/Game-Development/AugementedReality">
                                  {" "}
                                  <img
                                    src={G2}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Augemented Reality
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowVirt(true)}
                                onMouseLeave={() => setIsShowVirt(false)}
                              >
                                <Link to="/Game-Development/VirtualReality">
                                  {" "}
                                  <img
                                    src={G3}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Virtual Reality
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowCas(true)}
                                onMouseLeave={() => setIsShowCas(false)}
                              >
                                <Link
                                  to="/Game-Development/CasualGames
                            "
                                >
                                  {" "}
                                  <img
                                    src={G4}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Casual Games
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowMeta(true)}
                                onMouseLeave={() => setIsShowMeta(false)}
                              >
                                <Link to="/Game-Development/Metaverse">
                                  {" "}
                                  <img
                                    src={G5}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Metaverse
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowUnreal(true)}
                                onMouseLeave={() => setIsShowUnreal(false)}
                              >
                                <Link to="/Game-Development/UnrealEngine">
                                  {" "}
                                  <img
                                    src={G6}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Unreal Engine
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="services">
                        <h3>
                          <img
                            src={UI}
                            className="img-fluid"
                            alt="UI/UX Logo"
                            width={200}
                            height={200}
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                          />
                          UI/UX Designing
                        </h3>
                      </div>
                      <div className="row">
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowUi(true)}
                                onMouseLeave={() => setIsShowUi(false)}
                              >
                                <Link to="/ui-ux-design-services/UIAnimation">
                                  {" "}
                                  <img
                                    src={Ui1}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  UI & Animation
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowDesign(true)}
                                onMouseLeave={() => setIsShowDesign(false)}
                              >
                                <Link to="/ui-ux-design-services/DesignTesting">
                                  <img
                                    src={Ui2}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Design Testing
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowBrand(true)}
                                onMouseLeave={() => setIsShowBrand(false)}
                              >
                                <Link to="/ui-ux-design-services/BrandStrategy">
                                  {" "}
                                  <img
                                    src={Ui4}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Brand Strategy
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowPro(true)}
                                onMouseLeave={() => setIsShowPro(false)}
                              >
                                <Link to="/ui-ux-design-services/ProductDesign">
                                  {" "}
                                  <img
                                    src={Ui4}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Product Design
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowUx(true)}
                                onMouseLeave={() => setIsShowUx(false)}
                              >
                                <Link to="/ui-ux-design-services/UXResearch">
                                  <img
                                    src={Ui5}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  UX Research
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="services">
                        <h3>
                          <img
                            src={D}
                            className="img-fluid"
                            alt="UI/UX Logo"
                            width={200}
                            height={200}
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                          />
                          Digital Marketing
                        </h3>
                      </div>
                      <div className="row">
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowSeo(true)}
                                onMouseLeave={() => setIsShowSeo(false)}
                              >
                                <Link to="/Digital-Marketing/SearchEngineOptimization">
                                  {" "}
                                  <img
                                    src={D1}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Search Engine Optimization (SEO)
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowEmail(true)}
                                onMouseLeave={() => setIsShowEmail(false)}
                              >
                                <Link to="/Digital-Marketing/EmailMarketing">
                                  <img
                                    src={D2}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Email Marketing
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowMark(true)}
                                onMouseLeave={() => setIsShowMark(false)}
                              >
                                <Link to="/Digital-Marketing/MarketAnalytics">
                                  {" "}
                                  <img
                                    src={D3}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Marketing Analytics
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowgGoogle(true)}
                                onMouseLeave={() => setIsShowgGoogle(false)}
                              >
                                <Link to="/Digital-Marketing/Ppc">
                                  {" "}
                                  <img
                                    src={D4}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Google Pay Per Click (PPC)
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowMob(true)}
                                onMouseLeave={() => setIsShowMob(false)}
                              >
                                <Link to="/Digital-Marketing/MobileMarketing">
                                  <img
                                    src={D5}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Mobile Marketing
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowAff(true)}
                                onMouseLeave={() => setIsShowAff(false)}
                              >
                                <Link to="/Digital-Marketing/AffiliateMarketing">
                                  <img
                                    src={D6}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Affiliate Marketing
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>

                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowSocial(true)}
                                onMouseLeave={() => setIsShowSocial(false)}
                              >
                                <Link to="/Digital-Marketing/SocialMediaMarketing">
                                  {" "}
                                  <img
                                    src={D7}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Social Media Marketing
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12  col-sm-12 col-md-6 col-lg-6 col-xxl-4 col-xl-4">
                          <ul class="platform">
                            <li id="DM" class="inside-sub-li">
                              <Nav.Link
                                onMouseEnter={() => setIsShowCont(true)}
                                onMouseLeave={() => setIsShowCont(false)}
                              >
                                <Link to="/Digital-Marketing/ContentMarketing">
                                  <img
                                    src={D8}
                                    className="img-fluid"
                                    alt="UI/UX Logo"
                                    width={200}
                                    height={200}
                                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                                  />
                                  Content Marketing
                                </Link>
                              </Nav.Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Nav.Link>
                  <Nav.Link className="solution-sec">
                    <Link to="">Solution</Link>
                    <div className="other-solution">
                      <div className="services oneto">
                        <h2>Solution</h2>
                        {/* <Link
                          to="/

                        "
                        >
                          Back to Home
                        </Link> */}
                        <Offcanvas.Header closeButton className="ser-btn">
                          <Offcanvas.Title
                            id={`offcanvasNavbarLabel-expand-${expand}`}
                          ></Offcanvas.Title>
                        </Offcanvas.Header>
                      </div>
                      <div className="row">
                        <div className="col-12  col-sm-6 col-md-12 col-lg-6 col-xxl-4 col-xl-4">
                          <Nav.Link>
                            <Link to="/Solutions/HealthCareApp">
                              <div className=" fts bg-img-pos h-50vh">
                                <h2 class="head-txt">Fintech Development</h2>
                              </div>
                            </Link>
                          </Nav.Link>
                        </div>
                        <div className="col-12  col-sm-6 col-md-12 col-lg-6 col-xxl-4 col-xl-4">
                          <Nav.Link>
                            <Link to="/Solutions/DemandApp">
                              <div className="ods bg-img-pos h-50vh">
                                <h2 class="head-txt">
                                  On Demand App Development
                                </h2>
                              </div>
                            </Link>
                          </Nav.Link>
                        </div>
                        <div className="col-12  col-sm-6 col-md-12 col-lg-6 col-xxl-4 col-xl-4">
                          <Nav.Link>
                            <Link to="/Solutions/SaaSDevelopment">
                              <div className=" sas bg-img-pos h-50vh">
                                <h2 class="head-txt">SaaS Development</h2>
                              </div>
                            </Link>
                          </Nav.Link>
                        </div>

                        <div className="col-12  col-sm-6 col-md-12 col-lg-6 col-xxl-4 col-xl-4">
                          <Nav.Link>
                            <Link to="/Solutions/RealStateApp">
                              <div className="res bg-img-pos h-50vh">
                                <h2 class="head-txt black-color1">
                                  Real Estate App Development
                                </h2>
                              </div>
                            </Link>
                          </Nav.Link>
                        </div>
                        <div className="col-12  col-sm-6 col-md-12 col-lg-6 col-xxl-4 col-xl-4">
                          <Nav.Link>
                            <Link to="/Solutions/IntigrationMigration">
                              <div className="iam bg-img-pos h-50vh">
                                <h2 class="head-txt">
                                  Intigration &amp; Migration
                                </h2>
                              </div>
                            </Link>
                          </Nav.Link>
                        </div>
                        <div className="col-12  col-sm-6 col-md-12 col-lg-6 col-xxl-4 col-xl-4">
                          <Nav.Link>
                            <Link to="/Solutions/Maintainance">
                              <div className="mss bg-img-pos h-50vh">
                                <h2 class="head-txt">
                                  Maintance &amp; Support
                                </h2>
                              </div>
                            </Link>
                          </Nav.Link>
                        </div>
                      </div>
                    </div>
                  </Nav.Link>
                  <Nav.Link className="consulting-sec">
                    <Link to="">Consulting</Link>
                    <div className="other-consulting">
                      <div className="services">
                        <div className="services oneto">
                          <h2>Consulting</h2>
                          {/* <Link
                          to="/

                        "
                        >
                          Back to Home
                        </Link> */}
                          <Offcanvas.Header closeButton className="ser-btn">
                            <Offcanvas.Title
                              id={`offcanvasNavbarLabel-expand-${expand}`}
                            ></Offcanvas.Title>
                          </Offcanvas.Header>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12  col-sm-6 col-md-12 col-lg-6 col-xxl-4 col-xl-4">
                          <Nav.Link>
                            <h3>
                              <Link to="/Consulting/MobileAppConsulting">
                                <div className="mac bg-img-pos h-50vh">
                                  <h2 class="head-txt">
                                    Mobile App Consulting
                                  </h2>
                                </div>
                              </Link>
                            </h3>
                          </Nav.Link>
                        </div>
                        <div className="col-12  col-sm-6 col-md-12 col-lg-6 col-xxl-4 col-xl-4">
                          <Nav.Link>
                            <Link to="/Consulting/WebAppConsulting">
                              <div class="wco bg-img-pos h-50vh">
                                <h2 class="head-txt">Web App Consulting</h2>
                              </div>
                            </Link>
                          </Nav.Link>
                        </div>
                        <div className="col-12  col-sm-6 col-md-12 col-lg-6 col-xxl-4 col-xl-4">
                          <Nav.Link>
                            <Link to="/Consulting/DevOpsConsulting">
                              <div class="doc bg-img-pos h-50vh">
                                <h2 class="head-txt">DevOps Consulting</h2>
                              </div>
                            </Link>
                          </Nav.Link>
                        </div>

                        <div className="col-12  col-sm-6 col-md-12 col-lg-6 col-xxl-4 col-xl-4">
                          <Nav.Link>
                            <Link to="/Consulting/DesignConsulting">
                              <div class="ddc bg-img-pos h-50vh">
                                <h2 class="head-txt black-color1">
                                  Design Consulting
                                </h2>
                              </div>
                            </Link>
                          </Nav.Link>
                        </div>
                        <div className="col-12  col-sm-6 col-md-12 col-lg-6 col-xxl-4 col-xl-4">
                          <Nav.Link>
                            <Link to="/Consulting/TestingConsulting">
                              <div class="ttc bg-img-pos h-50vh">
                                <h2 class="head-txt">Testing Consulting</h2>
                              </div>
                            </Link>
                          </Nav.Link>
                        </div>
                        <div className="col-12  col-sm-6 col-md-12 col-lg-6 col-xxl-4 col-xl-4">
                          <Nav.Link>
                            <Link to="/Consulting/CloudConsulting">
                              <div class="ccc bg-img-pos h-50vh">
                                <h2 class="head-txt">Cloud Consulting</h2>
                              </div>
                            </Link>
                          </Nav.Link>
                        </div>
                      </div>
                    </div>
                  </Nav.Link>
                  {/* <Nav.Link>
                    <Link to="/#"> Blogs</Link>
                  </Nav.Link> */}
                  <Nav.Link>
                    <Link to="/Contact">Contact Us</Link>
                  </Nav.Link>

                  {/* <NavDropdown
                    title="Dropdown"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item>Action</NavDropdown.Item>
                    <NavDropdown.Item href="">Another action</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item>Something else here</NavDropdown.Item>
                  </NavDropdown> */}
                </Nav>
                <div className="social-icon">
                  <ul>
                    {/* <li>
                      <Nav.Link>
                        <img
                          src={linkedin}
                          className="img-fluid"
                          alt="UI/UX Logo"
                          width={40}
                          height={40}
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </Nav.Link>
                    </li> */}
                    <li>
                      <Nav.Link href="https://www.facebook.com/bridgethegapbtgtechnologies/" target="_blank">
                        <img
                          src={facebook}
                          className="img-fluid"
                          alt="UI/UX Logo"
                          width={40}
                          height={40}
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link href="https://x.com/btgtechnologies/" target="_blank">
                        <img
                          src={twitter}
                          className="img-fluid"
                          alt="UI/UX Logo"
                          width={40}
                          height={40}
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </Nav.Link>
                    </li>
                    <li>
                      <Nav.Link href="https://www.instagram.com/bridgethegapbtgtechnologies/" target="_blank">
                        <img
                          src={instagram}
                          className="img-fluid"
                          alt="UI/UX Logo"
                          width={40}
                          height={40}
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </Nav.Link>
                    </li>
                    {/* <li>
                      <Nav.Link>
                        <img
                          src={youtube}
                          className="img-fluid"
                          alt="UI/UX Logo"
                          width={40}
                          height={40}
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </Nav.Link>
                    </li> */}
                  </ul>
                </div>
                {isShownAi1 && <div id="Main-Img-round-AI1"></div>}
                {isShownAi2 && <div id="Main-Img-round-AI2"></div>}
                {isShownAi3 && <div id="Main-Img-round-AI3"></div>}
                {isShown && <div id="Main-Img-round-MS"></div>}

                {isShowne && <div id="Main-Img-round-HS"></div>}

                {isShownes && <div id="Main-Img-round-DS"></div>}

                {isShowes && <div id="Main-Img-round-US"></div>}

                {isShowicon && <div id="Main-Img-round-GS"></div>}

                {isShowswift && <div id="Main-Img-round-KS"></div>}

                {isShowefilter && <div id="Main-Img-round-ES"></div>}

                {isShowxam && <div id="Main-Img-round-BS"></div>}

                {isShowreact && <div id="Main-Img-round-WS"></div>}
                {isShowasp && <div id="Main-Img-round-BSS"></div>}
                {/* PHP  and wordpress ares remaning and typescript */}
                {isShowlarvel && <div id="Main-Img-round-FS"></div>}

                {isShowangular && <div id="Main-Img-round-GFS"></div>}
                {isShowpython && <div id="Main-Img-round-AS"></div>}
                {isShowui && <div id="Main-Img-round-CS"></div>}
                {isShowdesign && <div id="Main-Img-round-JS"></div>}
                {isShowbrand && <div id="Main-Img-round-LS"></div>}
                {isShowpro && <div id="Main-Img-round-NS"></div>}
                {isShowux && <div id="Main-Img-round-OS"></div>}
                {isShowshof && <div id="Main-Img-round-PS"></div>}
                {isShowbig && <div id="Main-Img-round-QS"></div>}
                {isShowwoo && <div id="Main-Img-round-SS"></div>}
                {isShowmag && <div id="Main-Img-round-TS"></div>}
                {isShowunity && <div id="Main-Img-round-VS"></div>}
                {isShowaug && <div id="Main-Img-round-XS"></div>}
                {isShowvirt && <div id="Main-Img-round-YS"></div>}
                {isShowcas && <div id="Main-Img-round-ZS"></div>}
                {isShowmeta && <div id="Main-Img-round-AB"></div>}
                {isShowunreal && <div id="Main-Img-round-AC"></div>}
                {isShowwall && <div id="Main-Img-round-AD"></div>}
                {isShowex && <div id="Main-Img-round-AE"></div>}
                {isShoweth && <div id="Main-Img-round-AF"></div>}
                {isShowpriv && <div id="Main-Img-round-AG"></div>}
                {isShownft && <div id="Main-Img-round-AH"></div>}
                {isShowhyp && <div id="Main-Img-round-AI"></div>}
                {isShowsmart && <div id="Main-Img-round-AJ"></div>}
                {isShowseo && <div id="Main-Img-round-AK"></div>}
                {isShowemail && <div id="Main-Img-round-AL"></div>}
                {isShowmark && <div id="Main-Img-round-AM"></div>}
                {isShowgoogle && <div id="Main-Img-round-AN"></div>}
                {isShowmob && <div id="Main-Img-round-AO"></div>}
                {isShowaff && <div id="Main-Img-round-AP"></div>}
                {isShowsocial && <div id="Main-Img-round-AQ"></div>}
                {isShowcont && <div id="Main-Img-round-AR"></div>}
                {isShowphp && <div id="Main-Img-round-ASS"></div>}
                {isShowword && <div id="Main-Img-round-AT"></div>}
                {isShowtype && <div id="Main-Img-round-TSSS"></div>}

                {/* <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form> */}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}
// function saveRoute(val) {
//   sessionStorage.setItem("route", val);
// }
// function navRoute() {
//   var router = sessionStorage.getItem("route");

//   window.location.replace(router);
// }
export default Nevbares;

// Nevbar on hover section start
