import React from "react";
import "./Service.css";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Leverage Our Expertise</h3>
                  <p>
                    We integrate the latest advancements in machine learning and natural language processing to create chatbots that learn and adapt, delivering increasingly personalized and efficient interactions. Count on BTG Technologies to empower your business with intelligent chatbots that drive growth and customer satisfaction.
                  </p>
                  <ul>
                    <li>Rule-Based Chatbots</li>
                    <li>AI-Powered Chatbots</li>
                    <li>Virtual Assistant Chatbots</li>
                  </ul>
                  <ul>
                    <li>Informational Chatbots</li>
                    <li>Customer Support Chatbots</li>
                    <li>Conversational AI Chatbots</li>
                  </ul>
                  <ul>
                    <li>Language Translation Chatbots</li>
                    <li>Educational Chatbots</li>
                    {/* <li>Cross-platform Android App Development</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
