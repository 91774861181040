import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/E-commerce/Big-commerce/Bigcommerce-Design1.png";
import Android2 from "../../../../images/indeximg/E-commerce/Big-commerce/Bigcommerce-Design5.png";

import Android3 from "../../../../images/indeximg/E-commerce/Big-commerce/Bigcommerce-Design4.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec bigecom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">
                    If You Want A BigCommerce Website, We're The Ones To Call
                  </h3>
                  <p className="big">
                    When it comes to BigCommerce development, BTG Technologies
                    is a name of trust and excellence. Our team comprises
                    well-versed and certified developers who can transform your
                    app into the best possible store with the potential to
                    attract a massive audience.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">Bringing Your Ideas to Life</h3>
                  <p className="big">
                    Have a new idea for your eCommerce store? Call us now
                    because we can turn your ideas into reality with our
                    state-of-the-art technologies and techniques. Whether you
                    need a custom-built solution or want to integrate an
                    existing site with BigCommerce, we can make it happen.
                    Contact us today to discuss your project and get started!.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">
                    Build a Best-Selling BigCommerce platform!
                  </h3>
                  <p className="big">
                    Call experts at BTG Technologies to boost your BigCommerce
                    platform's sales, conversions, revenues, and organic
                    traffic. With our mobile-friendly themes and templates,
                    marketing tools, and intuitive interfaces, we can make your
                    site SEO optimized, which can result in enhanced customer
                    engagement and loyalty.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
