import React from "react";
import Automation from "../../../../images/indeximg/blockchain/exchange/fast-secure.png";
import Secure from "../../../../images/indeximg/blockchain/exchange/secure-transection.png";
import cost from "../../../../images/indeximg/blockchain/exchange/userfriendly-app.png";
import greate from "../../../../images/indeximg/blockchain/exchange/Centralized-Exchanges.png";
import icon from "../../../../images/solutions/Icon1.png";

function QuickSolution() {
  return (
    <>
      <section className="QuickSolution-sec">
        <div className="Quick-maas">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="Quick-head">
                  <h3>
                    Get the Ultimate{" "}
                    <strong> Crypto Exchange Development</strong> Solution
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quick-fir">
          <div className="container">
            <div className="row quick-sec">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={Automation}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Secure & Faster Exchange</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={Secure}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Highly Secure & Encrypted</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={cost}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Cost-effective & Reliable</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={greate}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Greater Convenience</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-5 pb-5">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>Finance Data Management</h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      Don’t burden yourself by handling complex and unmanaged
                      finance records; call us now, and we will do the job for
                      you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main sec1">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>B2B/B2C Payment Solutions</h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      Save time and money with our consultation services that
                      will lead you to modernize your old, outdated app! you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main sec2">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>Technical Consultation</h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      Say goodbye to complicated invoices, collecting payments,
                      and dealing with fraudulent clients by using our secure
                      payment processing services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default QuickSolution;
