import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/Services/Swift/Swift-Deign-01.png";
import Android2 from "../../../../images/Services/Swift/Swift-Deign-02.png";

import Android3 from "../../../../images/Services/Swift/Swift-Deign-03.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec sweift">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Innovative Solution for Businesses</h3>
                  <p>
                    Swift is compiled of coding and tools that ensure apps speed
                    performance, and safety. It also provides a set of libraries
                    and frameworks for the development of engaging and
                    interactive apps compatible with Apple products, Linux, and
                    Windows. It also comprises object-oriented features
                    including generics, protocols, Cocoa, and classes. Our
                    swift-build apps are secure, immune to attacks, and are
                    easy-to-maintain. We are the best and most reliable solution
                    for your app development needs no matter if you need a swift
                    app from scratch or want to integrate your current apps with
                    this advanced language.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Contact us for Next-generation App Development</h3>
                  <p>
                    Created by Apple Inc., Swift is an intuitive and powerful
                    programming language used for iOS, macOS, tvOS, and watchOS.
                    We have extensive experience in creating feature-rich apps
                    using the latest Swift features such as Swift UI and
                    combine. Our team of developers has a proven track record of
                    developing successful apps for our clients. Our services
                    include custom app development, app design, and app
                    maintenance. Contact us today to get started!
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>We Offer Affordable Solutions</h3>
                  <p>
                    Our Swift App Development Services are some of the most
                    affordable in the market. We understand that when it comes
                    to developing apps, time is of the essence. That's why we
                    offer our clients a range of services that can be completed
                    swiftly and efficiently. We never sacrifice quality for
                    speed, so you can rest assured that your app will be up to
                    par with the competition. Contact us today to learn more
                    about our Swift App Development Services!
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
