import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/Uiux/brand/brandstretegy-Design01.png";
import Android2 from "../../../../images/indeximg/Uiux/brand/brandstretegy-Design02.png";

import Android3 from "../../../../images/indeximg/Uiux/brand/brandstretegy-Design03.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec brand">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>What are Brand Strategy Services</h3>
                  <p>
                    Brand strategy is a long-term plan to create, implement, and
                    measurement techniques to help brands to achieve specific
                    goals. From researching your competition to identifying
                    potential audiences and developing strategies to keep you
                    stay ahead of the competition, we have it all for you.
                    Contact us now!
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>The Value of a Strong Brand Strategy</h3>
                  <p>
                    We understand the importance of creating a unique and
                    relevant brand identity that can reach the target audience.
                    By developing a clear and concise brand strategy, you can
                    ensure that your business is always on the right track.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Raise Your Business to a New Level</h3>
                  <p>
                    Accelerate your business success and growth by joining hands
                    with one of the most reliable companies. We offer various
                    services to help businesses craft and implement an effective
                    brand strategy, including research and analysis, brand
                    positioning, messaging development, and more.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
