import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img testing">
        <div className="left-img testing">
          <div className="short-line"></div>
          <h2 className="heading-1">Testing Consulting Services</h2>
          <p>
            Testing consultation services can be a valuable resource for
            businesses of all sizes in the software development lifecycle
            (SDLC). Our innovative testing services can offer businesses a way
            to evaluate and test potential apps and software before launch.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
