import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Approach to AR Game Development</h3>
                  <p>
                    From game conceptualization to development, deployment, and
                    post-release support, we provide technical assistance at
                    each step to make your project successful. With our deep
                    understanding of game design, development, and deployment,
                    we provide our customers with the best possible gaming
                    experience..
                  </p>
                  <ul>
                    <li>AR Custom Game Development</li>
                    <li>Location-based AR Game Development</li>
                  </ul>
                  <ul>
                    <li>AR Integration Services</li>
                    <li>Maintenance & Support</li>
                    {/* <li>Android App Integration & Modernization</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
