import React, { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
// import axios from "axios";

function Getintech({ info }) {
  const navigate = useNavigate();
  const [showMessage, setshowMessage] = useState("");
  const [error, seterror] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const HandleFormValidation = useFormik({
    // validationSchema: Yup.object().shape({
    //   Name: Yup.string().required("This field is required"),
    //   EmailAddress: Yup.string()
    //     .email("Invalid email address")
    //     .required("Email is required"),
    //   Description: Yup.string().required("This field is required"),
    //   CategoryId: Yup.string().required("This field is required"),
    //   Phone: Yup.string().required("Mobile number is required"),
    // }),
    initialValues: {
      Name: "",
      EmailAddress: "",
      Phone: "",
      Description: "",
      CategoryId: "",
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const body = {
        CategoryId: `${selectedValue}`,
        Name: `${values.Name}`,
        EmailAddress: `${values.EmailAddress}`,
        Phone: `${values.Phone}`,
        Description: "BTG WEB </br>" + values.Description
        // service: "Bridge The Gap (BTG) Technologies | Branding",
      };
      setLoading(true)
      axios
        .post("https://api.delta19seafood.com/api/lead/CreateLeadFromWbsite", body)
        .then((response) => {
          setshowMessage(response.data);
          resetForm();
          setSubmitting(false);
          // setTimeout(() => {
          //   setshowMessage("");
          // }, 1000);
          setLoading(false)

        })
        .catch((error) => {
          setLoading(false)
          seterror(error);
          setSubmitting(false);
          setTimeout(() => {
            seterror("");
          }, 3000);
        });
    },
  });

  const isFormikValid = (name) =>
    !!(HandleFormValidation.touched[name] && HandleFormValidation.errors[name]);
  const getFormMessage = (name) => {
    return (
      isFormikValid(name) && (
        <p
          className="small m-0"
          style={{ color: "red", fontSize: "12px", paddingLeft: '10px' }}
        >
          {HandleFormValidation.errors[name]}
        </p>
      )
    );
  };
  // State variable to store the selected value

  const handleSelectChange = (event) => {
    const value = event.target.value; // Get the selected value from the event
    setSelectedValue(value); // Update the state with the selected value
  };

  return (
    <>
      <section className="main-banner min-vh-100">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
            <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
              <div className="choce-btg-head">
                <h1>Let’s talk business!</h1>
                <div className="choce-btg-sec">
                  <h3>BTG Technologies – Serving Technology at its Finest</h3>
                  <p>
                    With the BTG team of expert and certified developers at your
                    side, you can rest assured to get high-end app development
                    services. We work 24/7 to provide you with all the necessary
                    tools and support when needed. We are here to help you get
                    the most out of the Ionic project and ensure that your code
                    remains reliable and valid throughout the app's life.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="main-form">
              <div className="get-head">
                <h3>Get In Touch With Us</h3>
              </div>
              <form className="row g-3" onSubmit={HandleFormValidation.handleSubmit}>
                <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 ">
                  <input onChange={HandleFormValidation.handleChange} name="Name"
                    value={HandleFormValidation.values.Name} type="text" className="form-control" placeholder='Full Name' required />
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 ">
                  <input onChange={HandleFormValidation.handleChange} name="EmailAddress"
                    value={HandleFormValidation.values.EmailAddress} type="text" className="form-control" placeholder='Email Address' required />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 ">
                  <input onChange={HandleFormValidation.handleChange} name="Phone"
                    value={HandleFormValidation.values.Phone} type="number" className="form-control" placeholder='Phone Number' required />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 ">
                  <select id={selectedValue} onChange={handleSelectChange} className="form-control" style={{ padding: '1.2rem', backgroundColor: 'transparent', color: 'white' }} placeholder="Choose Catagory" required >
                    <option style={{ backgroundColor: 'rgba(0,0,0,0.8)', }} selected>Select</option>
                    <option style={{ backgroundColor: 'rgba(0,0,0,0.8)', }} value="E84AB28C-B65D-4845-BEA9-C52BFCE794E8">Artificial Intelligence</option>
                    <option style={{ backgroundColor: 'rgba(0,0,0,0.8)', }} value="7148B03E-93C9-416A-9EA0-658E10C3A711">Logo Design</option>
                    <option style={{ backgroundColor: 'rgba(0,0,0,0.8)', }} value="A4D68300-4683-436A-96E5-84143B92103C">Website Design and Development</option>
                    <option style={{ backgroundColor: 'rgba(0,0,0,0.8)', }} value="4CB3A3FF-2593-4513-86F9-D4E9DEB058F8">Branding Design</option>
                    <option style={{ backgroundColor: 'rgba(0,0,0,0.8)', }} value="879652F1-122E-4C1E-8A55-CC3B5848F6E9">Digital Marketing</option>
                    <option style={{ backgroundColor: 'rgba(0,0,0,0.8)', }} value="4CC1100C-89CF-4C8A-A3A3-9BF1F30C789F">Mobile App Development</option>
                    <option style={{ backgroundColor: 'rgba(0,0,0,0.8)', }} value="487778AD-7198-4A29-9094-E87B55A1F273">Other</option>
                  </select>
                </div>
                <div className="col-md-12">
                  <textarea onChange={HandleFormValidation.handleChange} name="Description"
                    value={HandleFormValidation.values.Description} style={{ color: 'white' }} className="form-control" placeholder='Description' required ></textarea>
                </div>
                <div className="d-flex jus justify-content-center">
                  <div className="col-12 col-sm-8 col-lg-4">
                    <button type="submit" className="btn btn-primary">
                      {loading ? <> <Spinner animation="border" variant="primary" /> </> : <>Send Me MESSAGE</>}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Getintech;
