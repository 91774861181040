import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/web-dev/Laravel/laravel-design05.png";
import Android2 from "../../../../images/indeximg/web-dev/Laravel/laravel-design04.png";
// import First from "../../../../images/indeximg/web-dev/Laravel/laravel-design05.png";
// import First1 from "../../../../images/indeximg/web-dev/Laravel/laravel-design05.png";
// import First2 from "../../../../images/indeximg/web-dev/Laravel/laravel-design05.png";
// import First3 from "../../../../images/indeximg/web-dev/Laravel/laravel-design05.png";

import Android3 from "../../../../images/indeximg/web-dev/Laravel/laravel-design01.png";
// import First4 from "../../../../images/indeximg/web-dev/Laravel/laravel-design05.png";
// import First5 from "../../../../images/indeximg/web-dev/Laravel/laravel-design05.png";
// import First6 from "../../../../images/indeximg/web-dev/Laravel/laravel-design05.png";
// import First7 from "../../../../images/indeximg/web-dev/Laravel/laravel-design05.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec Laravel">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Laravel is the Right Choice for your Next Project</h3>
                  <p>
                    If you're looking for a robust and scalable PHP framework
                    for your next project, Laravel is the right choice. Laravel
                    is a free, open-source framework that helps you create
                    high-quality web applications quickly and efficiently.
                    Laravel’s flexible PHP frameworks, built-in tools, and
                    support for database management make it ideal for businesses
                    to create functional web apps on this platform.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Gain an Edge over Your Competition</h3>
                  <p>
                    Accelerate your business success by incorporating
                    Laravel-build apps into your marketing infrastructure. With
                    Laravel, you can create highly responsive and dynamic
                    websites that deliver an immersive and pleasing user
                    experience. Laravel also provides a robust set of tools and
                    features that can make web development easier and more
                    efficient, keeping you ahead of the competition.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Need an Expert Laravel Developer? Call us</h3>
                  <p>
                    We offer top-quality Laravel development services. We are a
                    team of highly skilled and experienced Laravel developers
                    who can provide you with the best possible solution for your
                    Laravel development needs. We have the knowledge and
                    expertise to deliver the project on time and within budget.
                    Contact us now for a free consultation.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
