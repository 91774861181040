import React, { Component } from "react";
import Blogslider from "react-slick";
import Wework from "../images/indeximg/wework.png";
import Youthink from "../images/indeximg/youthink-wedeliver.png";
import weempower from "../images/indeximg/weempower-yourgrowth.png";

export default class VerticalMode extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
    };
    return (
      <div className="maines">
        {/* <h2> Single Item</h2> */}
        <Blogslider {...settings}>
          <div className="blog-slider">
            <div className="blog-slider__wrp swiper-wrapper">
              <div className="blog-slider__item swiper-slide">
                <div className="blog-slider__img">
                  <img src={weempower} alt="weempower" />
                </div>
                <div className="blog-slider__content">
                  {/* <span class="blog-slider__code ">26 December 2019</span> */}
                  <div className="blog-slider__title">
                    We empower your growth engine.
                  </div>
                  {/* <div className="blog-slider__text">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Recusandae voluptate repellendus magni illo ea animi?{" "}
                  </div> */}
                  {/* <a href="#" class="blog-slider__button">
                    READ MORE
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="blog-slider">
            <div className="blog-slider__wrp swiper-wrapper">
              <div className="blog-slider__item swiper-slide">
                <div className="blog-slider__img">
                  <img src={Wework} alt="wework" />
                </div>
                <div className="blog-slider__content">
                  {/* <span class="blog-slider__code ">26 December 2019</span> */}
                  <div className="blog-slider__title">
                    We work on horizontal and vertical velocity.
                  </div>
                  {/* <div className="blog-slider__text">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Recusandae voluptate repellendus magni illo ea animi?{" "}
                  </div> */}
                  {/* <a href="#" class="blog-slider__button">
                    READ MORE
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="blog-slider">
            <div className="blog-slider__wrp swiper-wrapper">
              <div className="blog-slider__item swiper-slide">
                <div className="blog-slider__img">
                  <img src={Youthink} alt="youthink" />
                </div>
                <div className="blog-slider__content">
                  {/* <span class="blog-slider__code ">26 December 2019</span> */}
                  <div className="blog-slider__title">
                    You dream we deliver.
                  </div>
                  {/* <div className="blog-slider__text">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Recusandae voluptate repellendus magni illo ea animi?{" "}
                  </div> */}
                  {/* <a href="#" class="blog-slider__button">
                    READ MORE
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </Blogslider>
      </div>
    );
  }
}
