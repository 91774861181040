import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img poduc">
        <div className="left-img produc">
          <div className="short-line"></div>
          <h2 className="heading-1">Product Design Services</h2>
          <p>
            Elevate your brand awareness, conversions, and leads with our
            innovative and advanced product design services that can help you to
            make high-end and market-competitive digital products.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
