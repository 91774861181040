import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../images/consulting/design/desingconsulting-Deign-01.png";
import Android2 from "../../../images/consulting/design/desingconsulting-Deign-05.png";

import Android3 from "../../../images/consulting/design/desingconsulting-Deign-02.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec design">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    Improve the user experience and drive engagement with
                    Intuitive Designs{" "}
                  </h3>
                  <p>
                    A well-organized app or website with intuitive designs, easy
                    to navigate interface, and an attractive UI can drive more
                    leads and sales. Businesses can foster a better relationship
                    with customers by providing an engaging experience. As a
                    company that specializes in delivering striking designs, we
                    at BTG are always looking for ways to improve the user
                    experience and drive engagement.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>You Demand. We Deliver</h3>
                  <p>
                    Our team of designers and developers work together to create
                    beautiful and easy-to-use interfaces compatible with your
                    visions and business needs. Our design consultants can also
                    help businesses to streamline their processes and improve
                    efficiency, leading to reduced costs and improved
                    performance and competitiveness.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Turn your Ideas into Reality with us!</h3>
                  <p>
                    Whether you need a SEO optimized and potential app from
                    scratch or want to redesign your existing product, we have
                    expertise to get the job done right. Our team can work
                    effortlessly to turn your dream project into a big success.
                    Contact us today to get started.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
