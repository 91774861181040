import React from "react";
import Navbares from "../../Component/Nev";
import "../../Blog.css";
import "../../App.css";

import Leverage from "../../Component/Solutions/SaaS-Development/Loverage";
import Digitalsec from "../../Component/Solutions/SaaS-Development/Digitalsec";
import Expand from "../../Component/Solutions/SaaS-Development/Expand";
import Header01 from "../../Component/Solutions/SaaS-Development/Header01";
import Footer from "../../Component/Footer";
import Getintech from "../../Component/LetsBusiness";
import Document from "../../Document";
// import Leverage from "../../../Component/Services/Android/Loverage"
function SaaSDevelopment() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <Header01 />
        <Leverage />
        <Digitalsec />
        <Expand />
        <Getintech info={"SaaS Development"} />
        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"SaaS Development"} />
    </>
  );
}

export default SaaSDevelopment;
