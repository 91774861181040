import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/web-dev/typescript/typescript-design01.png";

// import Android1 from "../../images/indeximg/web-dev/typescript/Androidapp-Design01.png";
import Android2 from "../../../../images/indeximg/web-dev/typescript/typescript-design02.png";
import First from "../../../../images/indeximg/web-dev/typescript/1.png";
import First1 from "../../../../images/indeximg/web-dev/typescript/2.png";
import First2 from "../../../../images/indeximg/web-dev/typescript/3.png";
import First3 from "../../../../images/indeximg/web-dev/typescript/4.png";

import Android3 from "../../../../images/indeximg/web-dev/typescript/typescript-design03.png";
import First4 from "../../../../images/indeximg/web-dev/typescript/5.png";
import First5 from "../../../../images/indeximg/web-dev/typescript/6.png";
import First6 from "../../../../images/indeximg/web-dev/typescript/7.png";
import First7 from "../../../../images/indeximg/web-dev/typescript/8.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    Let Our Team Of Experts Get Started With What They Do Best!
                  </h3>
                  <p>
                    We are experts in TypeScript development and can provide
                    your business with the tools and resources it needs to
                    succeed. Our typescript web development services include
                    building responsive websites and web applications that are
                    optimized for performance. We use the latest technologies
                    and frameworks to ensure that our products are of the
                    highest quality.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Custom TypeScript Development</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First1}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> JavaScript To TypeScript Migration</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First2}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>TypeScript Optimization & Maintenance</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First3}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>TypeScript Consultation</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Leverage TypeScript & Maximize Productivity</h3>
                  <p>
                    Medium and large businesses can also benefit from using
                    TypeScript, as it can help them scale their development
                    projects more efficiently. By using TypeScript, companies
                    can avoid potential issues when adding new features or
                    making changes to existing ones.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First4}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Increased Engagement</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First5}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Enhanced Customer Loyalty</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First6}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span> Value-Added Brand Recognization</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3>
                      {" "}
                      <img
                        src={First7}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span>Improved Accessibility</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Stop Those Annoying Code Bugs</h3>
                  <p>
                    Tired of unwanted and detrimental errors? Let us help you!
                    We can help you debug your projects with TypeScript, which
                    empowers your businesses by making the codebase stable and
                    scalable. TypeScript provides an extra layer of security and
                    catches potential issues that may cause errors in JavaScript
                    code. This ultimately leads to fewer bugs and errors in web
                    development projects.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
