import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Maintenance & Support Expertise</h3>
                  <p>
                    At BTG Technologies, we offer a full range of services, from
                    regular security updates and bug fixes to software
                    performance, monitoring, and custom development. Our
                    experienced team can take on any maintenance or support task
                    quickly.
                  </p>
                  <ul>
                    <li>Web & Mobile Applications Maintenance</li>
                    <li>SAP Support</li>
                    <li>Remote IT Experts Support</li>
                    <li>Consultation & Estimation</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
