import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img unity">
        <div className="left-img unity3d">
          <div className="short-line"></div>
          <h2 className="heading-1">Unity 3d Game Development</h2>
          <p>
            Get fast, high-quality, and immersive 3d games that can leave a
            long-lasting impact on your customers. We ensure shorter development
            and delivery timeline, a reduced budget, and faster turnaround
            times.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
