import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Utilize our Expertise in Magento</h3>
                  <p>
                    Stand out from your competition by joining hands with a
                    leading software house. At BTG Technologies, we can handle
                    any project, no matter how big or small. From Magento
                    designing, and theme customization, to extension development
                    and integration, we have decades of experience in handling
                    Magento projects.
                  </p>
                  <ul>
                    <li>Magento Designing & Development</li>
                    <li>Magento Migration & Upgradation</li>
                    <li>Magento Maintenance & Support</li>
                  </ul>
                  <ul>
                    <li>Magento Integration</li>
                    <li>Magento Consultation</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
