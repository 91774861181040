import React from "react";
import Navbares from "../../../Component/Nev";
import "../../../Blog.css";
import "../../../App.css";

import Footer from "../../../Component/Footer";
import MainBanner from "../../../Component/Services/Mobile-App-Development/Flutter/Main-banner";
import QuickSolution from "../../../Component/Services/Mobile-App-Development/Flutter/Quicksolution";
import Fintechapp from "../../../Component/Services/Mobile-App-Development/Flutter/Fintech-App";
// import Choose from "../../../Component/Services/Mobile-App-Development/Flutter/Choose";
// import Getintech from "../../../Component/Services/Mobile-App-Development/Flutter/GetIntech";
import Getintech from "../../../Component/LetsBusiness";
import Document from "../../../Document";
function Flutter() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <MainBanner />
        <QuickSolution />
        <Fintechapp />
        {/* <Choose /> */}
        <Getintech info={"Flutter"} />

        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Flutter"} />

    </>
  );
}

export default Flutter;
