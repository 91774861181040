import React from "react";
// import Navbares from "./Component/Nev";
// import Android1 from "../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/indeximg/game/metaverse/metaverse_design02.png";
import First from "../../../../images/indeximg/game/metaverse/1.png";
import First1 from "../../../../images/indeximg/game/metaverse/2.png";

import fintech_design05 from "../../../../images/indeximg/game/metaverse/metaverse_design03.png";
import First4 from "../../../../images/indeximg/game/metaverse/3.png";
import First5 from "../../../../images/indeximg/game/metaverse/4.png";
import fintech_design04 from "../../../../images/indeximg/game/metaverse/metaverse_design04.png";
import First7 from "../../../../images/indeximg/game/metaverse/5.png";
import First8 from "../../../../images/indeximg/game/metaverse/6.png";

function Fintechapp() {
  return (
    <>
      <section className="Fintech-sec metav">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Why Choose Metaverse for your Gaming Project?</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    If you're looking for a powerful and versatile platform that
                    can support a wide variety of gaming genres, then Metaverse
                    is the obvious choice.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Built and supported on blockchain technology, it
                          provides a tamper-proof and immutable environment that
                          makes an online gaming experience fair and
                          transparent.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First1}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Metaverse is a customizable, user-friendly, and
                          constantly evolving platform that can be a perfect
                          choice for any complex gaming project.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>
                  Unleash the Innovative and Immersive Digital World with us!
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Metaverse enables users to interact with each other, digital
                    objects, and real-world objects in a 3D environment.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First4}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Metaverse's 3d platform also allows real-time
                          interaction with digital avatars. BTG Technologies
                          takes pride in delivering an incredible experience
                          that can bring captivating realms to life.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First5}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          We take leverage of the vast ecosystem of Metaverse to
                          create games from simple interfaces to complex
                          gameplays.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design05}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Create A Fully Customizable Gaming Metaverse</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design04}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    We create a fully customizable gaming metaverse that allows
                    you to immerse yourself in your favorite game worlds and
                    interact with other players from around the globe.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First7}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          We have expertise in creating P2P and PvP games by
                          utilizing the advanced features of this vast digital
                          world.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First8}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Using the Unity game engine and the Steamworks SDK, we
                          give users the ability to create custom game worlds
                          and experiences they can share with others.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Fintechapp;
