import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../images/consulting/testing/testingconsulting-Deign-03.png";
import Android2 from "../../../images/consulting/testing/testingconsulting-Deign-02.png";

import Android3 from "../../../images/consulting/testing/testingconsulting-Deign-01.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec testing">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Ignored Testing Standards can Lead to Big Problems</h3>
                  <p>
                    Stop putting yourself at risk by not adhering to
                    industry-accepted guidelines. Fragile and outdated testing
                    standards can lead your business to potential security
                    vulnerabilities, data breaches, and even legal problems.
                    While it's tempting not to take testing services to save
                    money, the consequences can cost you even more. So, it's
                    always best to play it safe and follow accepted guidelines.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    Reach Zenith in Testing Competence with BTG Technologies
                  </h3>
                  <p>
                    With a reliable and result-driven software testing team at
                    your back, you can deliver better products/services, improve
                    customer satisfaction, and lower costs. As a premier
                    software testing consulting services provider, we offer a
                    full range of services to help you ensure the quality of
                    your software products.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Consult with us for an Error-free Launch Every Time!</h3>
                  <p>
                    Our high-quality testing techniques help you in increased
                    productivity and overall app and software quality assurance.
                    We can help businesses save time and money while ensuring
                    that their products are high quality by testing through
                    configured test libraries and resources.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
