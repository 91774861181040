import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Expertise</h3>
                  <p>
                    By understanding customer behavior, demand, and actions, any
                    organization can maintain sales and ROI. We are experts in
                    providing a customized solution that works best and provides
                    the best results.
                  </p>
                  <ul>
                    <li>Custom Research & Analysis</li>
                    <li>Ad Tracking & Measurement</li>
                    <li>Conversion rate Optimization</li>
                  </ul>
                  <ul>
                    <li>Product Promotion & Analytics</li>
                    <li>Predictive Analytics</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
