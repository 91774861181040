import React from "react";
import Navbares from "../../Component/Nev";
import "../../Blog.css";
import "../../App.css";

import Leverage from "../../Component/Solutions/Real-Estate-App/Loverage";
import Digitalsec from "../../Component/Solutions/Real-Estate-App/Digitalsec";
import Expand from "../../Component/Solutions/Real-Estate-App/Expand";
import Header01 from "../../Component/Solutions/Real-Estate-App/Header01";
import Footer from "../../Component/Footer";
import Getintech from "../../Component/LetsBusiness";
import Document from "../../Document";
// import Leverage from "../../../Component/Services/Android/Loverage"
function RealStateApp() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <Header01 />
        <Leverage />
        <Digitalsec />
        <Expand />
        {/* <Lettalk /> */}
        <Getintech info={"Real State App"} />
      </div>
      <Footer />
      <Document title={"Real State App"} />

    </>
  );
}

export default RealStateApp;
