import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Expertise</h3>
                  <p>
                    Google PPC Services can be an effective way to drive traffic
                    to your website and generate leads. At BTG Technologies, we
                    offer innovative and advanced PPC services.
                  </p>
                  <ul>
                    <li>PPC Management</li>
                    <li>Landing Page Conversion Optimization</li>
                    <li>CRO Optimization</li>
                  </ul>
                  <ul>
                    <li>Ads A/B Split Testing</li>
                    <li>Remarketing Campaigns</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
