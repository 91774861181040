import React from "react";
// import Navbares from "./Component/Nev";
import First from "../../../images/consulting/testing/expert-developers.png";
import First1 from "../../../images/consulting/testing/focus-qualityservice.png";
import First2 from "../../../images/consulting/testing/free Consultation.png";

function Expand() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Why Choose us?</h3>
                  {/* <p>
                    With Android being one of the most popular Software, you can
                    easily diversify your revenues by using it in your marketing
                    and finances to be more competitive. Our diverse team of
                    engineers and developers can scale your business to more
                    global audiences. Contact us now!
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>We Know Digital</h3>
                  <p>
                    We have an expert team of developers and testers that can
                    ensure your software quality and functionality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First1}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Focused Team</h3>
                  <p>
                    With eternal focus and commitment to quality, we deliver
                    results that can help you succeed.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First2}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Free Consultation</h3>
                  <p>
                    We offer a free consultation to our valuable clients so that
                    they know what they will invest in.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Expand;
