import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img webapp">
        <div className="left-img webapp">
          <div className="short-line" style={{ backgroundColor: "#000" }}></div>
          <h2 className="heading-1 big">Web App Consulting Services</h2>
          <p className="big">
            We offer consultation services, support, and innovative solutions
            for creating and maintaining functional websites. Our customized web
            apps can provide a more interactive and engaging experience for your
            users, leading to increased customer loyalty and revenue.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
