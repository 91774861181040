import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/Uiux/Product/product-Design02.png";
import Android2 from "../../../../images/indeximg/Uiux/Product/Androidapp-Design02.png";

import Android3 from "../../../../images/indeximg/Uiux/Product/Androidapp-Design03.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>What is Product Design?</h3>
                  <p>
                    Product design refers to developing aesthetically pleasing,
                    functional, and intuitive products that are easy to use. Our
                    product design process is iterative and data-driven, and we
                    constantly test and optimize your products to ensure they
                    are intuitive and easy to use.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Weave Your Vision Into Reality</h3>
                  <p>
                    We strive to turn your innovative ideas into reality by
                    analyzing, prototyping, and testing your vision. Our team
                    has extensive experience in all aspects of software product
                    design, including user experience (UX), user interface (UI),
                    and graphic design. We make sure you get the same as you
                    have imagined.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Expertise You Can Trust</h3>
                  <p>
                    BTG Technologies is a leading name in the IT industry. With
                    our extensive experience and technological skills, we can
                    make any project hit the ground. Our goal is to provide a
                    product that not only meets the client's functional
                    requirements but also exceeds their expectations in terms of
                    quality and value.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
