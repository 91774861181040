import React from "react";
// import Navbares from "./Component/Nev";
// import Android1 from "../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/indeximg/web-dev/asp/asp-design01.png";
import First from "../../../../images/indeximg/web-dev/asp/1.png";
import First1 from "../../../../images/indeximg/web-dev/asp/1.png";

import fintech_design05 from "../../../../images/indeximg/web-dev/asp/asp-design02.png";
import First4 from "../../../../images/indeximg/web-dev/asp/3.png";
import First5 from "../../../../images/indeximg/web-dev/asp/4.png";
import fintech_design04 from "../../../../images/indeximg/web-dev/asp/asp-design03.png";
import First7 from "../../../../images/indeximg/web-dev/asp/5.png";
import First8 from "../../../../images/indeximg/web-dev/asp/6.png";

function Fintechapp() {
  return (
    <>
      <section className="Fintech-sec asp">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Tired of Delays? Satisfaction Guaranteed</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    We are a leading software development company having
                    expertise in .NET development. When it comes to quality and
                    responsiveness, there is no one better than us.
                  </p>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Delay work delivery does not stand anywhere in our
                          services; that’s why we offer a quick turnaround for
                          .NET, which means that you can expect your project to
                          be completed promptly.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img assa">
                    <ul>
                      <li>
                        <img
                          src={First1}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          So why wait? Contact us today to learn more about how
                          we can help you get the job done right!
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>BTG Delivers a Customized solution for everyone</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Should I outsource my .NET project? Do I need a C# or Visual
                    Basic skilled developer? We understand what everyone goes
                    through before hiring a professional. That’s why we offer a
                    wide range of services that cater to different needs and
                    preferences.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First4}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Our experienced developers are skilled in developing
                          web, mobile, and desktop apps.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sa">
                    <ul>
                      <li>
                        <img
                          src={First5}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Contact us today to learn more about our services and
                          how we can help you get started on your next .NET
                          development project!
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design05}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Reap the benefits of ASP.NET Development Services</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design04}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    ASP.NET Development Services for businesses can provide
                    several benefits, including the ability to develop custom
                    applications quickly and efficiently and integrate existing
                    applications with new ones.
                  </p>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First7}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Additionally, ASP.NET development services can help
                          businesses keep costs down by allowing them to reuse
                          code and components from previous projects.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First8}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          ASP.NET Development Services also offers several tools
                          and library support to help businesses speed up
                          development time.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Fintechapp;
