import React from "react";
// import Navbares from "./Component/Nev";
// import Android1 from "../../../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/indeximg/digital-market/Affiliate-market/Affilatedmarketing-Design5.png";
import First from "../../../../images/indeximg/digital-market/Affiliate-market/1.png";
import First1 from "../../../../images/indeximg/digital-market/Affiliate-market/2.png";

import fintech_design05 from "../../../../images/indeximg/digital-market/Affiliate-market/Affilatedmarketing-Design4.png";
import First4 from "../../../../images/indeximg/digital-market/Affiliate-market/3.png";
import First5 from "../../../../images/indeximg/digital-market/Affiliate-market/4.png";
import fintech_design04 from "../../../../images/indeximg/digital-market/Affiliate-market/Affilatedmarketing-Design2.png";
import First7 from "../../../../images/indeximg/digital-market/Affiliate-market/5.png";
import First8 from "../../../../images/indeximg/digital-market/Affiliate-market/6.png";

function Fintechapp() {
  return (
    <>
      <section className="Fintech-sec affiliate">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>What is Affiliate Marketing?</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Affiliate marketing is more of an agreement between an
                    affiliate and the company or website having affiliate links.
                    This connection is developed to boost sales and profits.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Through this mutually beneficial link, the affiliate
                          gets a percentage of any sales made through the
                          affiliate link. The advertiser gets traffic and
                          potential customers from clicking on the affiliate
                          link.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First1}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Effective affiliate marketing allows businesses to tap
                          into a wide network of potential customers without
                          spending a lot of money on advertising and marketing.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Impact of Affiliate Strategy on Businesses</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Businesses can track their marketing efforts and get
                    real-time analytics with the help of effective affiliate
                    marketing.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First4}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          This information is invaluable in helping businesses
                          fine-tune their marketing strategies and ensure they
                          get the most out of their businesses.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First5}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          A business endorsed by a reputable and trustworthy
                          affiliate marketing service can earn customer trust
                          and loyalty, resulting in more sales.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design05}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>
                  Most Reliable and Professional Affiliate Marketing Service
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design04}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    BTG Technologies is a renowned and creditable company
                    serving clients with world-class level affiliate services
                    designed to drive results.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First7}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          We are helping start-ups, and growing businesses
                          target specific markets and demographics with our
                          high-end affiliate techniques.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First8}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          We work closely with clients to help them identify
                          products or services customers are interested in so
                          that they can focus their marketing efforts on these
                          target audiences, which can result in increased sales
                          and ROI.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Fintechapp;
