import React from "react";
// import Navbares from "./Component/Nev";
function Projectlink() {
  return (
    <>
      <section className="footer-main">
        <div className="container">
          <div className="row wpb_wrapper">
            <div className="col-xs-12 col-sm-4 col-md-4" id="footer1">
              <h5>Artificial Intelligence</h5>
              <ul
                className="list-unstyled quick-links"
                style={{ listStyleType: "none" }}
              >
                <li>
                  <a href="/Artificial-Intelligence-Development/Chatbot">
                    <i className="fa fa-angle-double-right" />
                    Chatbot
                  </a>
                </li>
                <li>
                  <a href="/Artificial-Intelligence-Development/PromptEngineering">
                    <i className="fa fa-angle-double-right" />
                    Prompt Engineering
                  </a>
                </li>
                <li>
                  <a href="/Artificial-Intelligence-Development/DataTraining">
                    <i className="fa fa-angle-double-right" />
                    Data Training
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4" id="footer1">
              <h5>Mobile App Development</h5>
              <ul
                className="list-unstyled quick-links"
                style={{ listStyleType: "none" }}
              >
                <li>
                  <a href="/Mobile-App-Development/AndroidApp">
                    <i className="fa fa-angle-double-right" />
                    Android App
                  </a>
                </li>
                <li>
                  <a href="/Mobile-App-Development/IphoneApp">
                    <i className="fa fa-angle-double-right" />
                    iPhone App
                  </a>
                </li>
                <li>
                  <a href="/Mobile-App-Development/HybridApp">
                    <i className="fa fa-angle-double-right" />
                    Hybrid App
                  </a>
                </li>
                <li>
                  <a href="/Mobile-App-Development/Kotlin">
                    <i className="fa fa-angle-double-right" />
                    Kotlin
                  </a>
                </li>
                <li>
                  <a href="/Mobile-App-Development/Ionic">
                    <i className="fa fa-angle-double-right" />
                    Ionic
                  </a>
                </li>
                <li>
                  <a href="/Mobile-App-Development/Swift">
                    <i className="fa fa-angle-double-right" />
                    Swift
                  </a>
                </li>
                <li>
                  <a href="/Mobile-App-Development/Flutter">
                    <i className="fa fa-angle-double-right" />
                    Flutter
                  </a>
                </li>
                <li>
                  <a href="/Mobile-App-Development/Xamarin">
                    <i className="fa fa-angle-double-right" />
                    Xamarin
                  </a>
                </li>
                <li>
                  <a href="/Mobile-App-Development/ReactNative">
                    <i className="fa fa-angle-double-right" />
                    React Native
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4" id="footer1">
              <h5>Blockchain Development</h5>
              <ul
                className="list-unstyled quick-links"
                style={{ listStyleType: "none" }}
              >
                <li>
                  <a href="/Blockchain-Development/Wallet">
                    <i className="fa fa-angle-double-right" />
                    Wallet
                  </a>
                </li>
                <li>
                  <a href="/Blockchain-Development/Exchange">
                    <i className="fa fa-angle-double-right" />
                    Exchange
                  </a>
                </li>
                <li>
                  <a href="/Blockchain-Development/Ethereum">
                    <i className="fa fa-angle-double-right" />
                    Ethereum
                  </a>
                </li>
                <li>
                  <a href="/Blockchain-Development/PrivateBlockchain">
                    <i className="fa fa-angle-double-right" /> Private
                    Blockchain
                  </a>
                </li>
                <li>
                  <a href="/Blockchain-Development/NFTMarketplace">
                    <i className="fa fa-angle-double-right" /> NFT Marketplace
                  </a>
                </li>
                <li>
                  <a href="/Blockchain-Development/Hyperledger">
                    <i className="fa fa-angle-double-right" />
                    Hyper Ledger
                  </a>
                </li>
                <li>
                  <a href="/Blockchain-Development/SmartContract">
                    <i className="fa fa-angle-double-right" /> Smart Contract
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="col-xs-12 col-sm-4 col-md-4" id="footer1">
              <h5>Web Development</h5>
              <ul
                className="list-unstyled quick-links"
                style={{ listStyleType: "none" }}
              >
                <li>
                  <a href="/Web-Development-services/Asp">
                    <i className="fa fa-angle-double-right" />
                    Asp .Net
                  </a>
                </li>
                <li>
                  <a href="/Web-Development-services/Php">
                    <i className="fa fa-angle-double-right" />
                    PHP
                  </a>
                </li>
                <li>
                  <a href="/Web-Development-services/Wordpress">
                    <i className="fa fa-angle-double-right" />
                    Wordpress
                  </a>
                </li>
                <li>
                  <a href="/Web-Development-services/TypeScript">
                    <i className="fa fa-angle-double-right" />
                    Type Script
                  </a>
                </li>
                <li>
                  <a href="/Web-Development-services/Laravel">
                    <i className="fa fa-angle-double-right" /> Laravel
                  </a>
                </li>
                <li>
                  <a href="/Web-Development-services/Angular">
                    <i className="fa fa-angle-double-right" />
                    Angular
                  </a>
                </li>
                <li>
                  <a href="/Web-Development-services/Python">
                    <i className="fa fa-angle-double-right" />
                    Python
                  </a>
                </li>
              </ul>
            </div> */}

          </div>
          <div className="row wpb_wrapper" style={{ margin: '3rem 0rem' }}>
            <div className="col-xs-12 col-sm-4 col-md-4" id="footer1">
              <h5>Web Development</h5>
              <ul
                className="list-unstyled quick-links"
                style={{ listStyleType: "none" }}
              >
                <li>
                  <a href="/Web-Development-services/Asp">
                    <i className="fa fa-angle-double-right" />
                    Asp .Net
                  </a>
                </li>
                <li>
                  <a href="/Web-Development-services/Php">
                    <i className="fa fa-angle-double-right" />
                    PHP
                  </a>
                </li>
                <li>
                  <a href="/Web-Development-services/Wordpress">
                    <i className="fa fa-angle-double-right" />
                    Wordpress
                  </a>
                </li>
                <li>
                  <a href="/Web-Development-services/TypeScript">
                    <i className="fa fa-angle-double-right" />
                    Type Script
                  </a>
                </li>
                <li>
                  <a href="/Web-Development-services/Laravel">
                    <i className="fa fa-angle-double-right" /> Laravel
                  </a>
                </li>
                <li>
                  <a href="/Web-Development-services/Angular">
                    <i className="fa fa-angle-double-right" />
                    Angular
                  </a>
                </li>
                <li>
                  <a href="/Web-Development-services/Python">
                    <i className="fa fa-angle-double-right" />
                    Python
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4" id="footer1">
              <h5>E-Commerce Development</h5>
              <ul
                className="list-unstyled quick-links"
                style={{ listStyleType: "none" }}
              >
                <li>
                  <a href="/E-Commerce-Development/Shopify">
                    <i className="fa fa-angle-double-right" />
                    Shopify
                  </a>
                </li>
                <li>
                  <a href="/E-Commerce-Development/BigCommerce">
                    <i className="fa fa-angle-double-right" />
                    Big Commerce
                  </a>
                </li>
                <li>
                  <a href="/E-Commerce-Development/WooCommerce">
                    <i className="fa fa-angle-double-right" />
                    Woo Commerce
                  </a>
                </li>
                <li>
                  <a href="/E-Commerce-Development/Magento">
                    <i className="fa fa-angle-double-right" />
                    Magento
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4" id="footer1">
              <h5>Game Development</h5>
              <ul
                className="list-unstyled quick-links"
                style={{ listStyleType: "none" }}
              >
                <li>
                  <a href="/Services/Game-Development/Unity3d">
                    <i className="fa fa-angle-double-right" />
                    Unity 3D
                  </a>
                </li>
                <li>
                  <a href="/Services/Game-Development/AugementedReality">
                    <i className="fa fa-angle-double-right" />
                    Augemented Reality
                  </a>
                </li>
                <li>
                  <a href="/Services/Game-Development/VirtualReality">
                    <i className="fa fa-angle-double-right" />
                    Virtual Reality
                  </a>
                </li>
                <li>
                  <a href="/Services/Game-Development/CasualGames">
                    <i className="fa fa-angle-double-right" />
                    Casual Games
                  </a>
                </li>
                <li>
                  <a href="/Services/Game-Development/Metaverse">
                    <i className="fa fa-angle-double-right" />
                    Metaverse
                  </a>
                </li>
                <li>
                  <a href="/Services/Game-Development/UnrealEngine">
                    <i className="fa fa-angle-double-right" />
                    Unreal Engine
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row wpb_wrapper">
            <div className="col-xs-12 col-sm-4 col-md-4" id="footer1">
              <h5>UI / UX Designing</h5>
              <ul
                className="list-unstyled quick-links"
                style={{ listStyleType: "none" }}
              >
                <li>
                  <a href="/UIUX-Designing/UIAnimation">
                    <i className="fa fa-angle-double-right" />
                    UI &amp; Animation
                  </a>
                </li>
                <li>
                  <a href="/UIUX-Designing/DesignTesting">
                    <i className="fa fa-angle-double-right" />
                    Design Testing
                  </a>
                </li>
                <li>
                  <a href="/UIUX-Designing/BrandStrategy">
                    <i className="fa fa-angle-double-right" />
                    Brand Strategy
                  </a>
                </li>
                <li>
                  <a href="/UIUX-Designing/ProductDesign">
                    <i className="fa fa-angle-double-right" />
                    Product Design
                  </a>
                </li>
                <li>
                  <a href="/UIUX-Designing/UXResearch">
                    <i className="fa fa-angle-double-right" />
                    UX Research
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4" id="footer1">
              <h5>Digital Marketing</h5>
              <ul
                className="list-unstyled quick-links"
                style={{ listStyleType: "none" }}
              >
                <li>
                  <a href="/Digital-Marketing/SearchEngineOptimization">
                    <i className="fa fa-angle-double-right" />
                    Search Engine Optimization (SEO)
                  </a>
                </li>
                <li>
                  <a href="/Digital-Marketing/Emailmaketing">
                    <i className="fa fa-angle-double-right" />
                    Email Marketing
                  </a>
                </li>
                <li>
                  <a href="/Digital-Marketing/MarketAnalytics">
                    <i className="fa fa-angle-double-right" />
                    Marketing Analytics
                  </a>
                </li>
                <li>
                  <a href="/Digital-Marketing/ppc">
                    <i className="fa fa-angle-double-right" />
                    Google Pay Per Click (PPC)
                  </a>
                </li>
                <li>
                  <a href="/Digital-Marketing/mobilemarketing">
                    <i className="fa fa-angle-double-right" />
                    Mobile Marketing
                  </a>
                </li>
                <li>
                  <a href="/Digital-Marketing/AffiliateMarketing">
                    <i className="fa fa-angle-double-right" />
                    Affiliat Marketing
                  </a>
                </li>
                <li>
                  <a href="/Digital-Marketing/Socialmediamarketing">
                    <i className="fa fa-angle-double-right" />
                    Social Media Marketing
                  </a>
                </li>
                <li>
                  <a href="/Digital-Marketing/Contentmarketing">
                    <i className="fa fa-angle-double-right" />
                    Content Marketing
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4" id="footer1">
              <h5>Solutions</h5>
              <ul
                className="list-unstyled quick-links"
                style={{ listStyleType: "none" }}
              >
                <li>
                  <a href="/Solutions/HealthCareApp">
                    <i className="fa fa-angle-double-right" />
                    Fintech Development
                  </a>
                </li>
                <li>
                  <a href="/Solutions/DemandApp">
                    <i className="fa fa-angle-double-right" />
                    On-Demand App Development
                  </a>
                </li>
                <li>
                  <a href="/Solutions/SaaSDevelopment">
                    <i className="fa fa-angle-double-right" />
                    Saas Develoment
                  </a>
                </li>
                <li>
                  <a href="/Solutions/RealStateApp">
                    <i className="fa fa-angle-double-right" />
                    Real Estate App Development
                  </a>
                </li>
                <li>
                  <a href="/Solutions/IntigrationMigration">
                    <i className="fa fa-angle-double-right" />
                    Integration &amp; Migration
                  </a>
                </li>
                <li>
                  <a href="/Solutions/Maintainance">
                    <i className="fa fa-angle-double-right" />
                    Maintenance &amp; Support
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4" id="footer1">
              <h5>Consulting</h5>
              <ul
                className="list-unstyled quick-links"
                style={{ listStyleType: "none" }}
              >
                <li>
                  <a href="/Consulting/MobileAppConsulting">
                    <i className="fa fa-angle-double-right" />
                    Mobile App Consulting
                  </a>
                </li>
                <li>
                  <a href="/Consulting/WebAppConsulting">
                    <i className="fa fa-angle-double-right" />
                    Web App Consulting
                  </a>
                </li>
                <li>
                  <a href="/Consulting/DevOpsConsulting">
                    <i className="fa fa-angle-double-right" />
                    DevOps Consulting
                  </a>
                </li>
                <li>
                  <a href="/Consulting/DesignConsulting">
                    <i className="fa fa-angle-double-right" />
                    Design Consulting
                  </a>
                </li>
                <li>
                  <a href="/Consulting/TestingConsulting">
                    <i className="fa fa-angle-double-right" />
                    Testing Consulting
                  </a>
                </li>
                <li>
                  <a href="/Consulting/CloudConsulting">
                    <i className="fa fa-angle-double-right" />
                    Cloud Consulting
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Projectlink;
