import React from "react";
// import Navbares from "./Component/Nev";
import First from "../../../../images/indeximg/digital-market/social-maedia/garrented-satisfaction.png";
import First1 from "../../../../images/indeximg/digital-market/social-maedia/cost-effective.png";
import First2 from "../../../../images/indeximg/digital-market/social-maedia/committed-team.png";
// import First3 from "../../../../images/indeximg/digital-market/social-maedia/competitive-price.png";
// import First4 from "../../../../images/indeximg/digital-market/social-maedia/timeframe.png";
// import First5 from "../../../../images/indeximg/digital-market/social-maedia/privacy-policy.png";

function Expand() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Why Choose BTG Technologies?</h3>
                  {/* <p>
                    With Android being one of the most popular Software, you can
                    easily diversify your revenues by using it in your marketing
                    and finances to be more competitive. Our diverse team of
                    engineers and developers can scale your business to more
                    global audiences. Contact us now!
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>100% Customer Satisfaction</h3>
                  <p>
                    Well-versed with years of experience, we ensure 100% work
                    satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First1}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Cost-effectiveness</h3>
                  <p>
                    We will work with you to create a custom plan that fits your
                    needs and budget.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div className="Skilled-main">
                <div className="Skilled-img">
                  <img
                    src={First2}
                    className="img-fluid "
                    alt="Android"
                    // style={{ paddingTop: 100, paddingBottom: 100 }}
                  />
                </div>
                <div className="Skilled-heading">
                  <h3>Dedicated Team</h3>
                  <p>
                    We are committed to delivering quality and on-time work to
                    our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Expand;
