import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/digital-market/email/Emailmarketing-Design01.png";
import Android2 from "../../../../images/indeximg/digital-market/email/Emailmarketing-Design05.png";

import Android3 from "../../../../images/indeximg/digital-market/email/Emailmarketing-Design03.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec email">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>A Digital Marketing Powerhouse</h3>
                  <p>
                    Email marketing is a direct and great way to connect with
                    potential customers and promote your brand. By using
                    automatic and targeted email, you can approach potential
                    audiences, which can help to increase organic traffic and
                    conversions, leading to more revenue for your business.
                    According to a survey, 99% of people check their emails
                    regularly and prefer to buy products marketed through this
                    medium which shows the worth of email marketing.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Why Choose Email Marketing for your Business?</h3>
                  <p>
                    Email Marketing is an affordable way to reach a massive
                    audience more swiftly and cost-effectively. If you want to
                    earn the trust and credibility of your audience, email is
                    worth considering. Additionally, email marketing can be used
                    to support new products or services, update customers on
                    changes or news, and much more. This type of communication
                    can also help you generate new leads and promote sales; in
                    fact, 72% of small businesses who use email Marketing report
                    seeing a significant increase in revenue compared to those
                    who don't use it!
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Stop Losing Customers to your Competitors</h3>
                  <p>
                    Stop Losing Customers to your Competitors Too often,
                    businesses lose customers to their competitors because
                    they're not using email effectively. But it's time to step
                    up your game and use email marketing to its full potential.
                    By targeting your audience, personalizing your messages, and
                    using powerful automation tools, you can start winning back
                    the customers you've been losing to your competitors.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
