import React from "react";
// import Navbares from "./Component/Nev";
import MainBanner from "../../images/aboutimg/howwework.png";
import First from "../../images/aboutimg/1.png";
import Second from "../../images/aboutimg/2.png";

import "./about.css";
function Howwework() {
  return (
    <>
      <section className="howwe-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12">
              <div className="top-bar-border"></div>
              <div className="howmain-head">
                <h3>How We Work?</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="mainbanner-img d-flex justify-content-center d-xl-block">
                <img src={MainBanner} alt="MainBanner" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="howwork-para">
                <div className="howworkpara">
                  <p>
                    BTG has developed culture of research and development to
                    understand client needs and help product development with
                    latest technological tools. We develop product through the
                    process of Agile development and design thinking.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2 col-sm-2 col-md-2  col-lg-2 col-xl-2">
                  <div className="howworkfirstimg">
                    <img src={First} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-10 col-sm-10 col-md-10  col-lg-10 col-xl-10">
                  <div className="howwork-para">
                    <div className="howworkpara">
                      <p>
                        Before handing over the product to customer we process
                        the product through our own stringent test criteria of
                        Quality Assurance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-2 col-sm-2 col-md-2  col-lg-2 col-xl-2">
                  <div className="howworksecimg">
                    <img src={Second} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-10 col-sm-10 col-md-10  col-lg-10 col-xl-10">
                  <div className="howwork-para">
                    <div className="howworkpara">
                      <p>
                        Before handing over the product to customer we process
                        the product through our own stringent test criteria of
                        Quality Assurance. These processes culminates into pre
                        launch and post launch Quality Assurance Cycle.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Howwework;
