import React from "react";
import Navbares from "../../../Component/Nev";
import "../../../Blog.css";
import "../../../App.css";

import Footer from "../../../Component/Footer";
import MainBanner from "../../../Component/Services/Game-Development/CasualGames/Main-banner";
import QuickSolution from "../../../Component/Services/Game-Development/CasualGames/Quicksolution";
import Fintechapp from "../../../Component/Services/Game-Development/CasualGames/Fintech-App";
// import Choose from "../../../Component/Services/Game-Development/CasualGames/Choose";
// import Getintech from "../../../Component/Services/Game-Development/CasualGames/GetIntech";
import Getintech from "../../../Component/LetsBusiness";
import Document from "../../../Document";
function CasualGames() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <MainBanner />
        <QuickSolution />
        <Fintechapp />
        {/* <Choose /> */}
        <Getintech info={"Casual Games"} />

        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Casual Games"} />

    </>
  );
}

export default CasualGames;
