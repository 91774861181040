import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>What we are Expert in</h3>
                  <p>
                    Our social media marketing services include creating and
                    managing social media accounts, creating content, running
                    ads, and analyzing results.
                  </p>
                  <ul>
                    <li>Facebook Marketing Campaigns</li>
                    <li>Tracking &Analysis</li>
                  </ul>
                  <ul>
                    <li>Content Creation</li>
                    <li>Advertising & Sponsorship Management</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
