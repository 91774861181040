import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img design-con">
        <div className="left-img design-con">
          <div className="short-line"></div>
          <h2 className="heading-1">Design Consultancy Services</h2>
          <p>
            Get more leads, higher conversion rate, and increased sales by
            partnering with our reliable company. Our ultimate goal is to create
            long-term relationships with our customers by giving them a positive
            experience every time they interact with you
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
