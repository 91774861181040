import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img realstat">
        <div className="left-img realstat">
          <div className="short-line"></div>
          <h2 className="heading-1">Real Estate App Development</h2>
          <p>
            A well-developed real estate app can significantly boost your
            business, giving you an edge over the competition. We provide
            companies and individuals with the necessary tools and resources to
            help them increase in profitability.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
