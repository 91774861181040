import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Competent Swift App Development Services</h3>
                  <p>
                    As a top-emerging and reliable company, we have years of
                    experience in creating custom robust, and high-end apps for
                    our clients. We pride ourselves on providing tailored
                    service and delivering exceptional results.
                  </p>
                  <ul>
                    <li>Custom Swift App Development</li>
                    <li>Swift App Optimization</li>
                    <li>Swift UI Design</li>
                    <li>App Migration to Swift</li>
                  </ul>
                  <ul>
                    <li>Support & Maintenance</li>
                    <li>Swift Consultation</li>
                    <li>Testing & Quality Assurance</li>
                  </ul>
                  <ul>
                    <li>On-demand Apps Development</li>
                    <li>Android Apps Optimization & QA</li>
                    {/* <li>Cross-platform Android App Development</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
