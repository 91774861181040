import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img mainten">
        <div className="left-img mainten">
          <div className="short-line" style={{ backgroundColor: "#000" }}></div>
          <h2 className="heading-1 big">Maintenance & Support Solutions</h2>
          <p className="big">
            Get a custom app for your business that will save you time, connect
            you with more clients and enhance your brand image. We are a team of
            professional and reliable developers keen to provide our clients
            with high-end, cost-effective, and functional Android apps that
            enable them to effectively compete in the marketplace.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
