import React from "react";
import Navbares from "../Component/Nev";
import "../Blog.css";
import "../App.css";

import Pricing from "../Component/About/boket";
import Wearereally from "../Component/About/Wearereally";
import Getintech from "../Component/LetsBusiness";
import Footer from "../Component/Footer";
import Document from "../Document";

function Contact() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <Pricing />
        <Wearereally />
        <Getintech info={"Contact Us"} />

        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Contact Us"} />
    </>
  );
}

export default Contact;
