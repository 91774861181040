import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/web-dev/Angular/angular-design01.png";
import Android2 from "../../../../images/indeximg/web-dev/Angular/angular-design02.png";
import First from "../../../../images/indeximg/web-dev/Angular/1.png";
import First1 from "../../../../images/indeximg/web-dev/Angular/2.png";
import First2 from "../../../../images/indeximg/web-dev/Angular/3.png";
import First3 from "../../../../images/indeximg/web-dev/Angular/4.png";

import Android3 from "../../../../images/indeximg/web-dev/Angular/angular-design03.png";
// import First4 from "../../../../images/indeximg/web-dev/Angular/userfriendly-app.png";
// import First5 from "../../../../images/indeximg/web-dev/Angular/visually-appealing.png";
// import First6 from "../../../../images/indeximg/web-dev/Angular/esay-navigation.png";
// import First7 from "../../../../images/indeximg/web-dev/Angular/customize-app.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec ang">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">
                    Let Us Help Your Business Take the Leap
                  </h3>
                  <p className="big">
                    At BTG, we are committed to helping our clients build the
                    most efficient and effective web applications possible. We
                    will work with you to create a plan for your application,
                    develop the necessary code and integration, and test the
                    application for functionality and security. With our team of
                    highly-skilled and experienced developers, you can be sure
                    that you are getting the best possible product for your
                    business.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">
                    Unlock Profitability for your Business with Angular
                    Development
                  </h3>
                  <p className="big">
                    Angular is a platform that makes it easy to build
                    applications for the web and mobile. It combines declarative
                    templates, dependency injection, end-to-end tooling, and
                    integrated best practices to solve development challenges.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3 className="big">
                      {" "}
                      <img
                        src={First}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span className="meansas"> Free Consultation</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3 className="big">
                      {" "}
                      <img
                        src={First1}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span className="meansas"> Customized Solutions</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3 className="big">
                      {" "}
                      <img
                        src={First2}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span className="meansas"> NDA Agreement</span>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="digital-images">
                    <h3 className="big">
                      {" "}
                      <img
                        src={First3}
                        className="img-fluid "
                        alt="Android"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      />
                      <span className="meansas">Technical Approach</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div
                  className="digital-high"
                  style={{ backgroundColor: "#000" }}
                ></div>
                <div className="digital-heading">
                  <h3 className="big">
                    Tired of Conflicts and Security Flaws? Migrate to Angular
                  </h3>
                  <p className="big">
                    We help businesses migrate their current apps to Angular
                    platforms so they can get the best out of their investments.
                    The high-tech features, tools, and technologies of Angular
                    help businesses advance toward success. Our team of experts
                    will work with you to ensure a seamless transition of codes
                    and frameworks while ensuring that your business experiences
                    minimal disruption during the process.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
