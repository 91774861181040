import React from "react";
import Automation from "../../../../images/indeximg/E-commerce/Woo-commerce/effective-designing.png";
import Secure from "../../../../images/indeximg/E-commerce/Woo-commerce/seo-friendly.png";
import cost from "../../../../images/indeximg/E-commerce/Woo-commerce/enrich-designfeature.png";
import greate from "../../../../images/indeximg/E-commerce/Woo-commerce/payment-gateway.png";
import icon from "../../../../images/solutions/Icon1.png";

function QuickSolution() {
  return (
    <>
      <section className="QuickSolution-sec">
        <div className="Quick-maas">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="Quick-head">
                  <h3>
                    Custom Bespoke Solutions for your{" "}
                    <strong>WooCommerce</strong> Development Needs!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quick-fir">
          <div className="container">
            <div className="row quick-sec">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={Automation}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Custom Functionality & Effective Designing</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={Secure}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Easy Integration & SEO Optimized</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={cost}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Feature enrich & Customized</h3>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div className="Quick-swift">
                  <div className="quick-swift-img">
                    <img
                      src={greate}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                    <h3>Secure Payment Gateways</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-5 pb-5">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>
                      WooCommerce Custom<br></br> Development
                    </h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      We at BTG Technologies are experts in developing custom
                      eCommerce apps for businesses. From feature customization
                      to optimal performance, we can help you stay ahead.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main sec1">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>
                      WooCommerce Themes & <br></br> Plugins Development
                    </h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      Our team is proficient in PHP, HTML, CSS, and JavaScript
                      and can develop innovative themes and plugins to make your
                      website fully functional, responsive, and appealing.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="Finance-main sec2">
                  <div className="finance-sec">
                    <img
                      src={icon}
                      className="img-fluid"
                      alt="E-Commerce Development"
                    />
                  </div>
                  <div className="finance-head">
                    <h3>
                      PSD to WooCommerce <br></br> Migration
                    </h3>
                  </div>
                  <div className="finance-para">
                    <p>
                      Are you planning to move your website from PSD to
                      WooCommerce?Enjoy a safe and hassle-free migration to
                      WooCOmmerce with world-class WooComerce experts at BTG
                      Technologies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default QuickSolution;
