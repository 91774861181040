import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>
                    Choose our Exceptional BigCommerce Development Services
                  </h3>
                  <p>
                    If you're looking for help with developing your BigCommerce
                    store with a unique and intuitive interface, you've come to
                    the right place. Our team of experts can help you with
                    everything from customizing your store's design to adding
                    features and support.
                  </p>
                  <ul>
                    <li>BigCommerce Design & Development Services</li>
                    <li>BigCommerce Customization</li>
                    <li>BigCommerce Integration</li>
                  </ul>
                  <ul>
                    <li>BigCommerce Migration</li>
                    <li>BigCommerce Maintenance & Support</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
