import React from "react";
import Carousel from "react-bootstrap/Carousel";
import metaverse from "../images/indeximg/metaverse-header.png";
import nft from "../images/indeximg/NFT-header.png";
import mobile from "../images/indeximg/mobile-app.png";
import webd from "../images/indeximg/webd-evelopment.png";
import ecommerce from "../images/indeximg/Ecommerce-design02.png";
import game from "../images/indeximg/game-development.png";
import blockchain from "../images/indeximg/blockchain-header.png";
import uiux from "../images/indeximg/uiux-header01.png";
import digital from "../images/indeximg/digital-marketing.png";
import headerDisign from "../images/ai/header-design.png";

function CarouselFadeExample() {
  return (
    <Carousel fade>
      <Carousel.Item>
        <section className="slid0">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                {/* <div className="subtitle" style={{ }}>
                        Our Services
                      </div> */}
                <h2 className="slidehead" style={{}}>
                  {" "}
                  Artificial Intelligence
                </h2>
                <p
                  className="slidepara"
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  Experience a new era of business with our AI-driven solutions. Our company leverages advanced artificial intelligence to provide data-driven insights, streamline operations, and enhance customer engagement. From automation to cybersecurity, we're your trusted partner in unlocking growth, efficiency, and innovation through AI.
                </p>
                {/*<div className="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masas pr-50  mt-auto mb-auto">
                <img
                  src={headerDisign}
                  className="img-fluid vc_hidden-sm vc_hidden-lg  vc_hidden-md"
                  alt="Artificial Intelligence"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>
      </Carousel.Item>

      <Carousel.Item>
        <section className="slid3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                {/* <div className="subtitle" style={{ }}>
                        Our Services
                      </div> */}
                <h2 className="slidehead" style={{}}>
                  {" "}
                  Mobile App Development Company
                </h2>
                <p
                  className="slidepara"
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  Get ready to elevate your business with our cutting-edge
                  mobile app development services! From flawless and fascinating
                  designs to superior functionality, we craft your apps with
                  state-of-the-art expertise. Whether streamlining your
                  business, engaging your customers, or increasing sales, our
                  tailored solutions can empower you to reach your goals. Call
                  us today!
                </p>
                {/*<div className="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                <img
                  src={mobile}
                  className="img-fluid vc_hidden-sm vc_hidden-lg  vc_hidden-md"
                  alt="E-Commerce Development"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>
      </Carousel.Item>

      <Carousel.Item>
        <section className="slid7">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                {/* <div className="subtitle" style={{ }}>
                        Our Services
                      </div> */}
                <h2 className="slidehead" style={{}}>
                  {" "}
                  Blockchain Development Company
                </h2>
                <p
                  className="slidepara"
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  Blockchain - a dependable business infrastructure? 99.999%!
                  Hit the market with a secure, decentralized, and tamper-proof
                  system that ensures your business success. From smart
                  contracts to decentralized finance (Defi), we can design and
                  develop customized blockchains. Hire us now to maximize your
                  business efficiency with this powerful digital catalyst!
                </p>
                {/*<div className="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                <img
                  src={blockchain}
                  className="img-fluid vc_hidden-sm vc_hidden-lg  vc_hidden-md"
                  alt="E-Commerce Development"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>
      </Carousel.Item>

      <Carousel.Item>
        <section className="slid4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                {/* <div className="subtitle" style={{ }}>
                        Our Services
                      </div> */}
                <h2 className="slidehead" style={{}}>
                  {" "}
                  Web Development Company
                </h2>
                <p
                  className="slidepara"
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  Experience the premiere and world's best web app development
                  services by joining hands with our expert team at BTG
                  Technologies! We have the skills to bring your vision into
                  reality by creating websites based on complex technologies
                  such as PHP, Angular, Python, and others. It's time to
                  revolutionize how the world perceives and interacts with your
                  business. Let's accomplish your goals together. Call us now!
                </p>
                {/*<div className="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                <img
                  src={webd}
                  className="img-fluid vc_hidden-sm vc_hidden-lg  vc_hidden-md"
                  alt="E-Commerce Development"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>
      </Carousel.Item>

      <Carousel.Item>
        <section className="slid5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                {/* <div className="subtitle" style={{ }}>
                        Our Services
                      </div> */}
                <h2 className="slidehead" style={{}}>
                  {" "}
                  Ecommerce Development Company
                </h2>
                <p
                  className="slidepara"
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  Accelerate your eCommerce business by unleashing the power of
                  online marketing! Leverage our full suite of eCommerce
                  services, including but not limited to development strategies,
                  migration, integration, and support services, to get an
                  inspiring and scalable platform. Call us now to get the best
                  Shopify, Magento, Woocommerce, or Big Commerce storefront!
                </p>
                {/*<div className="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                <img
                  src={ecommerce}
                  className="img-fluid vc_hidden-sm vc_hidden-lg  vc_hidden-md"
                  alt="E-Commerce Development"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>
      </Carousel.Item>

      <Carousel.Item>
        <section className="slid2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                {/* <div className="subtitle" style={{ }}>
                        Our Services
                      </div> */}
                <h2 className="slidehead" style={{}}>
                  {" "}
                  NFT Development Company
                </h2>
                <p
                  className="slidepara"
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  Get ahead of the game and unlock the potential of NFTs for
                  your business! Let our team help you tokenize your products
                  and services and revolutionize your business's operations.
                  From virtual estate to collectibles, dust off your digital
                  assets and start reaping the benefits today with our
                  state-of-the-art services!
                </p>
                {/*<div className="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                <img
                  src={nft}
                  className="img-fluid vc_hidden-sm vc_hidden-lg  vc_hidden-md"
                  alt="E-Commerce Development"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>
      </Carousel.Item>

      <Carousel.Item>
        <section className="slid6">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                {/* <div className="subtitle" style={{ }}>
                        Our Services
                      </div> */}
                <h2 className="slidehead" style={{}}>
                  {" "}
                  Game Development Company
                </h2>
                <p
                  className="slidepara"
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  No one knows game development better than the expert
                  professionals at BTG Technologies! With our extensive
                  experience, we can turn your wild gaming idea into an
                  addictive reality that customers will come back for more. From
                  unique concept creation to creating mesmerizing art assets,
                  our team can optimize your games with promising results. So,
                  get ready to experience the craziest game of the decade!
                </p>
                {/*<div className="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                <img
                  src={game}
                  className="img-fluid vc_hidden-sm vc_hidden-lg  vc_hidden-md"
                  alt="E-Commerce Development"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>
      </Carousel.Item>

      <Carousel.Item>
        <section className="slid1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                {/* <div className="subtitle" style={{ }}>
                        Our Services
                      </div> */}
                <h2 className="slidehead" style={{}}>
                  {" "}
                  Metaverse Development Company
                </h2>
                <p
                  className="slidepara"
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  Welcome to the Metaverse – a world beyond imagination! Our
                  advanced technology and superficial knowledge can open doors
                  to a virtual world where you can acquire endless opportunities
                  to expand your business and engage your customers with new
                  adventures. Whether you need a virtual storefront, a 3d
                  immersive game, or a digital conference, join us now to level
                  up the game!
                </p>
                {/*<div className="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 masas pr-50  mt-auto mb-auto">
                <img
                  src={metaverse}
                  className="img-fluid vc_hidden-sm vc_hidden-lg  vc_hidden-md"
                  alt="E-Commerce Development"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>
      </Carousel.Item>

      <Carousel.Item>
        <section className="slid8">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                {/* <div className="subtitle" style={{ }}>
                        Our Services
                      </div> */}
                <h2 className="slidehead" style={{}}>
                  {" "}
                  UI/ UX Designing Company
                </h2>
                <p
                  className="slidepara"
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  Revamp your apps and websites to get visually appealing and
                  customer-centric UI/UX designs that can strengthen your brand.
                  Enhance user experience and customer base with our intuitive
                  and easy-to-navigate designs and 3d animations. The design
                  speaks for itself and reflects the brand value; we make it
                  happen for you! Collaborate with our creative team to give
                  your customers the experience they deserve! Call us now!
                </p>
                {/*<div className="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                <img
                  src={uiux}
                  className="img-fluid vc_hidden-sm vc_hidden-lg  vc_hidden-md"
                  alt="E-Commerce Development"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>
      </Carousel.Item>

      <Carousel.Item>
        <section className="slid9">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                {/* <div className="subtitle" style={{ }}>
                        Our Services
                      </div> */}
                <h2 className="slidehead" style={{}}>
                  {" "}
                  Digital Marketing Company
                </h2>
                <p
                  className="slidepara"
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  Get more leads, more business, and more profit! Yes! With our
                  creative and result-driven marketing solutions, we can turn
                  your company into a leading brand. From SEO and email
                  marketing to online promotions and lead generation, we have
                  everything in our arsenal you need to get the most out of your
                  online presence. Let us help you in creating a success story!
                </p>
                {/*<div className="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto">
                <img
                  src={digital}
                  className="img-fluid vc_hidden-sm vc_hidden-lg  vc_hidden-md"
                  alt="E-Commerce Development"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselFadeExample;
