import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Expertise with AI Data Training</h3>
                  <p>
                    We meticulously curate and prepare data, ensuring your AI models receive the highest quality inputs. Our track record of excellence empowers businesses with more accurate predictions, enhanced performance, and the competitive edge needed to succeed in today's data-driven world.
                  </p>
                  <ul>
                    <li>Supervised Learning</li>
                    <li>Unsupervised Learning</li>
                    <li>Semi-Supervised Learning</li>
                  </ul>
                  <ul>
                    <li>Self-Supervised Learning</li>
                    <li>Federated Learning</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
