import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our SaaS Development Solutions</h3>
                  <p>
                    We offer a full suite of development and operations
                    services, including application development, deployment,
                    scaling, and performance monitoring.
                  </p>
                  <ul>
                    <li>SaaS Consulting</li>
                    <li>SaaS Prototyping</li>
                    <li>UI/UX SaaS Designing</li>
                  </ul>
                  <ul>
                    <li>App Development & Testing</li>
                    <li>Evolution & Migration</li>
                    <li>SaaS Testing & MaintenanceEvolution & Migration</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
