import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Design Consulting Expertise</h3>
                  <p>
                    At BTG Technologies, we offer a wide range of services, from
                    developing prototype to design UI/UX and visually pleasing
                    graphics. We have years of experience in guiding and helping
                    businesses to have get the best outcome of their products.
                  </p>
                  <ul>
                    <li>Prototyping</li>
                    <li>UI/UX design</li>
                    <li>Visual branding</li>
                    <li>Graphic Designing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
