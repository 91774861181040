import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img google-pay">
        <div className="left-img google-pay">
          <div className="short-line" style={{ backgroundColor: "#000" }}></div>
          <h2 className="heading-1 big">Google Pay per Click Services</h2>
          <p className="big">
            Advertise your business online with Google pay-per-click, an
            effective and popular advertising technique. Using this service, you
            can place ads on Google search results on pages and websites that
            are part of the Google Display Network.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
