import React from "react";
import Navbares from "../../Component/Nev";
import "../../Blog.css";
import "../../App.css";

// import Lettalk from "../../Component/Let’stalk";
import MainBanner from "../../Component/Consulting/DevOpsConsulting/Main-banner";
import QuickSolution from "../../Component/Consulting/DevOpsConsulting/Quicksolution";
import Fintechapp from "../../Component/Consulting/DevOpsConsulting/Fintech-App";
// import Choose from "../../Component/Consulting/DevOpsConsulting/Choose";
// import Getintech from "../../Component/Consulting/DevOpsConsulting/GetIntech";
import Getintech from "../../Component/LetsBusiness";
import Footer from "../../Component/Footer";
import Document from "../../Document";
function DevOpsConsulting() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <MainBanner />
        <QuickSolution />
        <Fintechapp />
        {/* <Choose /> */}
        <Getintech info={"Dev Ops Consulting"} />

        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Dev Ops Consulting"} />

    </>
  );
}

export default DevOpsConsulting;
