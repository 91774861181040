import React from "react";
// import Navbares from "./Component/Nev";
import f1 from "../images/indeximg/icon/retail-Ecommerce.svg";
import f2 from "../images/indeximg/icon/Education-e-learning.svg";
import f3 from "../images/indeximg/icon/Healthcare&Fitness.svg";
import f4 from "../images/indeximg/icon/Social-Networking.svg";
function WhoWork() {
  return (
    <>
      <section className="who-work-with">
        <div className="container workflow">
          <div className="row">
            <div className="col-12 col-md-6 col-sm-12 col-xl-6 col-lg-6 aswsa">
              <h2 style={{ marginLeft: 0, fontSize: "44px!important" }}>
                We Work With
              </h2>
              <p
                style={
                  {
                    //   fontSize: "29px !important",
                    //   padding: 28,
                    //   lineHeight: 45,
                  }
                }
              >
                As a leading software development company in Pakistan, we worked
                with multipal businesses either it is a start-up or enterprise
                and delivers the best solution in the industry. At BRIDGE THE
                GAP (BTG) Technologies, we offer a broad range of solutions and
                services based on business requirements.
              </p>
            </div>
            <div className="col-12 col-md-6 col-sm-12 col-xl-6 col-lg-6 masasa">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6">
                  <div className="work-box text-center">
                    <img
                      onload="Wpfcll.r(this,true);"
                      src={f1}
                      // itemProp="image"
                      // data-wpfc-original-src="../Pictures/Footer-icon/Startup-01.svg"
                      alt="blank"
                    />
                    <h3>
                      Start Up <br /> Business
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6">
                  <div className="work-box text-center">
                    <img
                      onload="Wpfcll.r(this,true);"
                      src={f2}
                      // itemProp="image"
                      // data-wpfc-original-src="../Pictures/Footer-icon/smallmedium-business-01.svg"
                      alt="blank"
                    />
                    <h3>
                      Small &amp; Medium <br />
                      Business
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6">
                  <div className="work-box text-center">
                    <img
                      onload="Wpfcll.r(this,true);"
                      src={f3}
                      // itemProp="image"
                      // data-wpfc-original-src="../Pictures/Footer-icon/Enterprise-01.svg"
                      alt="blank"
                    />
                    <h3>
                      Enterprise
                      <br />
                      &nbsp;
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6">
                  <div className="work-box text-center">
                    <img
                      onload="Wpfcll.r(this,true);"
                      src={f4}
                      // itemProp="image"
                      // data-wpfc-original-src="../Pictures/Footer-icon/Agencies-01.svg"
                      alt="blank"
                    />
                    <h3>
                      Agencies
                      <br />
                      &nbsp;
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default WhoWork;
