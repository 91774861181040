import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img marketan">
        <div className="left-img marketan">
          <div className="short-line"></div>
          <h2 className="heading-1">Marketing Analytics Services</h2>
          <p>
            Accelerate your business growth and success by partnering with us!
            We are a reliable digital marketing company that can help you to
            monitor and analyze customer behavior to optimize your workflow and
            generate more revenues.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
