import React from "react";
// import Navbares from "./Component/Nev";
// import Android1 from "../../../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/indeximg/game/unreal/unrealengine_design01.png";
import First from "../../../../images/indeximg/game/unreal/1.png";
import First1 from "../../../../images/indeximg/game/unreal/2.png";

import fintech_design05 from "../../../../images/indeximg/game/unreal/unrealengine_design02.png";
import First4 from "../../../../images/indeximg/game/unreal/3.png";
import First5 from "../../../../images/indeximg/game/unreal/4.png";
import fintech_design04 from "../../../../images/indeximg/game/unreal/unrealengine_design03.png";
import First7 from "../../../../images/indeximg/game/unreal/5.png";
import First8 from "../../../../images/indeximg/game/unreal/6.png";

function Fintechapp() {
  return (
    <>
      <section className="Fintech-sec unreal">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>What is Unreal Game Engine?</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Unreal is a powerful game engine that creates high-quality
                    games across multiple platforms.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Unreal Engine 4 is equipped with advanced features and
                          resources by coordinating with technologies such as
                          Unreal Script, C++, and Blue Prints.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First1}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Unreal Engine 4, the latest version of the engine,
                          includes deferred rendering, new lighting models, and
                          improved tools for creating assets and materials.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Top Choice of the World’s Leading Games</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Unreal Engine 4 is used by many AAA game developers,
                    including Epic Games, Ubisoft, and EA.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First4}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          The engine has created some of the most popular games
                          in recent years, such as Fortnite, Gears of War, and
                          Borderlands.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First5}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Unreal offers a high degree of customization and
                          flexibility, making it ideal for complex game
                          development.s
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design05}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Incredible Visuals and Stunning Graphics</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design04}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    We utilize Unreal Engine’s built-in visual tools, elements,
                    and simulations to develop innovative games that can make a
                    big hit in the gaming industry.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First7}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          The Unreal engine can create amazing visual effects
                          and environments. It can also render incredible
                          gameplay, graphics, sceneries, and characters.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First8}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          It also supports high-end features such as HDR
                          lighting, physically based rendering, and real-time
                          ray tracing.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Fintechapp;
