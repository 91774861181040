import React from "react";
// import { Link } from "react-router-dom";
// AI app svg
import AI from "../images/menu-icon/ai/ai.svg";
import AI1 from "../images/menu-icon/ai-01/ai-01.svg";
import AI2 from "../images/menu-icon/ai-02/ai-02.svg";
import AI3 from "../images/menu-icon/ai-03/ai-03.svg";

// mobile app svg
import Mobileapp from "../images/indeximg/mobile-app-development.svg";
import M1 from "../images/indeximg/packageicon/android-icon.png";
import M2 from "../images/indeximg/packageicon/kotlin.png";
import M3 from "../images/indeximg/packageicon/flutter-icon.png";
import M4 from "../images/indeximg/packageicon/iphone.png";
import M5 from "../images/indeximg/packageicon/ionicframework-icon.png";
import M6 from "../images/indeximg/packageicon/xamarin.png";
import M7 from "../images/indeximg/packageicon/hybrid-icon.png";
import M8 from "../images/indeximg/packageicon/swift.png";
import M9 from "../images/indeximg/packageicon/react.png";

// web development svg
import WebDevelope from "../images/indeximg/website-development.svg";
import W1 from "../images/indeximg/packageicon/net.png";
import W2 from "../images/indeximg/packageicon/typescript.png";
import W3 from "../images/indeximg/packageicon/python.png";
import W4 from "../images/indeximg/packageicon/php.png";
import W5 from "../images/indeximg/packageicon/laravel.png";
import W6 from "../images/indeximg/packageicon/wordpress.png";
import W7 from "../images/indeximg/packageicon/angularjs.png";

// uiux developement svg

import UIUX from "../images/indeximg/UIUX-Development.svg";
import U1 from "../images/indeximg/packageicon/UI Animated.png";
import U2 from "../images/indeximg/packageicon/product design.png";
import U3 from "../images/indeximg/packageicon/Testing.png";
import U4 from "../images/indeximg/packageicon/UX Resarch.png";
import U5 from "../images/indeximg/packageicon/product design.png";

// Ecommerce Development svg

import Ecommercedeve from "../images/indeximg/Ecommerce-Development.svg";
import Ec1 from "../images/indeximg/packageicon/shopify.png";
import Ec2 from "../images/indeximg/packageicon/magento.png";
import Ec3 from "../images/indeximg/packageicon/bigcommerce.png";
import Ec4 from "../images/indeximg/packageicon/woocommerce.png";

// Game Development svg

import Game from "../images/indeximg/Game-Development.svg";
import Unites from "../images/indeximg/unityd.svg";
import Ga1 from "../images/indeximg/packageicon/casual-game.png";
import Ga2 from "../images/indeximg/packageicon/ar.png";
import Ga3 from "../images/indeximg/packageicon/metaverse.png";
import Ga4 from "../images/indeximg/packageicon/VR-logo.png";
import Ga5 from "../images/indeximg/packageicon/unreal-engine.png";

// Blockchan development svg

import Blockchan from "../images/indeximg/Blockchain-development.svg";
import BD1 from "../images/indeximg/packageicon/wallet.png";
import BD2 from "../images/indeximg/packageicon/private blockchain.png";
import BD3 from "../images/indeximg/packageicon/Smart Contract.png";
import BD4 from "../images/indeximg/packageicon/Exchange.png";
import BD5 from "../images/indeximg/packageicon/nft.png";
import BD6 from "../images/indeximg/packageicon/ethereum.png";
import BD7 from "../images/indeximg/packageicon/hyperledger.png";

// Digital marketing svg
import Digital from "../images/indeximg/Digital-marketing.svg";
import DM1 from "../images/indeximg/packageicon/seo.png";
import DM2 from "../images/indeximg/packageicon/ppc.png";
import DM3 from "../images/indeximg/packageicon/Social Media Marketing.png";
import DM4 from "../images/indeximg/packageicon/Email marketing.png";
import DM5 from "../images/indeximg/packageicon/Mobile marketing.png";
import DM6 from "../images/indeximg/packageicon/Content marketing.png";
import DM7 from "../images/indeximg/packageicon/marketing analytics.png";
import DM8 from "../images/indeximg/packageicon/affiliate marketing.png";

// Solution svg

import Solution from "../images/indeximg/packageicon/solution.png";
import S1 from "../images/indeximg/packageicon/fintech.png";
import S2 from "../images/indeximg/packageicon/On Demand App.png";
import S3 from "../images/indeximg/packageicon/Saas.png";
import S4 from "../images/indeximg/packageicon/Real Estate App.png";
import S5 from "../images/indeximg/packageicon/Intergration & Migration.png";
import S6 from "../images/indeximg/packageicon/maintainence-support.png";

// Consultants svg
import Consultantes from "../images/indeximg/packageicon/consultants.png";
import C1 from "../images/indeximg/packageicon/Mobile Consulting.png";
import C2 from "../images/indeximg/packageicon/Web-App-Consulting-01.png";
import C3 from "../images/indeximg/packageicon/Devops.png";
import C4 from "../images/indeximg/packageicon/Design Consultant.png";
import C5 from "../images/indeximg/packageicon/Testing Consulting.png";
import C6 from "../images/indeximg/packageicon/Cloud Consulting.png";

// import Navbares from "./Component/Nev";
function Package() {
  return (
    <>
      <section className="Package-sec mt-5">
        <div className="container-fluid">
          <div className="row pt-5 pb-5">
            <div className="section-head col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 ">
              <h4>
                <span>Why Choose</span> BTG?
              </h4>
              <p>
                We have extensive experience providing consulting and different
                services and solutions to many companies. We bring our clients
                insights and recommendations regarding best practices for IT and
                business management.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-xl-4 col-md-6 mt-4">
              <div className="item-SB">
                <span className="icon feature_box_col_one">
                  {" "}
                  <a href="">
                    <img
                      xlinkHref="../images/indeximg/mobile-app-development.svg"
                      src={AI}
                      width={100}
                      height={100}
                      alt="mobile-app-development"
                    />
                  </a>
                </span>
                <h6>Artificial Intelligence Development</h6>
                {/*<p>
                      <a href="#">
                          <svg width="32" height="32" class="icon-margin">
                              <img xlink:href="icon/android/android-icon.svg" src="icon/android/android-icon.svg" width="32" height="32"></img>
                          Android App
                      </a>
                  </p>*/}
                <p>
                  <a href="/Mobile-App-Development/AndroidApp">
                    <img
                      xlinkHref="../images/indeximg/android-icon.svg"
                      src={AI1}
                      width={32}
                      height={32}
                      alt="m1"
                    />
                    Chatbot
                  </a>
                </p>
                <p>
                  <a href="/Mobile-App-Development/Kotlin">
                    <img src={AI2} width={32} height={32} alt="m2" />
                    Prompt Engineering
                  </a>
                </p>
                <p>
                  <a href="/Mobile-App-Development/Flutter">
                    <img
                      xlinkHref="icon/flutter/flutter-icon.svg"
                      src={AI3}
                      width={32}
                      height={32}
                      alt="m3"
                    />
                    Data Training
                  </a>
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xl-4 col-md-6 mt-4">
              <div className="item-SB">
                <span className="icon feature_box_col_one">
                  {" "}
                  <a href="">
                    <img
                      xlinkHref="../images/indeximg/mobile-app-development.svg"
                      src={Mobileapp}
                      width={100}
                      height={100}
                      alt="mobile-app-development"
                    />
                  </a>
                </span>
                <h6>Mobile App Development</h6>
                {/*<p>
                      <a href="#">
                          <svg width="32" height="32" class="icon-margin">
                              <img xlink:href="icon/android/android-icon.svg" src="icon/android/android-icon.svg" width="32" height="32"></img>
                          Android App
                      </a>
                  </p>*/}
                <p>
                  <a href="/Mobile-App-Development/AndroidApp">
                    <img
                      xlinkHref="../images/indeximg/android-icon.svg"
                      src={M1}
                      width={32}
                      height={32}
                      alt="m1"
                    />
                    Android App
                  </a>
                </p>
                <p>
                  <a href="/Mobile-App-Development/Kotlin">
                    <img src={M2} width={32} height={32} alt="m2" />
                    Kotlin
                  </a>
                </p>
                <p>
                  <a href="/Mobile-App-Development/Flutter">
                    <img
                      xlinkHref="icon/flutter/flutter-icon.svg"
                      src={M3}
                      width={32}
                      height={32}
                      alt="m3"
                    />
                    Flutter
                  </a>
                </p>
                <p>
                  <a href="/Mobile-App-Development/IphoneApp">
                    <img
                      xlinkHref="icon/iphone/iphone.svg"
                      src={M4}
                      width={32}
                      height={32}
                      alt="m4"
                    />
                    iPhone App
                  </a>
                </p>
                <p>
                  <a href="/Mobile-App-Development/Ionic">
                    <img
                      xlinkHref="icon/Ionic/ionicframework-icon.svg"
                      src={M5}
                      width={32}
                      height={32}
                      alt="m5"
                    />
                    Ionic
                  </a>
                </p>
                <p>
                  <a href="/Mobile-App-Development/Xamarin">
                    <img
                      xlinkHref="icon/xamarin/xamarin.svg"
                      src={M6}
                      width={32}
                      height={32}
                      alt="m6"
                    />
                    Xamarin
                  </a>
                </p>
                <p>
                  <a href="/Mobile-App-Development/HybridApp">
                    <img
                      xlinkHref="icon/hybrid/hybrid-icon.svg"
                      src={M7}
                      width={32}
                      height={32}
                      alt="m7"
                    />
                    Hybrid App
                  </a>
                </p>
                <p>
                  <a href="/Mobile-App-Development/Swift">
                    <img
                      xlinkHref="icon/swift/swift.svg"
                      src={M8}
                      width={32}
                      height={32}
                      alt="m8"
                    />
                    Swift
                  </a>
                </p>
                <p>
                  <a href="/Mobile-App-Development/ReactNative">
                    <img
                      xlinkHref="icon/react/react.svg"
                      src={M9}
                      width={32}
                      height={32}
                      alt="m9"
                    />
                    React Native
                  </a>
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xl-4 col-md-6 mt-4">
              <div className="item-SB">
                <span className="icon feature_box_col_six">
                  {" "}
                  <a href="#">
                    <img
                      xlinkHref="icon/Blockchain-development/Blockchain-development.svg"
                      src={Blockchan}
                      width={100}
                      height={100}
                    />
                  </a>
                </span>
                <h6>Blockchain Development</h6>
                <p>
                  <a href="/Blockchain-Development/Wallet">
                    <img
                      xlinkHref="icon/wallet/wallet.svg"
                      src={BD1}
                      width={32}
                      height={32}
                    />
                    Wallet
                  </a>
                </p>
                <p>
                  <a href="/Blockchain-Development/PrivateBlockchain">
                    <img
                      xlinkHref="icon/private blockchain.svg"
                      src={BD2}
                      width={32}
                      height={32}
                    />
                    Private Blockchain
                  </a>
                </p>
                <p>
                  <a href="/Blockchain-Development/SmartContract">
                    <img
                      xlinkHref="icon/smart contrat/Smart Contract.svg"
                      src={BD3}
                      width={32}
                      height={32}
                    />
                    Smart Contract
                  </a>
                </p>
                <p>
                  <a href="/Blockchain-Development/Exchange">
                    <img
                      xlinkHref="icon/exchange/Exchange.svg"
                      src={BD4}
                      width={32}
                      height={32}
                    />
                    Exchange
                  </a>
                </p>
                <p>
                  <a href="/Blockchain-Development/NFTMarketplace">
                    <img
                      xlinkHref="icon/NFT/nft.svg"
                      src={BD5}
                      width={32}
                      height={32}
                    />
                    NFT Marketplace
                  </a>
                </p>
                <p>
                  <a href="/Blockchain-Development/Ethereum">
                    <img
                      xlinkHref="icon/ethereum/ethereum.svg"
                      src={BD6}
                      width={32}
                      height={32}
                    />
                    Ethereum
                  </a>
                </p>
                <p>
                  <a href="/Blockchain-Development/Hyperledger">
                    <img
                      xlinkHref="icon/Hyperleadger/hyperledger.svg"
                      src={BD7}
                      width={32}
                      height={32}
                    />
                    Hyperledger
                  </a>
                </p>
                <p>&nbsp; </p>
                <p>&nbsp; </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xl-4 col-md-6 mt-4">
              <div className="item-SB">
                <span className="icon feature_box_col_two">
                  {" "}
                  <a href="#">
                    <img
                      xlinkHref="../images/indeximg/website-development.svg"
                      src={WebDevelope}
                      width={100}
                      height={100}
                      alt="WebDevelope"
                    />
                  </a>
                </span>
                <h6>Web Development</h6>
                <p>
                  <a href="/Web-Development-services/Asp">
                    <img
                      xlinkHref="icon/Asp.net/.net.svg"
                      src={W1}
                      width={32}
                      height={32}
                      alt="w1"
                    />
                    ASP.NET
                  </a>
                </p>
                <p>
                  <a href="/Web-Development-services/TypeScript">
                    <img
                      xlinkHref="icon/typescript/typescript.svg"
                      src={W2}
                      width={32}
                      height={32}
                      alt="w2"
                    />
                    Type Script
                  </a>
                </p>
                <p>
                  <a href="/Web-Development-services/Python">
                    <img
                      xlinkHref="icon/phython/python.svg"
                      src={W3}
                      width={32}
                      height={32}
                      alt="w3"
                    />
                    Python
                  </a>
                </p>
                <p>
                  <a href="/Web-Development-services/Php">
                    <img
                      xlinkHref="icon/php/php.svg"
                      src={W4}
                      width={32}
                      height={32}
                      alt="w4"
                    />
                    PHP
                  </a>
                </p>
                <p>
                  <a href="/Web-Development-services/Laravel">
                    <img
                      xlinkHref="icon/laravel/laravel.svg"
                      src={W5}
                      width={32}
                      height={32}
                      alt="w5"
                    />
                    Laravel
                  </a>
                </p>
                <p>
                  <a href="/Web-Development-services/Wordpress">
                    <img
                      xlinkHref="icon/wordpress/wordpress.svg"
                      src={W6}
                      width={32}
                      height={32}
                      alt="w6"
                    />
                    Wordpress
                  </a>
                </p>
                <p>
                  <a href="/Web-Development-services/Angular">
                    <img
                      xlinkHref="icon/Angular/angularjs.svg"
                      src={W7}
                      width={32}
                      height={32}
                      alt="w7"
                    />
                    Angular
                  </a>
                </p>
                <p>&nbsp; </p>
                <p>&nbsp; </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xl-4 col-md-6 mt-4">
              <div className="item-SB">
                <span className="icon feature_box_col_four">
                  {" "}
                  <a href="#">
                    <img
                      xlinkHref="icon/EcommerceDevelopment/Ecommerce Development.svg"
                      src={Ecommercedeve}
                      width={100}
                      height={100}
                      alt="Ecommercedeve"
                    />
                  </a>
                </span>
                <h6>Ecommerce Development</h6>
                <p>
                  <a href="/E-Commerce-Development/Shopify">
                    <img
                      xlinkHref="icon/shopify/shopify.svg"
                      src={Ec1}
                      width={32}
                      height={32}
                      alt="ec1"
                    />
                    Shopify
                  </a>
                </p>
                <p>
                  <a href="/E-Commerce-Development/Magento">
                    <img
                      xlinkHref="icon/Magento/magento.svg"
                      src={Ec2}
                      width={32}
                      height={32}
                      alt="ec2"
                    />
                    Magento
                  </a>
                </p>
                <p>
                  <a href="/E-Commerce-Development/BigCommerce">
                    <img
                      xlinkHref="icon/Big commerce/bigcommerce.svg"
                      src={Ec3}
                      width={32}
                      height={32}
                      alt="ec3"
                    />
                    Big Commerce
                  </a>
                </p>
                <p>
                  <a href="/E-Commerce-Development/WooCommerce">
                    <img
                      xlinkHref="icon/woocommerce/woocommerce.svg"
                      src={Ec4}
                      width={32}
                      height={32}
                      alt="ec4"
                    />
                    Woo Commerce
                  </a>
                </p>
                {/* <a href="../ecommerce-website-development/WooCommerce.html">
                  <p>&nbsp; </p>
                  <p>&nbsp; </p>
                  <p>&nbsp; </p>
                  <p>&nbsp; </p>
                  <p>&nbsp; </p>
                </a> */}
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xl-4 col-md-6 mt-4">
              <div className="item-SB">
                <span className="icon feature_box_col_five">
                  <a href="#">
                    <img
                      xlinkHref="icon/GameDevelopment/Game Development.svg"
                      src={Game}
                      width={100}
                      height={100}
                    />
                  </a>
                </span>
                <h6>Game Development</h6>

                <p>
                  <a href="/Game-Development/Unity3d">
                    <img
                      xlinkHref="../images/indeximg/unityd.svg"
                      src={Unites}
                      width={32}
                      height={32}
                    />
                    Unity 3D
                  </a>
                </p>
                <p>
                  <a href="/Game-Development/CasualGames">
                    <img
                      xlinkHref="icon/Causal Game/casual-game.svg"
                      src={Ga1}
                      width={32}
                      height={32}
                    />
                    Casual Games
                  </a>
                </p>
                <p>
                  <a href="/Game-Development/AugementedReality">
                    <img
                      xlinkHref="icon/AR logo/ar.svg"
                      src={Ga2}
                      width={32}
                      height={32}
                    />
                    Augemented Reality
                  </a>
                </p>
                <p>
                  <a href="/Game-Development/Metaverse">
                    <img
                      xlinkHref="icon/metaverse/metaverse.svg"
                      src={Ga3}
                      width={32}
                      height={32}
                    />
                    Metaverse
                  </a>
                </p>
                <p>
                  <a href="/Game-Development/VirtualReality">
                    <img
                      xlinkHref="icon/Virtul Reallity logo/VR-logo.svg"
                      src={Ga4}
                      width={32}
                      height={32}
                    />
                    Virtual Reality
                  </a>
                </p>
                <p>
                  <a href="/Game-Development/UnrealEngine">
                    <img
                      xlinkHref="icon/unrealengine/unreal-engine.svg"
                      src={Ga5}
                      width={32}
                      height={32}
                    />
                    Unreal Engine
                  </a>
                </p>
                <p>&nbsp; </p>
                <p>&nbsp; </p>
                <p>&nbsp; </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xl-4 col-md-6 mt-4">
              <div className="item-SB">
                <span className="icon feature_box_col_three">
                  {" "}
                  <a href="#">
                    <img
                      xlinkHref="icon/UIUX-Development/UIUX-Development.svg"
                      src={UIUX}
                      width={100}
                      height={100}
                      alt="UIUX"
                    />
                  </a>
                </span>
                <h6>UI / UX Designing</h6>
                <p>
                  <a href="/UIUX-Designing/UIAnimation">
                    <img
                      xlinkHref="icon/UI Animated/UI Animated.svg"
                      src={U1}
                      width={32}
                      height={32}
                      alt="u1"
                    />
                    UI &amp; Animation
                  </a>
                </p>
                <p>
                  <a href="/UIUX-Designing/ProductDesign">
                    <img
                      xlinkHref="icon/Product design/product design.svg"
                      src={U2}
                      width={32}
                      height={32}
                      alt="u2"
                    />
                    Product Design
                  </a>
                </p>
                <p>
                  <a href="/UIUX-Designing/DesignTesting">
                    <img
                      xlinkHref="icon/Design Testing/Testing.svg"
                      src={U3}
                      width={32}
                      height={32}
                      alt="u3"
                    />
                    Design Testing
                  </a>
                </p>
                <p>
                  <a href="/UIUX-Designing/UXResearch">
                    <img
                      xlinkHref="icon/UX Resarch/UX Resarch.svg"
                      src={U4}
                      width={32}
                      height={32}
                      alt="u4"
                    />
                    UX Research
                  </a>
                </p>
                <p>
                  <a href="/UIUX-Designing/ProductDesign">
                    <img
                      xlinkHref="icon/Product design/product design.svg"
                      src={U5}
                      width={32}
                      height={32}
                      alt="u5"
                    />
                    Product Design
                  </a>
                </p>
                <p>&nbsp; </p>
                <p>&nbsp; </p>
                <p>&nbsp; </p>
                <p>&nbsp; </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xl-4 col-md-6 mt-4">
              <div className="item-SB">
                <span className="icon feature_box_col_four">
                  {" "}
                  <a href="#">
                    <img
                      xlinkHref="icon/Digitalmarketingdev/Digital marketing.svg"
                      src={Digital}
                      width={100}
                      height={100}
                    />
                  </a>
                </span>
                <h6>Digital Marketing</h6>
                <p>
                  <a href="/Digital-Marketing/SearchEngineOptimization">
                    <img
                      xlinkHref="icon/seo/seo.svg"
                      src={DM1}
                      width={32}
                      height={32}
                    />
                    Search Engine Optimization (SEO)
                  </a>
                </p>
                <p>
                  <a href="/Digital-Marketing/Ppc">
                    <img
                      xlinkHref="icon/ppc/ppc.svg"
                      src={DM2}
                      width={32}
                      height={32}
                    />
                    Google Pay Per Click (PPC)
                  </a>
                </p>
                <p>
                  <a href="/Digital-Marketing/SocialMediaMarketing">
                    <img
                      xlinkHref="icon/SocialMediaMarketing/Social Media Marketing.svg"
                      src={DM3}
                      width={32}
                      height={32}
                    />
                    Social Media Marketing
                  </a>
                </p>
                <p>
                  <a href="/Digital-Marketing/EmailMarketing">
                    <img
                      xlinkHref="icon/Emailmarketing/Email marketing.svg"
                      src={DM4}
                      width={32}
                      height={32}
                    />
                    Email Marketing
                  </a>
                </p>
                <p>
                  <a href="/Digital-Marketing/MarketAnalytics">
                    <img
                      xlinkHref="icon/mobilemarketing/Mobile marketing.svg"
                      src={DM5}
                      width={32}
                      height={32}
                    />
                    Mobile Marketing
                  </a>
                </p>
                <p>
                  <a href="/Digital-Marketing/ContentMarketing">
                    <img
                      xlinkHref="icon/contentmarketing/Content marketing.svg"
                      src={DM6}
                      width={32}
                      height={32}
                    />
                    Content Marketing
                  </a>
                </p>
                <p>
                  <a href="/Digital-Marketing/MarketAnalytics">
                    <img
                      xlinkHref="icon/marketinganalytics/marketing analytics.svg"
                      src={DM7}
                      width={32}
                      height={32}
                    />
                    Marketing Analytics
                  </a>
                </p>
                <p>
                  <a href="/Digital-Marketing/AffiliateMarketing">
                    <img
                      xlinkHref="icon/affilicatemarketing/affiliate marketing.svg"
                      src={DM8}
                      width={32}
                      height={32}
                    />
                    Affiliate Marketing
                  </a>
                </p>
                <p>&nbsp; </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xl-4 col-md-6 mt-4">
              <div className="item-SB">
                <span className="icon feature_box_col_five">
                  <a href="#">
                    <img
                      xlinkHref="Pictures/industry/solution.svg"
                      src={Solution}
                      width={100}
                      height={100}
                    />
                  </a>
                </span>
                <h6>Solutions</h6>
                <p>
                  <a href="/Solutions/HealthCareApp">
                    <img
                      xlinkHref="Pictures/solution-icon/fintech/fintech.svg"
                      src={S1}
                      width={32}
                      height={32}
                    />
                    Fintech Development
                  </a>
                </p>
                <p>
                  <a href="/Solutions/DemandApp">
                    <img
                      xlinkHref="Pictures/solution-icon/On-Demand-App/On Demand App.svg"
                      src={S2}
                      width={32}
                      height={32}
                    />
                    On Demand App Development
                  </a>
                </p>
                <p>
                  <a href="/Solutions/SaaSDevelopment">
                    <img
                      xlinkHref="Pictures/solution-icon/Saas/Saas.svg"
                      src={S3}
                      width={32}
                      height={32}
                    />
                    SaaS Development
                  </a>
                </p>
                <p>
                  <a href="/Solutions/RealStateApp">
                    <img
                      xlinkHref="Pictures/solution-icon/Real-Estate-App/Real Estate App.svg"
                      src={S4}
                      width={32}
                      height={32}
                    />
                    Real Estate App Development
                  </a>
                </p>
                <p>
                  <a href="/Solutions/IntigrationMigration">
                    <img
                      xlinkHref="Pictures/solution-icon/Intergration-Migration/Intergration & Migration.svg"
                      src={S5}
                      width={32}
                      height={32}
                    />
                    Intigration &amp; Migration
                  </a>
                </p>
                <p>
                  <a href="/Solutions/Maintainance">
                    <img
                      xlinkHref="Pictures/solution-icon/Maintainence-Support/maintainence-support.svg"
                      src={S6}
                      width={32}
                      height={32}
                    />
                    Maintance &amp; Support
                  </a>
                </p>
                <p>&nbsp; </p>
                <p>&nbsp; </p>
                <p>&nbsp; </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xl-4 col-md-6 mt-4 mx-auto">
              <div className="item-SB">
                <span className="icon feature_box_col_six">
                  {" "}
                  <a href="#">
                    <img
                      xlinkHref="Pictures/industry/consultants.svg"
                      src={Consultantes}
                      width={100}
                      height={100}
                    />
                  </a>
                </span>
                <h6>Consulting</h6>
                <p>
                  <a href="/Consulting/MobileAppConsulting">
                    <img
                      xlinkHref="Pictures/Consulting-icon/Mobile Consulting/Mobile Consulting.svg"
                      src={C1}
                      width={32}
                      height={32}
                    />
                    Mobile App Consulting
                  </a>
                </p>
                <p>
                  <a href="/Consulting/WebAppConsulting">
                    <img
                      xlinkHref="Pictures/Consulting-icon/Web app Consulting/Web-App-Consulting-01.svg"
                      src={C2}
                      width={32}
                      height={32}
                    />
                    Web App Consulting
                  </a>
                </p>
                <p>
                  <a href="/Consulting/DevOpsConsulting">
                    <img
                      xlinkHref="Pictures/Consulting-icon/Devops/Devops.svg"
                      src={C3}
                      width={32}
                      height={32}
                    />
                    DevOps Consulting
                  </a>
                </p>
                <p>
                  <a href="../Consulting/DesignConsulting.html">
                    <img
                      xlinkHref="Pictures/Consulting-icon/Design Constultant/Design Consultant.svg"
                      src={C4}
                      width={32}
                      height={32}
                    />
                    Design Consulting
                  </a>
                </p>
                <p>
                  <a href="/Consulting/DesignConsulting">
                    <img
                      xlinkHref="Pictures/Consulting-icon/Testing Consulting/Testing Consulting.svg"
                      src={C5}
                      width={32}
                      height={32}
                    />
                    Testing Consulting
                  </a>
                </p>
                <p>
                  <a href="/Consulting/TestingConsulting">
                    <img
                      xlinkHref="Pictures/Consulting-icon/Cloud Consulting/Cloud Consulting.svg"
                      src={C6}
                      width={32}
                      height={32}
                    />
                    Cloud Consulting
                  </a>
                </p>
                <p>&nbsp; </p>
                <p>&nbsp; </p>
                <p>&nbsp; </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Package;
