import React from "react";
import ecommerce from "../images/indexicon/Ecommerce-development.png";
import blockchain from "../images/indexicon/blockchain - 77.png";
import digital from "../images/indexicon/digital-marketing2.png";
import game from "../images/indexicon/games-header3.png";
// import metaverse from '../images/indeximg/metaverse-header.png';
import mobile from "../images/indexicon/mobileapp-development.png";
import uiux from "../images/indexicon/uiux-header02.png";
import webd from "../images/indexicon/webd-evelopment5.png";
import headerLeft from "../images/ai/header-left.png";
function Mobservices() {
  return (
    <>
      <section className="mobilese">
        <section className="sec050">
          <div className="container-fluid">
            <div className="row flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto image_curtain_0">
                <div className="subtitle" style={{}}>
                  Our Services
                </div>
                <h2 style={{}}>Artificial Intelligence</h2>
                <p
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  In today's world, a dynamic, user-friendly website is
                  necessary to match your brand's image and online marketing
                  needs. BTG Technologies can develop an interactive website to
                  showcase your products and help to build your brand image,
                  quality, and clientage. We focus on website design,{" "}
                  <span className="mobile-display">
                    application development, and branding. BTG Technologies,
                    depending on your business goals and requirements, can
                    develop a website helpful to business growth via traffic and
                    backlinks. Our web engineers can design responsive webs that
                    fit any device, including mobile and tablet. We believe that
                    "Content is King," and our content writers are specialists
                    in identifying your content needs and highlighting the same
                    to the customer. We are capable of delivering content as per
                    your requirement, whether it may be a startup, SME, or
                    business with more complex and specialized operations.
                  </span>
                </p>
                {/*<div className="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img
                  src={headerLeft}
                  className="img-fluid d-lg-none d-xl-none d-md-none"
                  alt="Artificial Intelligence"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="sec09">
          <div className="container-fluid">
            <div className="row flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto image_curtain">
                <div className="subtitle" style={{}}>
                  Our Services
                </div>
                <h2 style={{}}>Mobile App Development</h2>
                <p
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  Mobile apps can unlock countless opportunities to enhance
                  business growth and evolution. Moreover, they have a direct
                  impact on revenue and profits. BTG Technologies can support
                  your business with high-quality, innovative, and user-friendly
                  mobile apps that can serve your business interest.
                  <span className="mobile-display">
                    {" "}
                    Both Android and iOS devices build a strong relationship
                    with customers by providing more accessible and friendly
                    interface apps. An easy-to-navigate and engaging mobile app
                    can build your company's reputation and earns customers'
                    loyalty. Our high-quality mobile app with artistic UI/UX
                    work can redefine customers' business experience. High-end
                    app development is essential to the company's online
                    presence and customer bonding. Our mobile app development
                    process is based on; Requirement Gathering Research, UI/UX
                    Design, Prototype, Development, Quality Assurance,
                    Deployment, and Support &amp; Maintenance. We are
                    cost-effective, affordable, and negotiable.
                  </span>
                </p>
                {/*<div className="btns-arrow"  style="">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <img
                  src={mobile}
                  className="img-fluid d-lg-none d-xl-none d-md-none"
                  alt="Mobile App Development"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="sec0555">
          <div className="container-fluid">
            <div className="row flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto image_curtain_2">
                <div className="subtitle" style={{}}>
                  Our Services
                </div>
                <h2 style={{}}>BlockChain</h2>
                <p
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  The new digital world of emerging decentralized technology is
                  unfolding through Web 3 and Blockchain technology. On a
                  vertical side, blockchain's footprint is ever-extending, and
                  presently it has entered the domain of Healthcare, Fintech,
                  Banking, Logistics, and Supply Chain Management.
                  <span className="mobile-display">
                    {" "}
                    The Metaverse world is ever-growing, and it has encompassed
                    Real Estate, Superstores, Brands, Musical Concerts, and so
                    on. If you want to see Blockchain business ideation dreams
                    come to reality, trust BTG Technologies is the right place
                    to bank upon. Our expertise covers all Blockchain technology
                    applications, including NFT, Cryptocurrency Wallets, Crypto
                    Exchange, Smart Contract, Web 3 Applications, Coin
                    Development and Data Security. We can deliver on important
                    cryptocurrency platforms, including Ethereum, Hyperledger,
                    Solana, Steller, etc. Even we can help you to create a
                    hand-shaking of multiple chains to complete the transaction
                    from one chain to another. We commit to delivering the
                    Blockchain project in time within your budget.
                  </span>
                </p>
                {/*<div  style="" class="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <img
                  src={blockchain}
                  className="img-fluid d-lg-none d-xl-none d-md-none"
                  alt="BlockChain"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="sec05">
          <div className="container-fluid">
            <div className="row flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto image_curtain_1">
                <div className="subtitle" style={{}}>
                  Our Services
                </div>
                <h2 style={{}}>Web Development</h2>
                <p
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  In today's world, a dynamic, user-friendly website is
                  necessary to match your brand's image and online marketing
                  needs. BTG Technologies can develop an interactive website to
                  showcase your products and help to build your brand image,
                  quality, and clientage. We focus on website design,{" "}
                  <span className="mobile-display">
                    application development, and branding. BTG Technologies,
                    depending on your business goals and requirements, can
                    develop a website helpful to business growth via traffic and
                    backlinks. Our web engineers can design responsive webs that
                    fit any device, including mobile and tablet. We believe that
                    "Content is King," and our content writers are specialists
                    in identifying your content needs and highlighting the same
                    to the customer. We are capable of delivering content as per
                    your requirement, whether it may be a startup, SME, or
                    business with more complex and specialized operations.
                  </span>
                </p>
                {/*<div className="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img
                  src={webd}
                  className="img-fluid d-lg-none d-xl-none d-md-none"
                  alt="Web Development"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="sec055">
          <div className="container-fluid">
            <div className="row flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto image_curtain_3">
                <div className="subtitle" style={{}}>
                  Our Services
                </div>
                <h2 style={{}}>E-Commerce Development</h2>
                <p
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  The growth of e-commerce in the last two decades has
                  revolutionized business dynamics and customer behavior.
                  However, after the pandemic, it has seen new heights of
                  business growth both horizontally and vertically. The new
                  dynamics of e-commerce have impacted every single person and
                  house.
                  <span className="mobile-display">
                    {" "}
                    BTG Technologies has the expertise to provide an e-commerce
                    solution for startup SMEs and even big business houses. Our
                    solution includes unlimited customize features with the
                    flexibility &amp; power to create your e-commerce store the
                    way you want. We empower your business with scalability,
                    flexibility &amp; security. We help you to integrate your
                    ERP with CRM seamlessly and can plug in your e-commerce
                    platform with existing business management solutions. Our
                    Design Thinking Engineers are mindful of optics, creativity
                    &amp; innovation. We work on customer experience to ensure
                    your business growth. Depending on the client's needs, we
                    can deliver cost-effective e-commerce solutions in B2B and
                    B2C or the way you want.
                  </span>
                </p>
                {/*<div className="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <img
                  src={ecommerce}
                  className="img-fluid d-lg-none d-xl-none d-md-none"
                  alt="E-Commerce Development"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="sec0999">
          <div className="container-fluid">
            <div className="row flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto image_curtain">
                <div className="subtitle" style={{}}>
                  Our Services
                </div>
                <h2 style={{}}>Games Development</h2>
                <p
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  BTG Technologies is a full-cycle game development company. Our
                  game cycle revolves around Producer, Assistant Producer, Game
                  Designer, QA Engineer &amp; Artist. We develop all sorts of
                  games, including action, casino, sports, racing, hunting, etc.
                  <span className="mobile-display">
                    {" "}
                    We accompany the customer throughout the project journey by
                    mutually consulting and supporting through all stages of
                    game development, from conceptualizing, character
                    development, character animations, and visual and sound
                    impacts to post-release support. Our team includes senior
                    designers and programmers of 2D and 3D games having artistic
                    qualities capable of giving digital life to the game
                    characters. Our portability feature enables the game to be
                    displayed on any device like mobile, desktop, consoles, or
                    VR devices. Depending on the game's nature, our developers
                    are confident in engaging a greater number of players from
                    all walks of life, irrespective of age and gender. With the
                    rise of blockchain and web3 technology, our developers can
                    create NFTs specific to weapons, cars, or other characters.
                    BTG Technologies value and safeguard your data and
                    infrastructure security. We claim that we are punctual with
                    your timeline and flexible with your budget.
                  </span>
                </p>
                {/*<div  style="" class="btns-arrow">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <img
                  src={game}
                  className="img-fluid d-lg-none d-xl-none d-md-none"
                  alt="Games Development"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="sec099">
          <div className="container-fluid">
            <div className="row flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto image_curtain_1">
                <div className="subtitle" style={{}}>
                  Our Services
                </div>
                <h2 style={{}}>UI/UX Designing</h2>
                <p
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  "Beauty lies in the eyes of the beholder" our UI/UX engineers
                  redefine this paradigm and rephrase it as "Optical magic lies
                  with the UI/UX developer." In the digital world, the success
                  of a website and mobile app depends on the design, optics, and
                  flow of the UI/UX developer.
                  <span className="mobile-display">
                    {" "}
                    All the parameters of app download, website traffic, and
                    customer retention rely highly on UI/UX design and
                    development. BTG Technologies understand the business needs
                    and smartly translate them into traffic generation and
                    retention through optical magic and design process. Our
                    UI/UX experts can boost engagement matrices and customer
                    retention and minimize integrated errors that compromise the
                    website's and mobile app's efficiency and traffic.
                  </span>
                </p>
                {/*<div className="btns-arrow"  style="">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <img
                  src={uiux}
                  className="img-fluid d-lg-none d-xl-none d-md-none"
                  alt="UI/UX Designing"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="sec0101">
          <div className="container-fluid">
            <div className="row flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-50  mt-auto mb-auto image_curtain_2">
                <div className="subtitle" style={{}}>
                  Our Services
                </div>
                <h2 style={{ color: "white" }}>Digital Marketing</h2>
                <p
                  style={{
                    fontSize: "20px!important",
                    lineHeight: "36px!important",
                  }}
                >
                  BTG Technologies provides 360-degree digital marketing
                  solutions to its clients as a one-stop and window solution. We
                  serve startups, SMEs, and even big business firms with
                  complete digital marketing services and solutions portfolios.
                  We work like a partner with your company &amp; provide
                  cost-effective,
                  <span className="mobile-display">
                    {" "}
                    result-oriented solutions with the commitment to growth in
                    brand awareness, increase in sales, and rise in market
                    footprint. Our digital marketing strategies are reflected in
                    the substantial return on investment (ROI) achieved. Our
                    Search Engine Optimization (SEO) portfolio includes
                    Franchise SEO, Local SEO, and Technical SEO. We boost your
                    business through link building by capitalizing on your
                    compelling content, influential blogs, and podcast services.
                    By creating your social media engagement, we create
                    backlinks to boost your sale. Through a PPC campaign, we
                    increase your market footprint of targeted ad copies,
                    promote your bidding strategies and improve your target
                    engagement tactics. Our digital marketing motto is to
                    convert companies from good to great. We claim to respect
                    your timeline and budget.
                  </span>
                </p>
                {/*<div className="btns-arrow"  style="">
                  <a data-toggle="modal" data-target="#exampleModalCenter" class="connect">Learn More</a>
              </div>*/}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                <img
                  src={digital}
                  className="img-fluid d-lg-none d-xl-none d-md-none"
                  alt="Digital Marketing"
                // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
export default Mobservices;
