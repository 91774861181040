import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/Uiux/Uxre/UX-Reasearch-design03.png";
import Android2 from "../../../../images/indeximg/Uiux/Uxre/UX-Reasearch-design02.png";

import Android3 from "../../../../images/indeximg/Uiux/Uxre/UIUX-DESIGN.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec uxse">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Do you need User Experience research?</h3>
                  <p>
                    By understanding how users interact with your services or
                    products, organizations can design better user interfaces,
                    optimize their feature set for maximum impact, and improve
                    the overall functionality and usability of their sites.
                    Through UX research, companies can gain insights into what
                    users want and need, which helps them to make informed
                    decisions about their products.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Grow Your Customer Base</h3>
                  <p>
                    Organizations that invest in UX research reap the benefits
                    of improved user satisfaction, increased conversion rates,
                    and higher customer retention rates. Whether you are looking
                    for effective ways to measure and understand your user
                    behavior or the effectiveness of your user behavior, we can
                    equip you with the necessary data and insights.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Improve Your Conversion Rates with us</h3>
                  <p>
                    We at BTG Technologies aim to make the business succeed
                    through our effective UX research techniques. With our
                    qualitative and quantitative approach, we help our clients
                    to improve their conversion rates, leads, and sales. We use
                    various research methods to collect user data, including
                    interviews, surveys, focus groups, and usability testing.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
