import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img augm">
        <div className="left-img augm">
          <div className="short-line"></div>
          <h2 className="heading-1">
            Augmented Reality (AR) Game Development Services
          </h2>
          <p>
            Create high-quality, immersive, and addictive gaming experiences
            with the AR gaming platform that features 3D graphics, motion
            capture, and other technologies. We have a proficient and skilled
            team of game developers specialized in designing, developing, and
            optimizing all aspects of high-end interactive games on various
            platforms.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
