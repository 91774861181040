import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img sda">
        <div className="left-img left-img1">
          <div className="short-line"></div>
          <h2 className="heading-1">iPhone App Development</h2>
          <p>
            Increase your business’s customer base through our feature-enriched
            iOS-native apps and make your marketing more effective! We build
            top-notch functional apps for iOS devices. Our team will support you
            from design to programming and marketing to maintaining your app
            continually.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
