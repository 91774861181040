import React from "react";
import Navbares from "../../Component/Nev";
import "../../Blog.css";
import "../../App.css";

import MainBanner from "../../Component/Solutions/IntigrationMigration/Main-banner";
import QuickSolution from "../../Component/Solutions/IntigrationMigration/Quicksolution";
import Fintechapp from "../../Component/Solutions/IntigrationMigration/Fintech-App";
// import Choose from "../../Component/Solutions/IntigrationMigration/Choose";
import Getintech from "../../Component/LetsBusiness";
import Footer from "../../Component/Footer";
import Document from "../../Document";
function IntigrationMigration() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <MainBanner />
        <QuickSolution />
        <Fintechapp />
        {/* <Choose /> */}
        <Getintech info={"Intigration Migration"} />

        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Intigration Migration"} />

    </>
  );
}

export default IntigrationMigration;
