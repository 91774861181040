import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../../images/indeximg/game/virtualreal/virtual-reality_design01.png";
import Android2 from "../../../../images/indeximg/game/virtualreal/virtual-reality_design02.png";

import Android3 from "../../../../images/indeximg/game/virtualreal/virtual-reality_design03.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec virtual">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>The Indispensable Game Development Partner</h3>
                  <p>
                    BTG Technologies is a leading and reliable name in the
                    gaming world. We offer our clients invaluable support and
                    expertise to build hyper-realistic games. From guiding the
                    initial development process to providing constant support to
                    avoid potential issues, we are here to serve. We also cope
                    with the latest VR trends and technologies to keep you ahead
                    of the competition
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    Incorporate your Vision with our Game Development Techniques
                  </h3>
                  <p>
                    BTG Technologies is an expert game development agency that
                    can turn your vision into reality. We leverage VR gaming
                    engines and other technologies, such as 3d rendering,
                    animations, and visual creations, to make your project
                    successful. Our squad incorporates your unique ideas and
                    concepts into the game, ensuring that it meets your
                    expectations.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Crafting Premium Virtual Worlds</h3>
                  <p>
                    We are committed to providing the most entertaining and
                    immersive virtual world with 360-degree rotation. Designed
                    with interactive and challenging gameplay, we ensure game
                    success on each platform, including smartphones, desktops,
                    and consoles. We understand the importance of creating a
                    captivating gaming experience, and our team of experts is
                    dedicated to crafting virtual worlds that will meet your
                    specific needs. And with our cutting-edge technology, we can
                    ensure that your world is as realistic and immersive as
                    possible.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
