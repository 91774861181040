import React from "react";
import "./Service.css";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Leverage Our Expertise</h3>
                  <p>
                    Our state-of-the-art approach combined with the latest
                    technologies results in the <br /> development of various
                    android apps. We have a wide range of Android app <br />
                    development services available ranging from app development
                    to integration and <br /> testing, so you can be sure that
                    you will find the right solution for your needs.
                  </p>
                  <ul>
                    <li>Android UI/UX designing</li>
                    <li>Custom Android Apps</li>
                    <li>Cross-platform Android App Development</li>
                  </ul>
                  <ul>
                    <li>Android Game Development</li>
                    <li>Enterprise Apps Development</li>
                    <li>Android App Integration & Modernization</li>
                  </ul>
                  <ul>
                    <li>On-demand Apps Development</li>
                    <li>Android Apps Optimization & QA</li>
                    {/* <li>Cross-platform Android App Development</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
