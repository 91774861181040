import React from "react";
// import Navbares from "./Component/Nev";
import f1 from "../images/indeximg/icon/retail-Ecommerce.svg";
import f2 from "../images/indeximg/icon/Education-e-learning.svg";
import f3 from "../images/indeximg/icon/Healthcare&Fitness.svg";
import f4 from "../images/indeximg/icon/Social-Networking.svg";
import f5 from "../images/indeximg/icon/Logistics-Distribution.svg";
import f6 from "../images/indeximg/icon/Real-estate.svg";
import f7 from "../images/indeximg/icon/Travel&Hospitality.svg";
import f8 from "../images/indeximg/icon/Food Restaurant.svg";
import f9 from "../images/indeximg/icon/On-Demand Solutions.svg";
import f10 from "../images/indeximg/icon/Game.svg";

function Industry() {
  return (
    <>
      <section className="industry_sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 pr-50  mt-auto mb-auto ">
              <div className="industryhigh"></div>
              <div className="industry-head text-center">
                <h2>Industries We Serve</h2>
                <p>
                  Here, we make almost every genre of applications. You name it
                  and we build it.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={f1} class="card-img-top " alt="f1" />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">Retail, Ecommerce</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={f2} class="card-img-top " alt="f2" />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">Education & e-learning</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={f3} class="card-img-top " alt="f3" />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">Healthcare & Fitness</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={f4} class="card-img-top " alt="f4" />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">Social Networking</h5>
                    {/* <p class="card-text mb-5 ms-1">
                      See more bootstrap code snippets on my website
                    </p> */}

                    {/* <a
                      href="https://freesnippetcode.blogspot.com/"
                      target="_blank"
                      class="btn btn-primary mb-1 mt-1 "
                    >
                      Go now
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={f5} class="card-img-top " alt="f5" />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">Logistics & Distribution </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={f6} class="card-img-top " alt="f6" />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">Retail, Ecommerce</h5>
                    {/* <p class="card-text mb-5 ms-1">
                      See more bootstrap code snippets on my website
                    </p>

                    <a
                      href="https://freesnippetcode.blogspot.com/"
                      target="_blank"
                      class="btn btn-primary mb-1 mt-1 "
                    >
                      Go now
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={f7} class="card-img-top " alt="f7" />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">Travel & Hospitality</h5>
                    {/* <p class="card-text mb-5 ms-1">
                      See more bootstrap code snippets on my website
                    </p>

                    <a
                      href="https://freesnippetcode.blogspot.com/"
                      target="_blank"
                      class="btn btn-primary mb-1 mt-1 "
                    >
                      Go now
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={f8} class="card-img-top " alt="f8" />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">Food & Restaurant</h5>
                    {/* <p class="card-text mb-5 ms-1">
                      See more bootstrap code snippets on my website
                    </p>

                    <a
                      href="https://freesnippetcode.blogspot.com/"
                      target="_blank"
                      class="btn btn-primary mb-1 mt-1 "
                    >
                      Go now
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 pr-50  mt-auto mb-auto industr"></div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={f9} class="card-img-top " alt="f9" />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">On-Demand Solutions</h5>
                    {/* <p class="card-text mb-5 ms-1">
                      See more bootstrap code snippets on my website
                    </p>

                    <a
                      href="https://freesnippetcode.blogspot.com/"
                      target="_blank"
                      class="btn btn-primary mb-1 mt-1 "
                    >
                      Go now
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 pr-50  mt-auto mb-auto ">
              <div class="">
                <div class="animate__animated animate bounce card ">
                  <div class="container mt-3">
                    <img src={f10} class="card-img-top " alt="f10" />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title ms-1">Gaming</h5>
                    {/* <p class="card-text mb-5 ms-1">
                      See more bootstrap code snippets on my website
                    </p>

                    <a
                      href="https://freesnippetcode.blogspot.com/"
                      target="_blank"
                      class="btn btn-primary mb-1 mt-1 "
                    >
                      Go now
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-3 pr-50  mt-auto mb-auto "></div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Industry;
