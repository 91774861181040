import React from "react";
// import Navbares from "./Component/Nev";
import MainBanner from "../../images/aboutimg/whyworkwithus.png";
import First from "../../images/aboutimg/5.png";
import Second from "../../images/aboutimg/6.png";

import "./about.css";
function Ourwork() {
  return (
    <>
      <section className="OurWork-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12">
              <div className="top-bar-border"></div>
              <div className="ourmain-head">
                <h3>Our Work Ethics & Culture</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="ourside-img">
                <img src={MainBanner} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="ourwork-para">
                <div className="ourworkpara">
                  <p>
                    BTG has more humanistic outlook towards employees &
                    customers.We have developed customer centric culture where
                    we understand the needs of customer and deliver the product
                    within time and budget. We remain in loop through out
                    product development, support and maintenance.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-2 col-sm-2 col-md-2  col-lg-2 col-xl-2">
                  <div className="ourworkfirstimg">
                    <img src={First} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-10 col-sm-10 col-md-10  col-lg-10 col-xl-10">
                  <div className="ourwork-para">
                    <div className="ourworkpara">
                      <p>
                        BTG seamlessly modify your existing work flow, innovate
                        and automate with digital excellence to work on your
                        financial growth and customer retention.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-2 col-sm-2 col-md-2  col-lg-2 col-xl-2">
                  <div className="ourworksecimg">
                    <img src={Second} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-10 col-sm-10 col-md-10  col-lg-10 col-xl-10">
                  <div className="ourwork-para">
                    <div className="ourworkpara">
                      <p>
                        Our multi disciplinary professional teams create
                        different level of customer engagements to understand
                        your needs and provide software solutions that scale
                        your business and growth.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Ourwork;
