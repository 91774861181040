import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Python Solutions for Your Business</h3>
                  <p>
                    We proudly offer high-quality Python development services
                    for start-ups and growing enterprises. Our skilled team
                    deeply understands the latest technologies and programming
                    languages and can create custom solutions that meet your
                    specific needs. Whether you need a simple script or a
                    complex software application, we can help you get the job
                    done.
                  </p>
                  <ul>
                    <li>Python API Development</li>
                    <li>Python IoT Solutions</li>
                    <li>Python Migration & Upgradation</li>
                  </ul>
                  <ul>
                    <li>Python App Development</li>
                  </ul>
                  <ul>
                    <li>Python Prototype Development</li>
                    <li>Python Gaming Solutions</li>
                    <li>Python AI/ML Solutions</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
