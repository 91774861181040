import React from "react";
// import Navbares from "./Component/Nev";
// import Android1 from "../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/Services/Flutter/Flutter-Deign-01.png";
import First from "../../../../images/Services/Flutter/skill.png";
import First1 from "../../../../images/Services/Flutter/promote-app.png";

import fintech_design05 from "../../../../images/Services/Flutter/Flutter-Deign-02.png";
import First4 from "../../../../images/Services/Flutter/highest-performance_technology.png";
import First5 from "../../../../images/Services/Flutter/powerful-animation.png";
import fintech_design04 from "../../../../images/Services/Flutter/Flutter-Deign-03.png";
import First7 from "../../../../images/Services/Flutter/customizeapp-appearance.png";
import First8 from "../../../../images/Services/Flutter/widget-api.png";

function Fintechapp() {
  return (
    <>
      <section className="Fintech-sec flutt">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>What is Flutter App Development?</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Flutter is an open-source mobile app development UI toolkit
                    created by Google. It is used to develop cross-platform apps
                    compatible with Android, iOS, Windows, Google, and Linux.
                  </p>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Flutter apps are written in the Dart programming
                          language and use the Flutter SDK to compile and deploy
                          them. This framework allows developers to create
                          native apps with a single codebase. Flutter platform
                          can help you make an engaging interface design and
                          user-friendly features and optimize your app for
                          performance.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First1}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          In addition, it can help you promote your app and
                          reach new customers. If you are looking for a way to
                          improve your mobile app development process, consider
                          BTG Technologies to develop beautiful cross-platform
                          mobile applications with native performance.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Flutter Apps help you Achieve Your Goals</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Redefine the experience of how your business apps operate on
                    multiple devices by using Flutter SDKs to create interactive
                    and native mobile apps. At BTG Technologies, we have a team
                    of experienced and certified developers who are experts in
                    using the Flutter framework to create high-quality
                    cross-platform apps.
                  </p>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First4}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          By incorporating the highest-performance technology
                          into their systems, we are helping startups and
                          leading enterprises to own native mobile apps to stay
                          ahead in this competitive digital world.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First5}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          We use Flutter widget features, powerful animation,
                          and responsive design to create engaging business apps
                          that can help you to target potential audiences and
                          generate more revenue. We follow best practices to
                          ensure optimal performance and provide ongoing support
                          and maintenance. Contact us today for a free
                          consultation!
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design05}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Fully Loaded with Custom Features</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design04}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    We build Flutter apps with all the custom features you could
                    ever want. Our developers are experts in the Dart
                    programming language and can create any app you need. We've
                    built Flutter apps for businesses of all sizes, from small
                    startups to large enterprises. No matter what your app
                    needs, we can make it happen.
                  </p>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First7}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          With Flutter, you can customize every aspect of your
                          app's appearance and behavior, from how it animates
                          transitions to how it handles user input.
                          Flutter-build apps are highly stable and less prone to
                          crashes and attacks than other frameworks, making the
                          apps more user-friendly and reliable.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img">
                    <ul>
                      <li>
                        <img
                          src={First8}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Flutter includes a rich set of widgets and APIs that
                          can be used to create high-quality user experiences.
                          Built-in features and widgets help businesses in quick
                          product launches.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Fintechapp;
