import React from "react";
import Navbares from "../../../Component/Nev";
import "../../../Blog.css";
import "../../../App.css";

import Leverage from "../../../Component/Services/Web-Development/Laravel/Loverage";
import Digitalsec from "../../../Component/Services/Web-Development/Laravel/Digitalsec";
import Expand from "../../../Component/Services/Web-Development/Laravel/Expand";
import Header01 from "../../../Component/Services/Web-Development/Laravel/Header01";
import Getintech from "../../../Component/LetsBusiness";
import Footer from "../../../Component/Footer";
import Document from "../../../Document";
function Laravel() {
  return (
    <>
      <Navbares />
      <div className="pagesss">
        <Header01 />
        <Leverage />
        <Digitalsec />
        <Expand />
        <Getintech info={"Laravel"} />
        {/* <Lettalk /> */}
      </div>
      <Footer />
      <Document title={"Laravel"} />

    </>
  );
}

export default Laravel;
