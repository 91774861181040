import React from "react";
// import Navbares from "./Component/Nev";
// import Android1 from "../../../../images/Services/Androidapp-Design01.png";
import Android2 from "../../../../images/indeximg/E-commerce/Woo-commerce/Wocommerce-Design1.png";
import First from "../../../../images/indeximg/E-commerce/Woo-commerce/1.png";
import First1 from "../../../../images/indeximg/E-commerce/Woo-commerce/2.png";

import fintech_design05 from "../../../../images/indeximg/E-commerce/Woo-commerce/Wocommerce-Design2.png";
import First4 from "../../../../images/indeximg/E-commerce/Woo-commerce/3.png";
import First5 from "../../../../images/indeximg/E-commerce/Woo-commerce/4.png";
import fintech_design04 from "../../../../images/indeximg/E-commerce/Woo-commerce/Wocommerce-Design5.png";
import First7 from "../../../../images/indeximg/E-commerce/Woo-commerce/5.png";
import First8 from "../../../../images/indeximg/E-commerce/Woo-commerce/6.png";

function Fintechapp() {
  return (
    <>
      <section className="Fintech-sec woo">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Why Choose WooCommerce for your eCommerce needs?</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    WooCommerce is a very versatile platform that can be used
                    for a wide variety of eCommerce businesses
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          It has a strong focus on security and scalability,
                          making it a good choice for businesses looking to grow
                          their online presence.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First1}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          WooCommerce is also easy to use and set up, so you can
                          get started quickly without investing much time or
                          money into the platform.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>Get your WooCommerce project done right the first time</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    If you're looking for a company you can trust to get the job
                    done right, please don't hesitate to contact us today.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First4}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          At BTG Technologies, we have a team of experts who
                          have worked on hundreds of WooCommerce projects. We
                          know all the ins and outs of the platform, and we can
                          help you avoid any common pitfalls.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First5}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Whether you're looking to launch a new online store or
                          optimize an existing one, we can help. We'll work with
                          you to understand your goals and then develop a plan
                          to achieve them.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design05}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Fintech-Head">
                <h3>WooCommerce setup and optimization</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={fintech_design04}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head our-fint">
                <div className="digital-heading">
                  <p>
                    Our team can optimize your store for better performance,
                    usability, and conversion rates. We can also help you
                    troubleshoot any issues you may be having with your
                    WooCommerce store.
                  </p>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First7}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          We strive to maximize your platform functionality so
                          you can get the best out of your investment. Our team
                          can also help you achieve the highest customer
                          satisfaction levels.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="digital-images Fintech-img sasas">
                    <ul>
                      <li>
                        <img
                          src={First8}
                          className="img-fluid "
                          alt="Android"
                          // style={{ paddingTop: 100, paddingBottom: 100 }}
                        />
                      </li>
                      <li>
                        <p>
                          {" "}
                          Once your store is up and running, we can also provide
                          ongoing support and optimization services to keep it
                          performing at its best.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Fintechapp;
