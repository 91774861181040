import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img pyth">
        <div className="left-img pyth">
          <div className="short-line"></div>
          <h2 className="heading-1">Python Development Services</h2>
          <p>
            Create amazing web applications with great functionality and an
            amazing user interface with our Python development services. We at
            BTG Technologies provide Python services to help businesses in all
            industries and sizes gain flexibility, improve efficiency and reduce
            operating costs.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
