import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img Angular">
        <div className="left-img Angular">
          <div className="short-lineAng"></div>
          <h2 className="heading-1">Angular Development Services</h2>
          <p>
            BTG Technologies proudly offers Angular development services through
            decades of experience creating robust and functional apps. We
            leverage the latest Angular features and technologies to develop
            fast, efficient, and secure solutions for your business.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
