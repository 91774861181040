import React from "react";
import Navbares from "../Component/Nev";

import ControlledCarousel from "../Component/Fullpageslide";

import Services from "../Component/Service";
import Package from "../Component/Packages";

// import Home from "./Pages/Home";
// import logo from './logo.svg';
// import "../Blog.css";
// import "./Home.css";
import Industry from "../Component/Industries";
import WhoWork from "../Component/WhoWork";
import Projectlink from "../Component/Projectlink";
import Our from "../Component/Ourkeyfeature";
import Blogslider from "../Component/Blogslider";
// import Process from "../Component/Process";
// import Swiper from "./Component/Blogslid";
import Footer from "../Component/Footer";

import Mobservices from "../Component/Mobileservice";
import Getintech from "../Component/LetsBusiness";
import Document from "../Document";

function Home() {
  return (
    <>
      {/* home page  */}
      {/* <MainNav /> */}
      <Navbares />
      <div className="pagesss">
        {/* <Slider /> */}
        <ControlledCarousel />

        {/* <Mainslider /> */}
        <Services />
        <Mobservices />
        <Blogslider />
        <Our />
        <Package />
        <Industry />
        <WhoWork />
        {/* <Process /> */}
        <Projectlink />
        {/* <Lettalk /> */}
        <Getintech info={'Home'} />
      </div>
      <Footer />
      <Document title={'Home'} />
    </>
  );
}

export default Home;
