import React from "react";
// import Navbares from "./Component/Nev";
import Android1 from "../../../images/solutions/Realstate/realesateapp_design03.png";
import Android2 from "../../../images/solutions/Realstate/realesateapp_design01.png";

import Android3 from "../../../images/solutions/Realstate/realesateapp_design02.png";

function Digitalsec() {
  return (
    <>
      <section className="digital-sec realstate">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>A One-Time Investment that Ensures Growth and Success</h3>
                  <p>
                    Real estate app development empowers businesses to grow and
                    profit by providing their clients with customized solutions
                    to fulfill their needs. Our professionals at BTG
                    Technologies can craft you a customized and functional app
                    that ensures ROI, growth, and success. By taking advantage
                    of built-in customer analytics and tracking, you can gain
                    valuable insights into their needs and preferences, which
                    can help you improve your business strategy.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android1}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android2}
                  className="img-fluid "
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>Stress-Free Custom Realtor Apps</h3>
                  <p>
                    Real estate holders using our top-notch apps can enjoy
                    several benefits, including increased efficiency, less
                    dependency, and improved branding. Our developers integrate
                    the latest technologies, such as blockchain, AI, IoT, Cloud,
                    and others, to create highly useful property apps. We'll be
                    happy to provide you with a detailed proposal outlining our
                    services and answer any questions you may have.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-head">
                <div className="digital-high"></div>
                <div className="digital-heading">
                  <h3>
                    BTG Technologies - A Gateway to customized & Advanced Real
                    Estate Digital World
                  </h3>
                  <p>
                    We at BTG Technologies have the best and most experienced
                    professionals ready to handle your digital needs to elevate
                    and stabilize your real estate business. Our team of
                    developers can help you identify potential properties,
                    organize financing, and market your property to attract
                    buyers. We also offer marketing and branding assistance and
                    expert advice on finance and investment.
                  </p>
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="digital-img">
                <img
                  src={Android3}
                  className="img-fluid"
                  alt="Android"
                  // style={{ paddingTop: 100, paddingBottom: 100 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Digitalsec;
