import React from "react";
// import Navbares from "./Component/Nev";
function Leverage() {
  return (
    <>
      <section className="android-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="Leverage-head">
                <div className="leverage-heading">
                  <div className="leverage-higt"></div>
                  <h3>Our Expertise</h3>
                  <p>
                    Whether you are a start-up new to this blockchain technology
                    or looking for a reliable company to outsource your
                    development or migration project, we can help. We at BTG
                    Technologies have expertise in creating secure and scalable
                    bespoke Hyperledger for businesses.
                  </p>
                  <ul>
                    <li>Hyperledger & Multi-chain Development</li>
                    <li>Hyperledger application development</li>
                    <li>Hyperledger consulting</li>
                  </ul>
                  <ul>
                    <li>Maintenance and support</li>
                    <li>Migration and upgrades</li>
                    <li>Private Blockchain Development</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Leverage;
