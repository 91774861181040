import React from "react";
// import Navbares from "./Component/Nev";
import "./Service.css";
function Header01() {
  return (
    <>
      <section className="Service-img cloud">
        <div className="left-img cloud">
          <div className="short-line"></div>
          <h2 className="heading-1">Cloud Consulting Services</h2>
          <p>
            Scale up your business with a highly secure and flexible Cloud
            platform. BTG Technologies take pride in delivering high-end cloud
            consultancy that can upgrade your business model. Well-versed with
            the technologies of major clouds, including Amazon Web Services
            (AWS), Microsoft Azure, and Google Cloud Platform (GCP), we are
            helping businesses to grow and compete successfully.
          </p>
        </div>
      </section>
    </>
  );
}
export default Header01;
